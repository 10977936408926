import { ReactNode } from 'react'

import { Tooltip } from '@/components'
import { BadgeSizes, IconSize } from '@/components/icons/Icon'
import { INTEGRATION_CREDENTIAL_STATUSES, IntegrationCredentialStatusEnum } from '@/enums'
import { IntegrationCredentialStatusDataType } from '@/types'

export class IntegrationCredentialStatus {
    raw: IntegrationCredentialStatusDataType

    key: IntegrationCredentialStatusEnum

    message?: string

    color?: string

    secondaryColor?: string

    discovered_at?: Date

    index: number

    icon: (size?: IconSize) => ReactNode

    constructor(data: IntegrationCredentialStatusDataType) {
        this.raw = data
        this.index = data.index
        this.key = data.key
        this.color = data.color
        this.secondaryColor = data.secondaryColor
        this.message = data.message
        this.icon = data.icon || (() => null)
    }

    get title() {
        return <span className={this.color}>{this.raw.title}</span>
    }

    get subTitle() {
        return this.discovered_at ? `(Discovered ${new Date(this.discovered_at).humanize()})` : ''
    }

    static find(key: IntegrationCredentialStatusEnum) {
        return IntegrationCredentialStatus.all.find(item => item.key === key) || IntegrationCredentialStatus.all
            .find(item => item.key === IntegrationCredentialStatusEnum.UNKNOWN) as IntegrationCredentialStatus
    }

    static get all() {
        return INTEGRATION_CREDENTIAL_STATUSES.map(item => new IntegrationCredentialStatus(item))
    }

    getBadge(size: IconSize = 'md') {
        const icon = this.icon(size)
        if (!icon) return null
        return <Tooltip content={<span>{this.raw.title} {this.subTitle}</span>}>
            <div className={`rounded-full flex justify-center items-center ${this.secondaryColor} ${BadgeSizes[size]}`}>
                {icon}
            </div>
        </Tooltip>
    }
}
