import { IntegrationCredentialStatus } from './IntegrationCredentialStatus'
import { InverterManufacturer } from './InverterManufacturer'
import { Model } from './Model'
import { IntegrationCredentialStatusEnum } from '@/enums'
import { api, Options, Params, Payload } from '@/services'
import { IdType, IntegrationDataType } from '@/types'

export class Integration extends Model {
    static endpoint = 'api/v1/company/integrations'

    raw: IntegrationDataType

    status: IntegrationCredentialStatus

    manufacturer: InverterManufacturer

    matched_systems_count: number

    warranties_count: number

    company_id: IdType

    user?: string

    secret?: string

    contact_name?: string

    contact_email?: string

    api_key?: string

    status_change_detected_at?: Date


    constructor(data: IntegrationDataType) {
        super(data)
        this.raw = data
        this.manufacturer = InverterManufacturer.find(data.provider)
        this.status = IntegrationCredentialStatus.find(this.manufacturer.isUnknown
            ? IntegrationCredentialStatusEnum.UNSUPPORTED : data.status)

        this.matched_systems_count = Model.toNumber(data.matched_systems_count, 0) as number
        this.warranties_count = Model.toNumber(data.warranties_count, 0) as number
        this.company_id = data.company_id
        this.user = data.user
        this.secret = data.secret
        this.contact_name = data.contact_name
        this.contact_email = data.contact_email
        this.api_key = data.api_key
        this.status_change_detected_at = Model.toDate(data.status_change_detected_at)
    }

    static DEFAULT_ORDER: { [key: string]: number } = {
        enphase: 1,
        solaredge: 2,
        generac: 3,
        other: 4
    }

    static MAINTAINER_USERNAME = 'monitoring@solarinsure.com'

    static MAINTAINER_ENPHASE_ID = 1034

    static MAINTAINER_SOLAREDGE_ID = 1035

    static MAINTAINER_GENERAC_ID = 1036

    get isMaintainer() {
        return this.status.key === IntegrationCredentialStatusEnum.MAINTAINER
    }

    get isValid() {
        return this.status.key === IntegrationCredentialStatusEnum.VALID
    }

    get isUsesMaintainer() {
        return this.status.key === IntegrationCredentialStatusEnum.USES_MAINTAINER
    }

    get isNotRequired() {
        return this.status.key === IntegrationCredentialStatusEnum.NOT_REQUIRED
    }

    get isInvalid() {
        return this.status.key === IntegrationCredentialStatusEnum.INVALID
    }

    get isMissing() {
        return this.status.key === IntegrationCredentialStatusEnum.MISSING
    }

    get isInvalidOrMissing() {
        return this.isInvalid || this.isMissing
    }

    get isUnsupported() {
        return this.status.key === IntegrationCredentialStatusEnum.UNSUPPORTED
    }

    get isUnknown() {
        return this.status.key === IntegrationCredentialStatusEnum.UNKNOWN
    }

    get isComplianceVerified() {
        if (this.manufacturer.isGenerac) {
            return this.matched_systems_count > 0 && !this.isInvalidOrMissing
        }
        return this.isUsesMaintainer && this.matched_systems_count > 0
    }

    get isComplianceNotVerified() {
        return this.isUsesMaintainer && this.matched_systems_count === 0 && this.warranties_count > 0
    }

    showCredentials() {
        let { id } = this
        if (this.isUsesMaintainer) {
            if (this.manufacturer.isEnphase) {
                id = Integration.MAINTAINER_ENPHASE_ID
            } else if (this.manufacturer.isSolaredge) {
                id = Integration.MAINTAINER_SOLAREDGE_ID
            } else if (this.manufacturer.isGenerac) {
                id = Integration.MAINTAINER_GENERAC_ID
            }
        }
        return api.integrationCredentials.showCredentials(id)
    }

    update = (payload: Payload, params?: Params, options?: Options) =>
        api.http.put(Integration.endpoint, { id: this.id, ...payload }, params, options)

    destroy = (params?: Params, options?: Options) => api.http.delete(Integration.endpoint, {
        company_id: this.company_id,
        provider: this.manufacturer.key,
        ...params
    }, options)
}
