import { useState, useEffect } from 'react'

import { Alert, Button, IconBatteryCharging, IconHome, IconClose, IconAlertTriangle } from '@/components'
import { useAuth } from '@/hooks'
import { Invoice } from '@/models'
import { sessionStorage } from '@/services'

type DashboardAlertProps = {
    className?: string
}

const DashboardAlert = ({ className, ...props }: DashboardAlertProps) => {
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [seenBanners, setSeenBanners] = useState<string[]>(sessionStorage.get('seen_banners') as string[] || [])
    const [isInvoiced, setIsInvoiced] = useState(false)
    const [processing, setProcessing] = useState(true)
    const auth = useAuth()

    const fetchIsInvoiced = async (controller: AbortController) => {
        try {
            const { meta } = await Invoice.index({}, { signal: controller.signal })
            setIsInvoiced(!!meta.overall_count)
        } finally {
            setProcessing(false)
        }
    }

    const fetchData = () => {
        const controller = new AbortController
        setAbortController(controller)
        if (auth.user?.isContractor) {
            fetchIsInvoiced(controller)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const handleClose = (banner: string) => {
        const seenBanners = sessionStorage.get('seen_banners') as string[] || []
        seenBanners.push(banner)
        setSeenBanners(seenBanners)
        sessionStorage.set('seen_banners', seenBanners)
    }

    if (processing) return null

    const banners = [
        {
            name: 'welcome',
            showIf: auth.user?.isContractor && !auth.user?.company.integrations.length && !isInvoiced,
            banner: <Alert type="custom" className="bg-orange-400 font-body">
                <div className="flex flex-row items-start lg:items-center gap-2 w-full justify-between">
                    <div className="flex items-start lg:items-center grow gap-4">
                        <div className="bg-orange-100 flex items-center justify-center p-3 rounded-lg">
                            <IconHome className="stroke-orange-600" size="lg"/>
                        </div>

                        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                            <p className="leading-6 ml-4">Welcome to the Solar Insure Web Platform! To finish Certification and start registering <br/>Solar Insure warranties, <span className="font-semibold">Add Your Monitoring Credentials</span></p>

                            <Button design="btn-secondary-gray" href="/profile/integrations">
                                Add Your Credentials
                            </Button>
                        </div>
                    </div>
                </div>
            </Alert>
        },
        {
            name: '30YearBattery',
            showIf: auth.user?.isContractor &&
                !auth.user?.company.isEligibleFor30YearBatteries &&
                new Date() < new Date('12/01/2024') &&
                !seenBanners.includes('30YearBattery'),
            banner: <Alert type="custom" className="bg-gradient-to-r from-blue-800 to-blue-500 font-body text-white">
                <div className="flex flex-row items-start lg:items-center gap-2 w-full justify-between">
                    <div className="flex items-start lg:items-center grow gap-4">
                        <div>
                            <svg id="gradient-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34 19v-2m-15.2 7h7.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C31 21.72 31 20.88 31 19.2v-2.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C28.72 12 27.88 12 26.2 12h-7.4c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C14 14.28 14 15.12 14 16.8v2.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C16.28 24 17.12 24 18.8 24z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M23.333 21 25 18h-4l1.667-3" stroke="url(#szrybseefb)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.112 31.458c0 .329-.068.614-.205.855-.136.24-.322.437-.556.59a2.481 2.481 0 0 1-.782.337v.03c.573.071 1.008.247 1.304.527.3.28.45.654.45 1.123 0 .417-.103.79-.308 1.118-.202.329-.515.588-.938.777-.423.188-.968.283-1.636.283-.394 0-.761-.033-1.103-.098a4.35 4.35 0 0 1-.957-.288v-1.284c.306.156.627.275.962.356.335.078.648.117.937.117.54 0 .918-.092 1.133-.278.218-.189.327-.452.327-.791a.823.823 0 0 0-.151-.503c-.101-.137-.277-.24-.527-.312-.248-.072-.595-.108-1.04-.108h-.543v-1.157h.552c.44 0 .773-.04 1.001-.122.231-.085.388-.199.469-.342a.978.978 0 0 0 .127-.498c0-.254-.078-.452-.234-.596-.157-.143-.417-.214-.782-.214-.228 0-.436.029-.625.087a2.613 2.613 0 0 0-.503.206 4.56 4.56 0 0 0-.395.229l-.698-1.04c.28-.202.607-.37.981-.503.378-.133.827-.2 1.348-.2.735 0 1.318.148 1.748.444.43.296.644.715.644 1.255zm5.962 1.973c0 .576-.045 1.09-.137 1.543a3.347 3.347 0 0 1-.43 1.152 1.958 1.958 0 0 1-.77.723c-.32.166-.707.249-1.163.249-.573 0-1.043-.145-1.41-.435-.369-.293-.64-.713-.816-1.26-.176-.55-.264-1.207-.264-1.972 0-.772.08-1.431.24-1.978.162-.55.426-.972.79-1.264.365-.294.851-.44 1.46-.44.57 0 1.039.146 1.406.44.372.29.647.71.826 1.26.179.546.268 1.207.268 1.982zm-3.491 0c0 .543.03.997.088 1.362.062.361.164.633.308.815a.715.715 0 0 0 .595.274c.25 0 .448-.09.591-.269.146-.182.25-.454.313-.815.061-.365.092-.82.092-1.367 0-.544-.03-.998-.093-1.363-.061-.364-.166-.638-.312-.82a.705.705 0 0 0-.59-.278.71.71 0 0 0-.596.278c-.144.182-.246.456-.308.82a8.803 8.803 0 0 0-.088 1.363zm4.16 1.499v-1.221h2.622v1.22h-2.622zm6.045-2.13 1.49-2.939h1.63l-2.368 4.36V37h-1.504v-2.73l-2.368-4.409h1.64l1.48 2.94zm6.948-1.361c.075 0 .162.004.26.014.1.007.181.016.243.03l-.112 1.396a.902.902 0 0 0-.21-.034 2.162 2.162 0 0 0-.791.059c-.18.048-.34.128-.483.239-.144.107-.258.25-.342.43a1.54 1.54 0 0 0-.122.649V37h-1.49v-5.459h1.128l.22.918h.073c.108-.186.241-.355.4-.508.163-.156.347-.28.553-.37.208-.095.432-.142.673-.142z" fill="#fff"/>
                                <defs>
                                    <linearGradient id="szrybseefb" x1="21" y1="15" x2="25" y2="15" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FFB953"/>
                                        <stop offset="1" stopColor="#FFD8A2"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <style>{`
                            #gradient-svg {
                                background: linear-gradient(-45deg, #ffd600, #00b8d4, #ffd600, #00b8d4);
                                background-size: 200% 100%;
                                animation: gradient 1.5s linear infinite;
                                border-radius: 10px;
                            }

                            @keyframes gradient {
                                0% { background-position: 0% 0%; }
                                100% { background-position: 203% 0%; }
                            }
                        `}
                        </style>
                        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                            <p>
                                <span className="font-semibold">30-Year Battery Warranty Available!</span> Recertification Required—Contact Your Account Manager.
                            </p>
                            <Button target="_blank" className="text-black" href="https://www.solarinsure.com/battery">
                                Learn more
                            </Button>
                        </div>
                    </div>
                    <Button design="btn-link" className="!p-2.5" onClick={() => handleClose('30YearBattery')}>
                        <IconClose className="stroke-white"/>
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'invalidCredentials',
            showIf: auth.user?.isContractor &&
                !seenBanners.includes('invalidCredentials') &&
                (auth.user?.company.monitoringStatus.isInvalid ||
                    auth.user?.company.monitoringStatus.isMissing ||
                    auth.user?.company.monitoringStatus.isIncomplete),
            banner: <Alert type="custom" className="bg-white border-error-300 font-body">
                <div className="flex flex-row items-start lg:items-center gap-2 w-full justify-between">
                    <div className="flex items-start lg:items-center grow gap-4">
                        <div>
                            <div className="w-10 h-10 rounded-full bg-error-50 flex justify-center items-center">
                                <div className="w-8 h-8 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                                    <IconAlertTriangle/>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                            <p className="leading-6">
                                <span className="font-semibold">You have invalid or missing monitoring credentials!</span><br/>
                                Update now to enable Solar Insure Monitoring.
                            </p>
                            <Button design="btn-secondary" href="/profile/integrations" className="whitespace-nowrap">
                                <span className="font-normal">
                                    Update Credentials
                                </span>
                            </Button>
                        </div>
                    </div>
                    <Button design="btn-link" onClick={() => handleClose('invalidCredentials')}>
                        <IconClose className="stroke-gray-500"/>
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'batteryInfo',
            showIf: auth.user?.isContractor && !auth.user?.company.isEligibleForBatteries && !seenBanners.includes(
                'batteryInfo'),
            banner: <Alert type="custom" className="bg-white font-body">
                <div className="flex flex-row items-start lg:items-center gap-2 w-full justify-between">
                    <div className="flex items-start lg:items-center grow gap-4">
                        <div>
                            <div className="w-10 h-10 rounded-full bg-purple-50 flex justify-center items-center">
                                <div className="w-8 h-8 rounded-full bg-purple-100 flex justify-center items-center stroke-purple-700">
                                    <IconBatteryCharging/>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                            <p className="leading-6">
                                Interested in selling our <span className="font-semibold">New Battery Monitoring & Warranty Product</span>? <br/>
                                Learn More about our Certified Installer program!
                            </p>
                            <Button design="btn-secondary-gray" href="https://www.solarinsure.com/battery" className="whitespace-nowrap">
                                Learn More
                            </Button>
                        </div>
                    </div>
                    <Button design="btn-link" onClick={() => handleClose('batteryInfo')}>
                        <IconClose className="stroke-gray-500"/>
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'batteryWelcome',
            showIf: auth.user?.isContractor && auth.user?.company.isEligibleForBatteries && !seenBanners.includes('batteryWelcome'),
            banner: <Alert type="custom" className="bg-white font-body">
                <div className="flex flex-row items-start lg:items-center gap-2 w-full justify-between">
                    <div className="flex items-start lg:items-center grow gap-4">
                        <div>
                            <div className="w-10 h-10 rounded-full bg-purple-50 flex justify-center items-center">
                                <div className="w-8 h-8 rounded-full bg-purple-100 flex justify-center items-center stroke-purple-700">
                                    <IconBatteryCharging/>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                            <p className="leading-6">
                                You can now sell our <span className="font-semibold">New Battery Monitoring & Warranty Product</span>! Congrats on becoming a Certified Installer!
                            </p>
                            <Button design="btn-secondary-gray" href="https://www.solarinsure.com/battery" className="whitespace-nowrap">
                                Learn More
                            </Button>
                        </div>
                    </div>
                    <Button design="btn-link" onClick={() => handleClose('batteryWelcome')}>
                        <IconClose className="stroke-gray-500"/>
                    </Button>
                </div>
            </Alert>
        }
    ]

    const getBanner = () => banners.find(({ showIf }) => showIf)?.banner

    return <div className={className} {...props}>{getBanner()}</div>
}

export default DashboardAlert
