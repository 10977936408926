import classnames from 'classnames'
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react'
import { Link, To, LinkProps as RouterLinkProps } from 'react-router-dom'

import { Tooltip } from '@/components'

export type ButtonSize = 'btn-sm' | 'btn-md' | 'btn-lg' | 'btn-xl' | 'btn-2xl'

interface CustomProps {
    tooltip?: ReactNode
    hasError?: boolean
    processing?: boolean
    disabled?: boolean
    tooltipDisabled?: boolean
    square?: boolean
    design?: 'btn-link' | 'btn-link-gray' | 'btn-tertiary' | 'btn-tertiary-gray' | 'btn-secondary' | 'btn-secondary-gray' | 'btn-primary'
    size?: ButtonSize
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, CustomProps {}

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement>, CustomProps {}

export interface LinkProps extends RouterLinkProps, CustomProps {
    href: To
}

export const Button = ({
    children,
    className = '',
    hasError = false,
    processing = false,
    design = 'btn-primary',
    size = 'btn-md',
    square = false,
    disabled,
    tooltipDisabled,
    tooltip,
    ...props
}: ButtonProps | AnchorProps | LinkProps) => {
    const classNames = classnames('btn', className, design, size, {
        'btn-error': hasError,
        'btn-square': square,
        disabled: disabled || processing
    })

    const isExternalURL = (url: string | To) => typeof url === 'string' && url.startsWith('http')

    let button
    if ('href' in props && props.href && !disabled) {
        button = isExternalURL(props.href)
            ? <a className={classNames} {...props as AnchorProps}>
                {children}
            </a>
            : <Link className={classNames} {...props as LinkProps} to={props.href}>
                {children}
            </Link>
    } else {
        button = <button className={classNames} disabled={disabled || processing} {...props as ButtonProps}>
            {children}
        </button>
    }

    if (tooltip) {
        return <Tooltip content={tooltip} disabled={typeof tooltipDisabled === 'undefined' ? disabled : tooltipDisabled}>
            {button}
        </Tooltip>
    }
    return button
}
