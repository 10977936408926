import { useState } from 'react'

import {
    Button,
    Logo,
    IconCheck,
    IconCertificate,
    IconBarLineChart,
    IconBankNote,
    IconFileShield, Alert, Form, FormErrorsType, FormChangeEvent, FormDataType
} from '@/components'

interface StepFourFormDataType extends FormDataType {
    preferences: string[]
}

type StepFourProps = {
    onSubmit: () => void
}

const StepFour = ({ ...props }: StepFourProps) => {
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [form, setForm] = useState<StepFourFormDataType>({ preferences: [] })
    const handleSubmit = async () => {
        if (!form.preferences.length) {
            setErrors({ preferences: ['This field is required'] })
        } else {
            props.onSubmit()
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        const index = form.preferences.indexOf(e.target.value as string)
        if (index === -1) {
            setForm({
                ...form,
                preferences: [...form.preferences, e.target.value as string]
            })
        } else {
            setForm({
                ...form,
                preferences: form.preferences.filter(item => item !== e.target.value)
            })
        }
    }

    const options = [
        {
            value: 'warranty',
            icon: <IconCertificate size="sm"/>,
            title: 'Warranty Registration',
            subTitle: 'Entering new homeowner data to register warranties'
        },
        {
            value: 'monitoring',
            icon: <IconBarLineChart size="sm"/>,
            title: 'Monitoring',
            subTitle: 'Reviewing and troubleshooting fleet monitoring data'
        },
        {
            value: 'invoicing',
            icon: <IconBankNote size="sm"/>,
            title: 'Invoicing',
            subTitle: 'Reviewing and paying invoices for monthly warranty sales'
        },
        {
            value: 'claims',
            icon: <IconFileShield size="sm"/>,
            title: <>Claims Filing <i>Coming Soon</i></>,
            subTitle: 'Entering new homeowner data to register warranties'
        }
    ]

    return <div className="h-auto signup-step-four">
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            Define Your <br/> Preferences
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light mb-8">
            Help us learn about your workflow needs so we can create a tailored experience. Please select the one or
            more features you’d expect to use most frequently.
        </h3>
        <Form onSubmit={handleSubmit}>
            <div className="preferences mb-6">
                {options.map((step, index) => <div
                    className={`option relative rounded-lg p-4 font-light mb-3 grid items-center cursor-pointer border border-gray-200 ${form.preferences.includes(step.value) ? 'active border border-primary-700 bg-primary-200' : ''}`}
                    onClick={() => handleChange({ target: { value: step.value, name: 'option' } })}
                    role="presentation"
                    key={index}
                >
                    {form.preferences.includes(step.value)
                        ? <IconCheck className="check stroke-white bg-primary-700 absolute w-4 h-4 rounded-full p-0.5" size="xs"/>
                        : <span className="select absolute w-4 h-4 rounded-full"/>}
                    <div className={`flex justify-center items-center self-start relative top-4 rounded-full bg-primary-50 ${form.preferences.includes(step.value) ? 'w-6 h-6 left-1 top-5' : 'w-8 h-8'}`}>
                        <div className="w-6 h-6 flex justify-center items-center p-1 rounded-full bg-primary-100 stroke-primary-700">
                            {step.icon}
                        </div>
                    </div>
                    <div className="content grid text-sm">
                        <span className="text">{step.title}</span>
                        <span className="subText text-gray-700">{step.subTitle}</span>
                    </div>
                </div>)}
            </div>
            {errors.preferences && <Alert type="error" className="mb-4">{errors.preferences}</Alert>}
            <Button className="w-full">
                Continue
            </Button>
        </Form>
    </div>
}

export default StepFour
