import { useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'

import {
    Badge,
    Button,
    IconCurrencyDollar,
    IconDownload,
    IconMail,
    Table,
    InvoiceSendPopup, PageHeader, RowProps
} from '@/components'
import { AuthLayout } from '@/containers'
import { Company, Invoice, Warranty } from '@/models'
import { sessionStorage } from '@/services'

const breadcrumb = [
    <IconCurrencyDollar key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/invoices">Invoice Summary</Link>,
    'Invoice Summary Details'
]

const InvoicesShow = () => {
    const { invoice, company } = useLoaderData() as { invoice: Invoice, company: Company }

    const [isOpen, setIsOpen] = useState(false)
    const [rowsCount, setRowsCount] = useState(0)

    const rows = invoice.homeowners?.reduce((acc: RowProps[], homeowner) =>
        [...acc, ...homeowner.warranties.map((warranty: Warranty) => ({
            id: homeowner.id,
            homeowner: homeowner.full_name,
            company: homeowner.company?.name,
            date: homeowner.created_at?.format(),
            address: <>
                <p>{homeowner.street_address}</p>
                <p className="text-gray-500">{homeowner.city}, {homeowner.state} {homeowner.zip}</p>
            </>,
            size_kw: `${Warranty.getField('size_kw').format(warranty.size_kw)} kW`,
            battery_size_kw: `${warranty.battery_size_kw} kW`,
            num_panels: warranty.num_panels,
            price: warranty.price.money(),
            _path: `/warranties/${warranty.id}/warranty`
        }))], []) || []

    const heading = <PageHeader
        title={company.name}
        backUrl={`/invoices${sessionStorage.get('preservedQuery.invoices')}`}
        subtitle={<p className="text-gray-500 text-base mt-2 font-light">
            Registration Timeframe: <span className="text-orange-700 bg-orange-50 px-2 py-1 md:ml-1 rounded-sm text-sm">
                {new Date(`${invoice.date} 00:00`).format({
                    day: null,
                    month: 'long'
                })}
            </span>
        </p>}
    />
    return <AuthLayout heading={heading} breadcrumb={breadcrumb}>
        <div className="flex justify-between items-center">
            <Badge className="bg-primary-50 text-primary-700">
                {rowsCount} of {rows.length} invoice line items
            </Badge>

            <div className="flex">
                <Button square onClick={() => setIsOpen(true)} design="btn-secondary-gray" className="ml-0 sm:ml-3" size="btn-lg">
                    <IconMail/>
                </Button>
                <Button square onClick={() => invoice.download()} design="btn-secondary-gray" className="ml-0 sm:ml-3 ml-3" size="btn-lg">
                    <IconDownload/>
                </Button>
            </div>
        </div>

        <Table
            sortable={false}
            searchable={false}
            className="mt-6"
            onRowsChange={setRowsCount}
            columns={[
                { field: 'date', title: 'Submission Date' },
                { field: 'homeowner', title: 'Homeowner' },
                { field: 'company', title: 'Company', showIf: company.isAffiliate },
                { field: 'address', title: 'Address', className: 'min-w-[13rem]' },
                { field: 'size_kw', title: 'System Size' },
                { field: 'battery_size_kw', title: 'Battery Capacity' },
                { field: 'num_panels', title: 'Number of Panels', className: 'min-w-[7rem]' },
                { field: 'price', title: 'Price' }
            ]}
            rows={rows}
        />

        <InvoiceSendPopup
            isOpen={isOpen}
            invoice={invoice}
            onClose={() => setIsOpen(false)}
        />

    </AuthLayout>
}

export default InvoicesShow
