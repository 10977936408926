import { useState } from 'react'

import { Button, Form, FormChangeEvent, Upload } from '@/components'
import { excel, loadingBar } from '@/services'

type UploadFileStepProps = {
    form: any
    onCancel: any
    onNext: any
    onChange: any
}

const UploadFileStep = ({ form, ...props }: UploadFileStepProps) => {
    const [error, setError] = useState<string>('')
    const handleFileChange = async (e: FormChangeEvent) => {
        const [file] = e.target.value as File[]
        /*if (file uploaded in past) {
            setError('Caution: The file has been previously uploaded')
        } else */
        if (file?.type !== 'text/csv') {
            setError('Caution: Incorrect File Type')
        } else if (file?.size > 1e+7) { // 1e+7bytes = 10MB
            setError('Caution: File Size Too Large')
        } else if (file) {
            const data = await excel.parse(file)
            props.onChange({ target: { name: 'data', value: data } })
        }
        props.onChange({ target: { name: 'file', value: file } })
    }

    const handleSubmit = () => {
        loadingBar.start()
        props.onNext()
        loadingBar.stop()
    }

    return <Form onSubmit={handleSubmit}>
        <Upload
            value={form.file}
            validate={false}
            onChange={handleFileChange}
            accept=".csv,text/csv"
            className={`${error ? 'bg-error-25 !border-error-300' : ''} ${!error && form.file ? 'bg-success-25 !border-success-200' : ''}`}
        >
            <div className="mt-3 text-center text-gray-700">
                {form.file && !error
                    ? <p className="text-sm text-gray-500">
                        <span className="text-orange-700">{form.file.name}</span> uploaded successfully
                    </p>
                    : <>
                        {form.file && <p className="text-sm">{form.file.name}</p>}
                        <p className="text-sm">
                            <span className="text-orange-700">Click to upload</span> or drag and drop
                        </p>
                        {error && <p className="text-xs mt-1 font-semibold">{error}</p>}
                        <p className="text-xs mt-1">(.csv files only. One file at a time. 10 mb file size max)</p>
                    </>}
            </div>
        </Upload>

        <div className="flex justify-end gap-3 mt-4">
            <Button design="btn-secondary-gray" onClick={props.onCancel} type="button">
                Cancel
            </Button>

            <Button disabled={!form.file || !!error}>
                Next
            </Button>
        </div>
    </Form>
}

export default UploadFileStep
