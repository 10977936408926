import { useState } from 'react'

import {
    WarrantyHistoryTable,
    WarrantyPotentialDuplicateCards,
    WarrantyDetails
} from '@/components'
import { Warranty } from '@/models'

const WarrantiesShow = ({ warranty, ...props }: { warranty: Warranty, onChange: () => void }) => {
    const [activeDuplicateIndex, setActiveWarrantyIndex] = useState(0)

    const handleDuplicateChange = () => {
        setActiveWarrantyIndex(activeDuplicateIndex === 0 ? 0 : activeDuplicateIndex - 1)
        props.onChange()
    }

    return <>
        {warranty.status.isDuplicate &&
            <WarrantyPotentialDuplicateCards
                warranty={warranty}
                activeIndex={activeDuplicateIndex}
                onChange={handleDuplicateChange}
                onIndexChange={setActiveWarrantyIndex}
            />}

        <div data-test="warranty-details-page" className={`grid gap-6 mb-6 ${warranty.status.isDuplicate ? 'lg:grid-cols-4' : 'lg:grid-cols-2'}`}>
            <WarrantyDetails warranty={warranty} duplicate={warranty.all_duplicates[activeDuplicateIndex]}/>
            {warranty.status.isDuplicate && warranty.all_duplicates[activeDuplicateIndex] &&
                <WarrantyDetails warranty={warranty.all_duplicates[activeDuplicateIndex]} duplicate={warranty}/>}
        </div>

        <WarrantyHistoryTable warranty={warranty}/>
    </>
}

export default WarrantiesShow
