import {
    ActionReasonFormDataType,
    ActionReasonForm,
    Button, Form,
    FormErrorsType,
    IconInfoCircle,
    Popup,
    WarrantyUpdateDetails, FormChangeEventHandler
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyConfirmEditPopupProps = {
    form: ActionReasonFormDataType
    errors: FormErrorsType
    newPrice: number
    isOpen: boolean
    processing: boolean
    warranty: Warranty
    onClose: () => void
    onSubmit: () => void
    onChange: FormChangeEventHandler
}

export const WarrantyConfirmEditPopup = ({
    warranty,
    form,
    errors,
    newPrice,
    processing,
    isOpen,
    ...props
}: WarrantyConfirmEditPopupProps) => {
    const auth = useAuth()

    const {
        edit_reason: editReason,
        edit_contact_email: editContactEmail,
        ...filteredForm
    } = form

    return <Popup open={isOpen} onClose={props.onClose} data-test="confirm-warranty-edits-popup" className="w-200">
        <Form onSubmit={props.onSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
            <p className="text-lg font-semibold flex gap-2 items-center">
                <IconInfoCircle className="stroke-warning-600" size="lg"/>
                {!warranty.isLocked && auth.user?.isContractor
                    ? 'Edit Same-Day registered warranty'
                    : 'Confirm Warranty Edits'}
            </p>

            {auth.user?.isContractor && <p className="text-gray-500">
                Changing the System Size or Battery Warranty information will alter the price of the warranty and
                require review from a Solar Insure staff member.
            </p>}

            <WarrantyUpdateDetails
                oldValues={{
                    ...warranty,
                    ...warranty.homeowner,
                    affiliate_name: warranty.affiliate?.name,
                    panel_name: warranty.panel?.model_name,
                    inverter_name: warranty.inverter?.model_name,
                    inverter_manufacturer: warranty.inverter?.provider
                }}
                newValues={{
                    ...filteredForm,
                    price: newPrice
                }}
            />

            {auth.user?.isContractor && <ActionReasonForm
                form={form}
                errors={errors}
                actionName="Edit"
                onChange={props.onChange}
            />}

            <p className="text-sm font-semibold mb-2" data-test="approve-edits-text">
                Do you want to save these edits to the warranty?
            </p>
            <div className="flex flex-col lg:flex-row gap-2 justify-between">
                {auth.user?.isAdminOrStaff && <Button
                    className="w-40"
                    design="btn-secondary"
                    hasError
                    onClick={props.onClose}
                    type="button"
                    data-test="modify-warranty-edit-button"
                >
                    Modify Warranty
                </Button>}
                <div className="flex ml-auto gap-2">
                    <Button
                        className="w-40"
                        design="btn-secondary-gray"
                        hasError
                        onClick={props.onClose}
                        type="button"
                        data-test="cancel-warranty-edit-button"
                    >
                        Cancel
                    </Button>
                    {auth.user?.isAdminOrStaff
                        ? <Button
                            className="w-40"
                            processing={processing}
                            data-test="confirm-warranty-edit-button"
                        >
                            Yes, Confirm Edits
                        </Button>
                        : <Button
                            className="w-40"
                            processing={processing}
                            data-test="save-warranty-edit-button"
                        >
                            Yes, Save Edits
                        </Button>}
                </div>
            </div>
        </Form>
    </Popup>
}
