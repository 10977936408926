import { Button, Form, Logo } from '@/components'

type ComponentProps = {
    onSubmit: () => void
}
const StepSix = (props: ComponentProps) =>
    <Form className="h-auto signup-step-four" onSubmit={props.onSubmit}>
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            Account Created Successfully!
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light px-3">
            Thanks for creating a new account and Welcome to the Solar Insure Team! Login to the platform to gain access
            to Solar Insure’s exciting products and features!
        </h3>
        <div>
            <Button className="w-full font-light">
                Go to Platform
            </Button>
        </div>
    </Form>

export default StepSix
