import { Button, Form, FormChangeEvent, Select, Table } from '@/components'
import { Company } from '@/models'
import { loadingBar } from '@/services'

type AssignAffiliateStepProps = {
    form: any
    affiliates: Company[]
    onCancel: any
    onNext: any
    onChange: any
}

const AssignAffiliateStep = ({ form, affiliates, ...props }: AssignAffiliateStepProps) => {
    const handleSubmit = () => {
        loadingBar.start()
        props.onNext()
        loadingBar.stop()
    }

    const handleChange = (e: FormChangeEvent) => {
        props.onChange({
            target: {
                name: e.target.name,
                value: affiliates.find(item => item.id?.toString() === e.target.value?.toString())
            }
        })
    }

    return <Form onSubmit={handleSubmit}>
        <h4 className="text-primary-700 py-4 mb-2">Uploaded Files</h4>

        <Table
            className="stripped-table"
            searchable={false}
            sortable={false}
            pagination={false}
            useQueryParams={false}
            columns={[
                { title: '#', field: 'number' },
                { title: 'Affiliate', field: 'affiliate', cellClassName: 'text-base' },
                { title: 'File Name', field: 'file_name' }
            ]}
            rows={[{
                id: 1,
                number: 1,
                affiliate: <Select
                    id="affiliate"
                    name="affiliate"
                    className="min-w-[250px]"
                    options={[
                        { value: '', title: 'Select Affiliate' },
                        ...affiliates.map(item => ({
                            value: item.id,
                            title: item.name
                        }))
                    ]}
                    value={form.affiliate?.id}
                    onChange={handleChange}
                />,
                file_name: <div className="flex flex-col">
                    <p className="text-orange-700">
                        {form.file.name}
                    </p>
                    <p className="text-xs text-gray-500 leading-[18px]">Completed {new Date().format({
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZoneName: 'short',
                        hour12: false
                    })}</p>
                </div>
            }]}
        />

        <div className="flex justify-end gap-3 mt-4">
            <Button design="btn-secondary-gray" onClick={props.onCancel} type="button">
                Previous
            </Button>

            <Button disabled={!form.affiliate}>
                Next
            </Button>
        </div>
    </Form>
}

export default AssignAffiliateStep
