import { Button, Dropdown, IconChevronDown } from '@/components'
import { useAuth } from '@/hooks'
import { CompanyAccountActivityStatus } from '@/models'

type WarrantyCreateButtonProps = {
    className?: string
}
export const WarrantyCreateButton = ({ className }: WarrantyCreateButtonProps) => {
    const auth = useAuth()

    return <Dropdown
        disabled={!auth.user?.isAllowedToCreateWarranty || auth.user.company.account_activity_status.isDisabled}
        className={className}
        button={<Button
            disabled={!auth.user?.isAllowedToCreateWarranty || auth.user.company.account_activity_status.isDisabled}
            tooltipDisabled={auth.user?.isAllowedToCreateWarranty &&
                !auth.user.company.account_activity_status.isDisabled}
            tooltip={auth.user?.company.account_activity_status.isDisabled
                ? CompanyAccountActivityStatus.DISABLED_STATUS_MESSAGE
                : <div className="text-center">
                    <p>Registration disabled: Certification incomplete.</p>
                    <p>Please submit the required credentials to proceed with warranty registration.</p>
                    <p>Contact your account manager if you have questions about your certification status.</p>
                </div>}
        >
            Add New Warranty
            <IconChevronDown className="ml-2" size="md"/>
        </Button>}
    >
        <div className="px-2">
            <Button design="btn-link-gray" className="w-full !py-2.5" href="/warranties/create">
                Single Warranty
            </Button>
            <Button design="btn-link-gray" className="w-full !py-2.5" href="/warranties/upload">
                Bulk Upload
            </Button>
        </div>
    </Dropdown>
}
