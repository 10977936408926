import { useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import {
    Button,
    Card,
    Form,
    FormChangeEvent,
    FormErrorsType,
    IconCube,
    PageHeader,
    ProductForm,
    ProductFormDataType
} from '@/components'
import { AuthLayout } from '@/containers'
import { useFormData, useToastNotifications } from '@/hooks'
import { Product } from '@/models'
import { isApiError, sessionStorage } from '@/services'

const ProductsEdit = () => {
    const product = useLoaderData() as Product
    const navigate = useNavigate()
    const { success, error } = useToastNotifications()
    const { convert } = useFormData()

    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<ProductFormDataType>({
        model_name: product.model_name,
        pdf_template_id: product.pdf_template_id,
        product_type_id: product.product_type_id,
        ...product.properties.reduce((acc, item) => ({
            ...acc,
            [`properties[${item.property_id}]`]: item.property.data_type === 'Boolean'
                ? !!parseInt(item.value as string)
                : item.value
        }), {})
    })

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name as string]: e.target.type === 'checkbox' ? e.target.checked : e.target.value as string
        })
    }
    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})
        try {
            const clone = { ...form }
            delete clone.pdf_template_id
            await product.update(convert(clone))
            success('Product successfully updated')
            navigate('/products')
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const breadcrumb = [
        <IconCube key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/products">Products</Link>,
        'Edit Product'
    ]

    return <AuthLayout
        heading={<PageHeader
            title="Edit Product"
            backUrl={`/products${sessionStorage.get('preservedQuery.products')}`}
        />}
        breadcrumb={breadcrumb}
    >
        <Card>
            <Form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
                <div className="flex flex-col gap-6">
                    <ProductForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex justify-end lg:col-span-2 gap-3">
                    <Button design="btn-secondary-gray" hasError href="/products">
                        Cancel
                    </Button>
                    <Button type="submit" processing={processing}>
                        Save
                    </Button>
                </div>
            </Form>
        </Card>
    </AuthLayout>
}

export default ProductsEdit
