import { Homeowner } from './Homeowner'
import { Model } from './Model'
import { toastNotifications, api, Options, Params } from '@/services'
import { IdType, InvoiceDataType } from '@/types'

export class Invoice extends Model {
    static endpoint = 'api/v1/invoices'

    company_id: IdType

    row_id: IdType

    row_name: string

    date: string

    total_warranties: number

    panels_number: number

    sales: number

    homeowners: Homeowner[]

    constructor(data: InvoiceDataType) {
        super(data)
        const date = new Date(data.date).isValid() ? new Date(`${data.date} 00:00`) : new Date(`1 ${data.date}`)
        this.id = `${data.row_id}/${date.toISODate()}`
        this.company_id = data.row_id
        this.row_id = data.row_id
        this.row_name = data.row_name
        this.date = data.date
        this.homeowners = data.homeowners?.map(item => new Homeowner(item)) || []
        this.total_warranties = Model.toNumber(data.total_warranties, 0) as number
        this.panels_number = Model.toNumber(data.panels_number, 0) as number
        this.sales = Model.toNumber(data.sales, 0) as number
    }

    static async show<T extends typeof Model>(this: T,
        id: IdType,
        params?: Params,
        options?: Options
    ): Promise<InstanceType<T> | null> {
        const res = await api.http.get(`${Invoice.endpoint}/${id}`, params, options)
        if (!id) return null
        const [companyId, date] = id.toString().split('/')
        return new Invoice({
            id: `${companyId}/${new Date(date).toISODate()}`,
            row_name: companyId as string,
            date,
            row_id: companyId,
            homeowners: res.data || []
        }) as InstanceType<T>
    }


    async email(email: string) {
        const res = await api.invoices.email(this.id, email)
        toastNotifications.success('Invoice successfully sent.')
        return res
    }

    download() {
        return api.invoices.download(this.id)
    }

    static downloadMany(invoiceItems: Invoice[]) {
        return api.invoices.downloadMany(invoiceItems)
    }
}
