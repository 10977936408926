import { useState } from 'react'

import {
    Card,
    ClickToCopy,
    IconAlertCircle,
    IconEye,
    IconEyeOff,
    IconKey02
} from '@/components'
import { Integration } from '@/models'

const SolarEdgeCredentialsSection = ({ integration }: { integration: Integration }) => {
    const [showApiKey, setShowApiKey] = useState(false)
    const getCardType = () => {
        if (integration.isMissing) return 'error'
        if (integration.isInvalid) return 'warning'
        return undefined
    }

    return <Card type={getCardType()}>
        <h3 className="text-gray-900 font-semibold mb-4">API Key</h3>

        <div className="flex w-2/3 gap-2 w-full items-center break-anywhere">
            <div>
                <IconKey02 className="stroke-gray-700"/>
            </div>
            {integration.api_key
                ? <div className="flex gap-1 items-center text-sm">
                    {integration.api_key?.split('').map((item: string) => showApiKey ? item : '•')}
                    <button onClick={() => setShowApiKey(state => !state)}>
                        {showApiKey
                            ? <IconEyeOff className="stroke-gray-500" size="sm"/>
                            : <IconEye className="stroke-gray-500" size="sm"/>}
                    </button>
                    {integration.isInvalid ? <IconAlertCircle/> : <ClickToCopy content={integration.api_key}/>}
                </div>
                : <div className={integration.isInvalidOrMissing ? integration.status.color : ''}>
                    _____________________
                </div>}
        </div>
        {integration.isInvalidOrMissing && <div className="text-xs mt-4">
            {integration.status.title}
        </div>}
    </Card>
}

export default SolarEdgeCredentialsSection
