import { SYSTEM_STATUSES_UNMONITORED } from './system-statuses-unmonitored'
import { SystemTierCategoryEnum } from '@/enums'
import { SystemTierCategoryDataType } from '@/types'

export const SYSTEM_TIER_CATEGORIES_UNMONITORED_SITES: SystemTierCategoryDataType[] = [
    {
        value: SystemTierCategoryEnum.UNMONITORED,
        statuses: SYSTEM_STATUSES_UNMONITORED
    }
]
