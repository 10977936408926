import { ChartDataset, ChartOptions } from 'chart.js'
import merge from 'lodash/merge'
import { useState } from 'react'

import Chart from './Chart'
import { api } from '@/services'

type USMapChartProps = {
    datasets: ChartDataset[] | any[]
    labels: string[]
    plugins?: any
    options?: ChartOptions
    onInit?: any
    formatTooltip: any
}

const USMapChart = ({ plugins, labels, datasets, options, ...props }: USMapChartProps) => {
    const [active, setActive] = useState<any>({
        show: false,
        index: 0,
        item: null,
        x: 0,
        y: 0
    })

    const defaultOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            datalabels: {
                display: true,
                color: '#fff',
                font: { size: 10 },
                formatter: value => api.address.statesStatic[value.feature?.properties?.name] || ''
            },
            legend: { display: false },
            tooltip: {
                enabled: false,
                external: context => {
                    const tooltipModel = context.tooltip
                    const [activeEl] = tooltipModel.getActiveElements()
                    if (activeEl && (activeEl.index !== active.index || !active.show)) {
                        setActive({
                            show: true,
                            index: activeEl.index,
                            item: activeEl,
                            x: tooltipModel.caretX + 8,
                            y: tooltipModel.caretY
                        })
                    }
                }
            }
        },
        scales: {
            projection: {
                axis: 'x',
                projection: 'albersUsa'
            },
            color: {
                display: false,
                axis: 'x',
                beginAtZero: true,
                interpolate: (v: number) => {
                    if (v < 0.2) {
                        return '#b4bcd5'
                    } else if (v < 0.4) {
                        return '#909dc2'
                    } else if (v < 0.6) {
                        return '#7c8cb7'
                    } else if (v < 0.8) {
                        return '#576fa3'
                    }
                    return '#3a5c96'
                },
                quantize: 5
            }
        }
    }

    return <div className="relative max-h-full flex justify-center items-center" onMouseLeave={() => setActive({ ...active, show: false })}>
        {active.show && active.index >= 0 && props.formatTooltip && <div
            className="px-3 py-2 absolute font-body bg-white shadow-md rounded-md -translate-y-1/2 z-40"
            style={{ left: `${active.x}px`, top: `${active.y}px` }}
            onMouseEnter={() => setActive({ ...active, show: true })}
            onMouseLeave={() => setActive({ ...active, show: false })}
        >
            <div className="absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-white rotate-45 origin-center"/>
            {props.formatTooltip(active.item?.element?.feature?.properties?.name)}
        </div>}
        <Chart
            type="choropleth"
            data={{
                labels,
                datasets
            }}
            options={merge(defaultOptions, options)}
            plugins={plugins}
            onInit={props.onInit}
        />
    </div>
}

export default USMapChart
