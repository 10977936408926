import { Device } from './Device'
import { System } from './System'
import {
    DEFAULT_DEVICE_TYPE,
    DeviceAttributeEnum,
    DeviceTypeEnum
} from '@/enums'
import { InverterManufacturer } from '@/models/InverterManufacturer'
import { DeviceCategoryDataType, IdType } from '@/types'

export type DeviceAttributeType = {
    key: DeviceAttributeEnum
    title: string
}

export type DeviceType = {
    key: DeviceTypeEnum
    title: string
    defaultStatus?: string
    attributes?: DeviceAttributeType[]
}

export class DeviceCategory {
    id: IdType

    manufacturer: InverterManufacturer

    type: DeviceTypeEnum

    devices: Device[]

    system: System

    constructor(data: DeviceCategoryDataType) {
        this.id = data.id || data.type
        this.type = data.type
        this.manufacturer = InverterManufacturer.find(data.provider)
        this.system = data.system
        this.id = `${data.system.id}-${data.type}`
        this.devices = data.devices
            .map(item => new Device({ ...item, category: this }))
            .filter(item => !item.isVirtual)
    }

    get deviceType() {
        return this.manufacturer.deviceTypes.find(({ key }) => key === this.type) || DEFAULT_DEVICE_TYPE
    }

    get title() {
        return this.deviceType.title
    }

    get attributes() {
        return this.deviceType.attributes || []
    }

    get normalDevices() {
        return this.devices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.devices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.devices.filter(item => item.isErrored)
    }

    get statusIcon() {
        if (this.system.manufacturer.isEnphase || this.system.manufacturer.isGenerac) {
            if (this.erroredDevices.length) {
                return Device.getIcon('error', 'xs', true,
                    <span className="text-error-600 text-sm">{this.erroredDevices.length}</span>)
            }
            if (this.disabledDevices.length) {
                return Device.getIcon('disabled', 'xs')
            }
            return Device.getIcon('normal', 'xs')
        } else if (this.system.manufacturer.isSolaredge) {
            return <span>({this.devices.length})</span>
        }

        return null
    }
}
