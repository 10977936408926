import { SYSTEM_STATUSES_EXCLUDED } from './system-statuses-excluded'
import { SystemTierCategoryEnum } from '@/enums'
import { SystemTierCategoryDataType } from '@/types'

export const SYSTEM_TIER_CATEGORIES_EXCLUDED_SITES: SystemTierCategoryDataType[] = [
    {
        value: SystemTierCategoryEnum.EXCLUDED,
        statuses: SYSTEM_STATUSES_EXCLUDED
    }
]
