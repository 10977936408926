import {
    Card,
    Checkbox,
    ClickToCopy,
    IconHelpCircle,
    IconPasscode,
    IconUser01,
    Tooltip
} from '@/components'
import { Integration } from '@/models'

export const MaintainerCredentialsSection = ({ integration }: { integration: Integration }) => {
    const getIsChecked = () => {
        if (integration.manufacturer.isGenerac) {
            return !!(integration.contact_name && integration.contact_email)
        } else if (integration.manufacturer.isSolaredge) {
            return integration.isUsesMaintainer || !!(integration.contact_name && integration.contact_email)
        }
        return integration.isUsesMaintainer
    }

    const handleCopyPassword = async (): Promise<string> => integration.showCredentials()

    return <Card>
        <h3 className="text-gray-900 font-semibold mb-4">Maintainer Account Integration</h3>
        <div className="flex items-center my-2">
            <Checkbox
                id="credentials-status"
                name="credentials_status"
                value="uses_maintainer"
                checked={getIsChecked()}
                disabled
            />
            <span className="ml-2 text-gray-800 text-xs">
                Contractor Acknowledged Requirement
            </span>
        </div>
        {integration.isUsesMaintainer && <div className="mt-2 flex justify-between items-end">
            <span className="text-gray-600 text-2xs italic font-body">
                {!integration.manufacturer.isEnphase && <>
                    {integration.contact_name} <br/>
                    {integration.contact_email}
                </>}
            </span>
            <span className="text-gray-600 text-2xs italic font-body">
                {integration.status_change_detected_at?.format()}
            </span>
        </div>}

        {integration.manufacturer.isGenerac && integration.isInvalid &&
            <div className="flex items-baseline gap-2 text-xs mt-4 font-body">
                Daybreak has not been able to find any sites for warranties linked to this
                contractor in the Solar Insure Maintainer account on PWRfleet.
            </div>}

        <div className="flex items-baseline gap-2 mb-2">
            <span className={`${integration.isComplianceVerified ? 'text-success-600 bg-success-100' : 'text-purple-700'} text-xs px-2 py-1 rounded-2xl`}>
                {integration.isComplianceVerified
                    ? 'Contractor Compliance Verified'
                    : 'Contractor Compliance Not Verified'}
            </span>
            <Tooltip
                interactive
                content={
                    <div className="font-body">
                        <h3 className="font-semibold text-xs">
                            {integration.isComplianceVerified ? 'Contractor Compliance Verified' : 'Contractor Compliance Not Verified'}
                        </h3>
                        <p className="mt-1 text-xs">
                            {integration.isComplianceVerified
                                ? 'At least one solar system with an active warranty has been located within our Solar Insure owned Maintainer account.'
                                : 'We are currently unable to retrieve information for any solar system under an active warranty through a Solar Insure owned Maintainer account for this Manufacturer.'}
                        </p>
                    </div>
                }
            >
                <IconHelpCircle className="stroke-gray-400 relative bottom-1" size="sm"/>
            </Tooltip>
        </div>


        {
            integration.isUsesMaintainer &&
            (integration.manufacturer.isEnphase || integration.manufacturer.isGenerac) && <>
                <hr className="mt-4"/>
                <h3 className="text-gray-900 font-semibold mt-4">Maintainer Account Login Credentials</h3>

                <div className="flex gap-1 items-center text-sm mt-4">
                    <IconUser01 className="stroke-gray-700"/>
                    {Integration.MAINTAINER_USERNAME}
                    <ClickToCopy content={Integration.MAINTAINER_USERNAME}/>
                </div>
                <div className="flex gap-1 items-center text-sm mt-4">
                    <IconPasscode className="stroke-gray-700"/>
                    ••••••••••••
                    <ClickToCopy content={handleCopyPassword}/>
                </div>
            </>
        }
    </Card>
}

export default MaintainerCredentialsSection
