import { FormChangeEventHandler, FormDataType, FormErrorsType, Input } from '@/components'

export interface PasswordFromDataType extends FormDataType {
    current_password?: string
    password: string
    password_confirmation: string
}

type PasswordFormProps = {
    form: PasswordFromDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
    inputClassName?: string
}
export const PasswordForm = ({ form, inputClassName, errors, ...props }: PasswordFormProps) =>
    <>
        {typeof form.current_password !== 'undefined' && <Input
            id="current-password"
            name="current_password"
            label="Current Password"
            placeholder="Password"
            type="password"
            className={inputClassName}
            onChange={props.onChange}
            value={form.current_password}
            errors={errors.current_password}
        />}
        <Input
            id="password"
            name="password"
            label="New Password"
            placeholder="Password"
            type="password"
            className={inputClassName}
            onChange={props.onChange}
            value={form.password}
            errors={errors.password}
        />
        <Input
            id="password-confirmation"
            name="password_confirmation"
            label="Confirm New Password"
            placeholder="Password"
            type="password"
            className={inputClassName}
            onChange={props.onChange}
            value={form.password_confirmation}
            errors={errors.password_confirmation}
        />
    </>
