import { Company } from './Company'
import { Device } from './Device'
import { DeviceCategory } from './DeviceCategory'
import { GeneracSystem } from './GeneracSystem'
import { Homeowner } from './Homeowner'
import { InverterManufacturer } from './InverterManufacturer'
import { Model } from './Model'
import { ConsumptionType, ProductionType } from './MonitoringData'
import { SystemStatus } from './SystemStatus'
import { DeviceTypeEnum, InverterManufacturerEnum } from '@/enums'
import { api, Options, Params } from '@/services'
import {
    DevicesDataType,
    IdType,
    ProductionConsumptionDataType,
    SystemDataType,
    SystemInventoryDataType, SystemOtherDataType
} from '@/types'

export class System extends Model {
    static endpoint = 'api/v1/monitoring-index'

    raw: SystemDataType

    name?: string

    status: SystemStatus

    manufacturer: InverterManufacturer

    production?: ProductionType

    consumption?: ConsumptionType

    status_change_detected_at?: Date

    inventory?: SystemInventoryDataType

    other?: SystemOtherDataType

    customer_name?: string

    warranty_id?: IdType

    company?: Company

    city?: string

    state?: string

    system_size: number

    battery_size_kw: number

    num_panels: number

    pto_date?: Date

    last_updated_at?: Date

    registration_date?: Date

    installation_date?: Date

    battery_coverage_end_date?: Date

    system_id?: IdType

    inverter_model?: string

    panel_model?: string

    battery_make?: string

    plan_number?: string

    generacSystems?: GeneracSystem[]

    constructor(data: SystemDataType) {
        super(data)
        this.raw = data
        this.name = data.name
        this.status = SystemStatus.find(data.status) as SystemStatus
        this.status.system = this
        this.manufacturer = InverterManufacturer.find(data.source || data.manufacturer as InverterManufacturerEnum)
        this.production = data.production ? this.parseProductionOrConsumption(data.production) : undefined
        this.consumption = data.consumption ? this.parseProductionOrConsumption(data.consumption) : undefined
        this.status_change_detected_at = Model.toDate(data.status_change_detected_at)
        this.inventory = data.inventory
        this.other = data.other

        this.customer_name = data.customer_name
        this.warranty_id = data.warranty_id
        this.company = data.company ? new Company(data.company) : undefined
        this.city = data.city
        this.state = data.state
        this.system_size = Model.toNumber(data.system_size, 0) as number
        this.battery_size_kw = Model.toNumber(data.battery_size_kw, 0) as number
        this.pto_date = Model.toDate(data.pto_date)
        this.last_updated_at = Model.toDate(data.last_updated_at)
        this.registration_date = Model.toDate(data.registration_date)
        this.installation_date = Model.toDate(data.installation_date)
        this.battery_coverage_end_date = Model.toDate(data.battery_coverage_end_date)
        this.system_id = data.system_id
        this.inverter_model = data.inverter_model
        this.panel_model = data.panel_model
        this.battery_make = data.battery_make
        this.plan_number = data.plan_number
        this.num_panels = Model.toNumber(data.num_panels, 0) as number

        if (this.manufacturer.isGenerac) {
            this.generacSystems = data.summary?.systems?.map((item, index: number) =>
                new GeneracSystem({
                    ...item,
                    system: this,
                    devices: this.inventory?.systems?.[index].devices as DevicesDataType
                })) || []
        }
    }

    get homeowner() {
        return this.raw.homeowner ? new Homeowner(this.raw.homeowner) : undefined
    }

    get manufacturerUrl() {
        return this.manufacturer.getUrl(this.homeowner?.system_id)
    }

    get deviceCategories(): DeviceCategory[] {
        if (this.manufacturer.isGenerac) {
            return this.generacSystems?.reduce((acc: DeviceCategory[], item) => {
                item.deviceCategories.forEach(item => {
                    const index = acc.findIndex(({ type }) => type === item.type)
                    if (index === -1) {
                        acc.push(item)
                    } else {
                        acc[index].devices = [...acc[index].devices, ...item.devices]
                    }
                })
                return acc
            }, []) || []
        }
        const devices = this.inventory?.devices || {}
        return Object.keys(devices).map(key => new DeviceCategory({
            type: key as DeviceTypeEnum,
            provider: this.manufacturer.key,
            devices: devices[key as DeviceTypeEnum] || [],
            system: this
        }))
    }

    get allDevices() {
        return this.deviceCategories.reduce((acc: Device[], item) => [...acc, ...item.devices], [])
    }

    get normalDevices() {
        return this.allDevices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.allDevices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.allDevices.filter(item => item.isErrored)
    }

    parseProductionOrConsumption(data: ProductionConsumptionDataType) {
        return {
            end_date: Model.toDate(data?.end_date),
            today: {
                max: Model.toNumber(data?.today?.max, 0) as number,
                sum: Model.toNumber(data?.today?.sum, 0) as number
            },
            yesterday: {
                max: Model.toNumber(data?.yesterday?.max, 0) as number,
                sum: Model.toNumber(data?.yesterday?.sum, 0) as number
            },
            past_seven_days: {
                max: Model.toNumber(data?.past_seven_days?.max, 0) as number,
                sum: Model.toNumber(data?.past_seven_days?.sum, 0) as number
            },
            month_to_date: {
                max: Model.toNumber(data?.month_to_date?.max, 0) as number,
                sum: Model.toNumber(data?.month_to_date?.sum, 0) as number
            },
            last_month: {
                max: Model.toNumber(data?.last_month?.max, 0) as number,
                sum: Model.toNumber(data?.last_month?.sum, 0) as number
            },
            year_to_date: {
                max: Model.toNumber(data?.year_to_date?.max, 0) as number,
                sum: Model.toNumber(data?.year_to_date?.sum, 0) as number
            },
            life_time: {
                max: Model.toNumber(data?.life_time?.max, 0) as number,
                sum: Model.toNumber(data?.life_time?.sum, 0) as number
            },
            values: data?.values?.map
                ? data?.values?.map(item => Model.toNumber(item, 0) as number)
                : []
        }
    }

    static export(params: Params) {
        return api.monitoring.export({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            company_id: params.company_id,
            format: params.format,
            manufacturers: params.manufacturers,
            search: params.search,
            statuses: params.statuses,
            type: params.type
        })
    }

    static summary(params: Params = {}, options: Options = {}) {
        return api.monitoring.summary(params, options)
    }
}
