import { useState } from 'react'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

import MonitoringDonutReport from './MonitoringDonutReport'
import { Button, Card, IconChevronUp, ManufacturersGraphReport, StatusGuidePopup, Tooltip } from '@/components'
import { useQuery } from '@/hooks'
import { InverterManufacturer } from '@/models'
import { MonitoringSummaryDataType } from '@/types'

type MonitoringReportProps = {
    summary: MonitoringSummaryDataType
}

const MonitoringReport = ({ summary }: MonitoringReportProps) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const location = useLocation()
    const query = useQuery()

    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    const getMonitoringIndexPage = (filters: any) => {
        if (location.pathname === '/monitoring') {
            return `/monitoring?${createSearchParams({ ...query, ...filters })}`
        }
        return `/monitoring?${createSearchParams(filters)}`
    }

    return <div className="flex flex-wrap lg:flex-nowrap gap-6 mb-4">
        <Card className="flex flex-col justify-between items-start min-w-full lg:min-w-[25rem]">
            <h3 className="text-gray-500 text-sm">
                Top Inverter Manufacturer{isExpanded ? 's' : ''}
            </h3>

            <ManufacturersGraphReport
                summary={summary}
                isExpanded={isExpanded}
            />

            <Button design="btn-link" onClick={handleExpandToggleClick} className="mt-2 gap-2">
                <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>
                See {isExpanded ? 'Less' : 'More'}
            </Button>
        </Card>

        <Card className="grow flex flex-col gap-4 justify-between items-start">
            <h3
                data-test="monitoring-index-issues-title"
                className="text-gray-500 text-sm flex gap-4 justify-between w-full"
            >
                Site Status Alert Prioritization

                <StatusGuidePopup compact={!isExpanded}/>
            </h3>

            <MonitoringDonutReport
                summary={summary}
                isExpanded={isExpanded}
            />

            <div className="flex justify-between w-full">
                <Button design="btn-link" onClick={handleExpandToggleClick} className="gap-2">
                    <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>
                    See {isExpanded ? 'Less' : 'More'}
                </Button>

                {isExpanded && <Tooltip content="Currently Supported Integrations">
                    <div className="flex">
                        {!!summary.manufacturers[InverterManufacturer.enphase.key] && <Link
                            to={getMonitoringIndexPage({ manufacturers: [InverterManufacturer.enphase.key] })}
                            className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-3 z-[1] hover:z-10"
                        >
                            {InverterManufacturer.enphase.getLogo()}
                        </Link>}
                        {!!summary.manufacturers[InverterManufacturer.enphase.key] && <Link
                            to={getMonitoringIndexPage({ manufacturers: [InverterManufacturer.solaredge.key] })}
                            className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-1.5 z-[2] hover:z-10"
                        >
                            {InverterManufacturer.solaredge.getLogo()}
                        </Link>}
                        {!!summary.manufacturers[InverterManufacturer.enphase.key] && <Link
                            to={getMonitoringIndexPage({ manufacturers: [InverterManufacturer.generac.key] })}
                            className="p-1.5 rounded-full border border-gray-300 bg-white z-[3] hover:z-10"
                        >
                            {InverterManufacturer.generac.getLogo()}
                        </Link>}
                    </div>
                </Tooltip>}
            </div>
        </Card>
    </div>
}

export default MonitoringReport
