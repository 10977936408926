import { ReactNode, useState } from 'react'

import { Button, Popup, IconXCircle, IconX, IconFlipBackward, Form, ButtonSize } from '@/components'
import { useAuth } from '@/hooks'
import { CompanyAccountActivityStatus, Warranty } from '@/models'
import { CancelRequestType } from '@/types'

type WarrantyReviewCancelOrReinstateRequestButtonProps = {
    warranty: Warranty
    className?: string
    size?: ButtonSize
    children?: ReactNode

    onChange: () => void
}

const wordings = {
    cancel: {
        noun: 'cancellation',
        Icon: IconXCircle,
        ButtonIcon: IconX
    },
    reinstate: {
        noun: 'reinstatement',
        Icon: IconFlipBackward,
        ButtonIcon: IconFlipBackward
    }
}

export const WarrantyReviewCancelOrReinstateRequestButton = ({
    warranty,
    className,
    size,
    children,
    ...props
}: WarrantyReviewCancelOrReinstateRequestButtonProps) => {
    const auth = useAuth()
    const [isOpen, setIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const {
        noun,
        Icon,
        ButtonIcon
    } = warranty.status.isCancellationRequested ? wordings.cancel : wordings.reinstate

    const request = warranty.pending_cancellation_requests[0] as CancelRequestType

    const handleSubmit = async () => {
        setProcessing(true)
        try {
            await Warranty.approveCancellationRequest(request.id)
            setIsOpen(false)
            props.onChange()
        } finally {
            setProcessing(false)
        }
    }

    const handleDeny = async () => {
        setProcessing(true)
        try {
            await Warranty.denyCancellationRequest(request.id)
            setIsOpen(false)
            props.onChange()
        } finally {
            setProcessing(false)
        }
    }

    return <>
        {children ? <div
            role="button"
            tabIndex={-1}
            onClick={() => setIsOpen(true)}
            onKeyDown={() => setIsOpen(true)}
        >
            {children}
        </div> : <Button
            square
            hasError
            size={size}
            type="button"
            design="btn-secondary-gray"
            onClick={() => setIsOpen(true)}
            tooltip={auth.user?.company?.account_activity_status.isDisabled
                ? CompanyAccountActivityStatus.DISABLED_STATUS_MESSAGE
                : `Review ${noun.capitalize()} Request`}
            disabled={auth.user?.company?.account_activity_status.isDisabled}
            tooltipDisabled={false}
        >
            <ButtonIcon/>
        </Button>}
        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="w-200">
            <Form onSubmit={handleSubmit} className="flex flex-col lg:pr-7 text-gray-900" noValidate>
                <p className="flex items-center gap-2 text-gray-900 text-lg font-semibold capitalize mt-3">
                    <Icon className="stroke-error-600 w-6 h-6"/>
                    Warranty {noun} Request
                </p>
                <span className="text-gray-500 my-5">
                    <b className="text-gray-900">Plan ID:</b> {warranty.policy_num}
                </span>
                <p className="font-semibold capitalize">
                    Contractor&apos;s {noun} reason:
                </p>
                <p className="italic font-light">
                    &quot;{request.cancellation_reason}&quot;
                </p>
                <span className="text-gray-500 font-light mt-6">
                    Do you approve the {noun} of this warranty?
                </span>
                <div className="flex gap-2 justify-center mt-8">
                    <Button
                        hasError
                        design="btn-secondary-gray"
                        processing={processing}
                        onClick={handleDeny}
                        type="button"
                    >
                        No, Deny {noun.capitalize()}
                    </Button>
                    <Button
                        processing={processing}
                    >
                        Yes, approve {noun.capitalize()}
                    </Button>
                </div>
            </Form>
        </Popup>
    </>
}
