import { useEffect, useMemo, useState } from 'react'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateInvoice.svg'
import {
    Button,
    DatePicker,
    IconDownload,
    IconMail,
    Tooltip,
    SearchableSelect,
    InvoiceSendPopup,
    InvoiceHistoryInformationPopup,
    Image,
    PageHeader,
    FormChangeEvent,
    DateRange
} from '@/components'
import { AuthLayout, FiltersType, GlobalIndex, GlobalIndexChangeEvent, GlobalIndexDataType } from '@/containers'
import { useAuth, useNavigateWithQuery, useQuery, useAnalytics } from '@/hooks'
import { Company, Invoice } from '@/models'

interface InvoiceIndexData extends GlobalIndexDataType {
    rows: Invoice[]
}

interface InvoiceIndexFilters extends FiltersType {
    status: string
    company_id: string
    start_date: Date
    end_date: Date
}

const InvoicesIndex = () => {
    const { trackEvent } = useAnalytics()
    const navigateWithQuery = useNavigateWithQuery()
    const auth = useAuth()
    const query = useQuery()
    const [showPopup, setShowPopup] = useState(false)
    const getDefaultFilters = () => ({
        company_id: query.company_id || '',
        status: query.status || '',
        // Adding ' 00:00' to the end of string date to clear timezone
        start_date: query.start_date ? new Date(`${query.start_date} 00:00`) : new Date().startOf('month'),
        end_date: query.end_date ? new Date(`${query.end_date} 00:00`) : new Date().endOf('month')
    })

    const [companies, setCompanies] = useState<Company[]>([])
    const [selectedRow, setSelectedRow] = useState<Invoice | null>(null)
    const [filters, setFilters] = useState<InvoiceIndexFilters>(getDefaultFilters())
    const [data, setData] = useState<InvoiceIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        selectedRows: []
    })

    const handleChange = (e: GlobalIndexChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const handleFiltersChange = (e: FormChangeEvent) => {
        if (e.target.name === 'range') {
            const value = e.target.value as DateRange
            navigateWithQuery({ page: null, start_date: value.start?.toISODate(), end_date: value.end?.toISODate() })
            setFilters({ ...filters, start_date: value.start as Date, end_date: value.end as Date })
        } else {
            setFilters({ ...filters, [e.target.name]: e.target.value as string })
            navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        }
    }

    const fetchCompanies = async () => {
        if (auth.user?.isAdminOrStaffOrAdvisor) {
            const data = await Company.autocomplete()
            setCompanies(data)
        }
    }

    const handleDownloadClick = (selectedRows: any) => {
        Invoice.downloadMany(selectedRows)
        trackEvent('click_download_invoices', 'User Interaction', 'Download Invoices')
    }

    const handleDownloadHover = () => {
        trackEvent('hover_download_invoices', 'User Interaction', 'Download Invoices Hover')
    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        row_id: item.row_id,
        company_name: item.row_name,
        date: item.date,
        date_raw: new Date(`1 ${item.date}`),
        total_warranties: item.total_warranties.format(),
        total_warranties_raw: item.total_warranties,
        num_panels: item.panels_number.format(),
        num_panels_raw: item.panels_number,
        total_sales_raw: item.panels_number,
        total_sales: item.sales.money(),
        _path: `/invoices/${item.id}`,
        _className: item.row_id === auth.user?.company.id && auth.user?.isAffiliate ? 'text-error-500' : '',
        actions: <div className="flex items-center gap-2">
            <Tooltip content="Download">
                <Button onClick={() => item.download()} design="btn-link">
                    <IconDownload className="stroke-gray-300 hover:stroke-primary-600"/>
                </Button>
            </Tooltip>
            <Tooltip content="Send">
                <Button onClick={() => setSelectedRow(item)} design="btn-link">
                    <IconMail className="stroke-gray-300 hover:stroke-gray-600"/>
                </Button>
            </Tooltip>
        </div>
    })), [data.rows])

    return <AuthLayout
        heading={
            <PageHeader
                title={
                    <div className="flex flex-col md:flex-row md:items-center md:gap-8" data-test="invoice-history-title">
                        Invoice Summary
                        <InvoiceHistoryInformationPopup onClose={() => setShowPopup(false)} isOpen={showPopup}>
                            <Button design="btn-link" onClick={() => setShowPopup(true)} data-test="invoice-history-learn-more-button">
                                <span className="text-sm text-primary-800 font-normal">
                                    Learn More
                                </span>
                            </Button>
                        </InvoiceHistoryInformationPopup>
                    </div>
                }
            />
        }>

        <GlobalIndex
            id="invoices"
            dataType="Invoices"
            api={(params, ...args) =>
                Invoice.index({ activity_tracker: 'invoice.index', ...params }, ...args)}
            data={data}
            onChange={handleChange}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            onFiltersChange={filters => setFilters(filters)}
            selectable
            columns={[
                { field: 'company_name', title: 'Company Name', showIf: !auth.user?.isContractor, className: 'w-1/5' },
                { field: 'date', title: 'Month/Year', className: 'w-1/5' },
                { field: 'total_warranties', title: 'Total Warranties', className: 'w-1/5' },
                { field: 'num_panels', title: 'Number of Panels', className: 'w-1/5 min-w-[7rem]' },
                { field: 'total_sales', title: 'Total Sales', className: 'w-1/5' },
                { field: 'actions', title: '', clickable: false, sortable: false }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <DatePicker
                    id="date-range"
                    name="range"
                    placeholder="Date range"
                    value={{
                        start: filters.start_date,
                        end: filters.end_date
                    }}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                />
                {!auth.user?.isContractor &&
                    <SearchableSelect
                        id="company-id"
                        name="company_id"
                        placeholder="Company"
                        onChange={handleFiltersChange}
                        value={filters.company_id}
                        options={companies.map(item => ({ value: item.id, title: item.name }))}
                    />}
            </>}
            rightSide={<>
                <Button
                    square
                    onClick={() => handleDownloadClick(data.selectedRows)}
                    onMouseOver={handleDownloadHover}
                    disabled={!data.selectedRows?.length}
                    design="btn-secondary-gray"
                    size="btn-lg"
                >
                    <IconDownload/>
                </Button>
            </>}
            placeholder={data.meta.overall_count === 0 && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Nothing to Invoice, Nothing to Worry!</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        There are currently no invoices to display. You{"'"}re all set! We{"'"}ll start populating this table once you{"'"}ve been billed. In the meantime, feel free to explore our other features and let us know if you have any questions.
                    </p>
                </div>
            </>}
        />

        {selectedRow && <InvoiceSendPopup
            isOpen={!!selectedRow}
            invoice={selectedRow}
            onClose={() => setSelectedRow(null)}
        />}
    </AuthLayout>
}

export default InvoicesIndex
