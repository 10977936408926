import { api, Options, Params } from '@/services'
import { MonitoringSummaryDataType } from '@/types'

export default {
    summary(params: Params, options: Options): Promise<{ data: MonitoringSummaryDataType }> {
        return api.http.get('api/v1/monitoring/summary', params, options)
    },
    export(params: Params) {
        return api.http.get('api/v1/monitoring/export', params)
    }
}
