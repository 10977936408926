import { ReactNode } from 'react'

import { NOTIFICATION_TYPES, NotificationTypeEnum } from '@/enums'
import { NotificationMessageDataType, NotificationTypeDataType } from '@/types'

export class NotificationType {
    key: NotificationTypeEnum

    message: (data: NotificationMessageDataType) => ReactNode

    icon: ReactNode | ((data: NotificationMessageDataType) => ReactNode)

    showTimestamp: boolean

    onComplete?: (data: NotificationMessageDataType) => void

    constructor(data: NotificationTypeDataType) {
        this.key = data.key
        this.icon = data.icon
        this.message = data.message
        this.onComplete = data.onComplete
        this.showTimestamp = data.showTimestamp !== false
    }

    static get all() {
        return NOTIFICATION_TYPES.map(item => new NotificationType(item))
    }

    static find(key: NotificationTypeEnum) {
        return NotificationType.all.find(item => item.key === key) ||
            NotificationType.defaultType
    }

    static defaultType = NotificationType.all.find(item =>
        item.key === NotificationTypeEnum.DEFAULT) as NotificationType
}
