import { Battery } from './Battery'
import { Company } from './Company'
import { Field } from './Field'
import { Homeowner } from './Homeowner'
import { InverterManufacturer } from './InverterManufacturer'
import { Model } from './Model'
import { Product } from './Product'
import { WarrantyHistory } from './WarrantyHistory'
import { WarrantyProductType } from './WarrantyProductType'
import { WarrantyStatus } from './WarrantyStatus'
import { InverterManufacturerEnum, WarrantyStatusEnum } from '@/enums'
import SystemDetails from '@/models/SystemDetails'
import { api, Params, Payload } from '@/services'
import {
    AddressDataType,
    CancelRequestType,
    UpdateRequestType,
    WarrantyDataType,
    IdType, FieldDataType, BatteryDataType
} from '@/types'

export class Warranty extends Model {
    static endpoint = 'api/v1/warranty'

    raw: WarrantyDataType

    unique_id?: string

    policy_num: string

    type: WarrantyProductType

    status: WarrantyStatus

    affiliate_id?: IdType

    refresh_allowed: boolean

    homeowner: Homeowner

    company?: Company

    affiliate?: Company

    panel?: Product

    inverter?: Product

    batteries: Battery[]

    histories: WarrantyHistory[]

    systemDetails?: SystemDetails

    all_duplicates: Warranty[]

    num_panels: number

    num_microinverters: number

    size_kw: number

    battery_size_kw: number

    panel_wattage: number

    price: number

    solar_price: number

    battery_price: number

    policy_rate: number

    battery_rate: number

    eligible_commission: number

    submission_date?: Date

    install_date: Date

    end_date: Date

    sent_date?: Date

    cancellation_requested_at?: Date

    pending_update_requests: UpdateRequestType[]

    pending_cancellation_requests: CancelRequestType[]

    install_address?: AddressDataType

    panel_model?: string

    inverter_model?: string

    customer_info?: {
        full_name: string
    }

    constructor(data: WarrantyDataType) {
        super(data)
        this.raw = data
        this.unique_id = data.unique_id
        this.policy_num = data.policy_num
        this.type = WarrantyProductType.find(data.type)
        this.status = WarrantyStatus.find(data.status)
        this.affiliate_id = data.affiliate_id
        this.pending_update_requests = data.pending_update_requests || []
        this.pending_cancellation_requests = data.pending_cancellation_requests || []
        this.refresh_allowed = !!data.refreshAllowed

        this.homeowner = new Homeowner({ ...data.homeowner, warranty: data })
        this.company = data.company ? new Company(data.company) : undefined
        this.affiliate = data.affiliate ? new Company(data.affiliate) : undefined
        this.panel = data.panel ? new Product(data.panel) : undefined
        this.inverter = data.inverter ? new Product(data.inverter) : undefined
        this.batteries = data.batteries?.map(item => new Battery(item)) || []
        this.histories = data.histories?.map(item => new WarrantyHistory(item)) || []
        this.systemDetails = data.systemDetails ? new SystemDetails(data.systemDetails) : undefined
        this.all_duplicates = data.all_duplicates?.map(item => new Warranty(item)) || []

        this.num_panels = Model.toNumber(data.num_panels, 0) as number
        this.num_microinverters = Model.toNumber(data.num_microinverters, 0) as number
        this.size_kw = Model.toNumber(data.size_kw, 0) as number
        this.battery_size_kw = Model.toNumber(data.battery_size_kw, 0) as number
        this.panel_wattage = Model.toNumber(data.panel_wattage, 0) as number
        this.price = Model.toNumber(data.price, 0) as number
        this.solar_price = Model.toNumber(data.solar_price, 0) as number
        this.battery_price = Model.toNumber(data.battery_price, 0) as number
        this.policy_rate = Model.toNumber(data.policy_rate, 0) as number
        this.battery_rate = Model.toNumber(data.battery_rate, 0) as number
        this.eligible_commission = Model.toNumber(data.eligible_commission, 0) as number

        this.submission_date = Model.toDate(data.submission_date) as Date
        this.install_date = Model.toDate(data.install_date) as Date
        this.end_date = Model.toDate(data.end_date) as Date
        this.sent_date = Model.toDate(data.sent_date)
        this.cancellation_requested_at = Model.toDate(data.cancellation_requested_at)

        this.customer_info = data.customer_info
        this.install_address = data.install_address
        this.panel_model = data.panel_model
        this.inverter_model = data.inverter_model
    }

    static _fields: FieldDataType[] = [
        { key: 'policy_num', title: 'Warranty Plan ID' },
        { key: 'affiliate_id', title: 'Affiliate ID' },
        { key: 'affiliate_name', title: 'Affiliate' },
        { key: 'company', title: 'Contractor' },
        { key: 'panel_name', title: 'Panel Model' },
        { key: 'inverter_name', title: 'Inverter Model' },
        { key: 'inverter_manufacturer', title: 'Inverter Manufacturer', format: (value: InverterManufacturerEnum) => InverterManufacturer.find(value).title },
        { key: 'num_panels', title: 'Number of Panels', format: 'number' },
        { key: 'size_kw', title: 'System Size (kW)', format: 'float' },
        { key: 'panel_wattage', title: 'Panel Wattage (W)', format: 'number' },
        { key: 'battery_size_kw', title: 'Battery Size (kW)', format: 'float' },
        { key: 'num_microinverters', title: 'Number of Microinverters/Optimizers ', format: 'number' },
        { key: 'install_date', title: 'Date of Installation', format: 'date' },
        { key: 'end_date', title: 'Warranty End Date', format: 'date' },
        { key: 'created_at', title: 'Submission Date', format: 'date' },
        { key: 'price', title: 'Total Price', format: 'money' },
        { key: 'solar_price', title: 'Solar Warranty Price', format: 'money' },
        { key: 'battery_price', title: 'Battery Warranty Price', format: 'money' },
        { key: 'status', title: 'Status', format: (value: WarrantyStatusEnum) => WarrantyStatus.find(value)?.title },
        { key: 'eligible_commission', title: 'Eligible Commission' },
        { key: 'battery_rate', title: 'Battery Rate', format: 'money' },
        { key: 'policy_rate', title: 'Policy Rate', format: 'money' },
        { key: 'full_name', title: 'Homeowner Name' },
        { key: 'first_name', title: 'First Name' },
        { key: 'last_name', title: 'Last Name' },
        { key: 'email', title: 'Homeowner Email' },
        { key: 'phone', title: 'Homeowner Phone', format: 'phone' },
        { key: 'system_id', title: 'Manufacturer System Identifier' },
        { key: 'address', title: 'Installation Address' },
        { key: 'street_address', title: 'Street Address Line 1' },
        { key: 'city', title: 'City' },
        { key: 'state', title: 'State' },
        { key: 'zip', title: 'Zip Code' },
        { key: 'sent_at', title: 'Sent At', format: 'datetime' },
        { key: 'cancellation_contact_email', title: 'Cancellation Contact Email' },
        { key: 'cancellation_reason', title: 'Cancellation Reason' },
        { key: 'cancellation_requested_at', title: 'Cancellation Requested at', format: 'datetime' },
        { key: 'edit_contact_email', title: 'Edit Contact Email' },
        { key: 'edit_reason', title: 'Edit Reason' },
        { key: 'edit_requested_at', title: 'Edit Requested at', format: 'datetime' },
        { key: 'num_batteries', title: 'Number of Batteries', format: (value: number) => value ? value.toString() : '0' },
        { key: 'battery_make', title: 'Battery: Make & Model' },
        { key: 'battery_size_kw', title: 'Battery: Capacity (kWh)' },
        {
            key: 'batteries',
            title: 'Batteries',
            format: (value: BatteryDataType[]) =>
                value?.map(item => `${item.product?.model_name}: ${parseInt(item.size_kw as string).format({ minimumFractionDigits: 2 })}kW`)
        }
    ]

    static get fields() {
        return Warranty._fields.map(item => new Field(item))
    }

    static get priceSensitiveFields() {
        return [
            'size_kw',
            'num_panels',
            'panel_wattage',
            'batteries',
            'battery_rate',
            'policy_rate'
        ].map(Warranty.getField)
    }

    static getField(key: string) {
        return Warranty.fields.find(item => item.key === key) || Field.defaultField
    }

    get isLocked() {
        return !this.created_at || +this.created_at < +new Date().subtract('day', 1)
    }

    /*
    * Static APIs
    * */
    static validateHomeowner(payload: Payload) {
        return api.warranties.validateHomeowner(payload)
    }

    static async getDuplicates(payload: Payload) {
        const res = await api.warranties.getDuplicates(payload)
        res.data = res.data.duplicates.map((item: WarrantyDataType) => new this(item))
        return res
    }

    static validateSystem(params: Payload) {
        return api.warranties.validateSystem(params as Params)
    }

    static send(ids: (string | number)[]) {
        return api.warranties.send(ids)
    }

    static upload(payload: Payload) {
        return api.warranties.upload(payload)
    }

    static validateUpload(payload: Payload) {
        return api.warranties.upload(payload, { 'validate-only': true })
    }

    static match(payload: Payload) {
        return api.warranties.match({ data: payload } as Payload)
    }

    static validateMatch(payload: Payload) {
        return api.warranties.match({ data: payload } as Payload, { validation_only: true })
    }

    static export(params: Params) {
        return api.warranties.export({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            format: params.format,
            state: params.state,
            type: params.type,
            statuses: params.statuses,
            warranties_by_contractors: params.warranties_by_contractors
        })
    }

    static revertCancellationRequest(id: string | number) {
        return api.warranties.cancelRequest(id)
    }

    static revertEditRequest(id: string | number) {
        return api.warranties.cancelEditRequest(id)
    }

    static downloadMany(params: {
        ids?: (string | number)[]
        select_all?: boolean
        start_date?: string
        end_date?: string
        company_id?: string | number
        search?: string
        state?: string
        type?: string
        statuses?: string[]
        warranties_by_contractors?: boolean
    }) {
        return api.warranties.downloadMany({
            ids: params.ids,
            select_all: params.select_all,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            state: params.state,
            type: params.type,
            statuses: params.statuses,
            warranties_by_contractors: params.warranties_by_contractors
        })
    }

    static approveCancellationRequest(id: string | number) {
        return api.warranties.approveCancellationRequest(id)
    }

    static denyCancellationRequest(id: string | number) {
        return api.warranties.denyCancellationRequest(id)
    }

    static updateRequest(id: string | number, action: 'approve' | 'deny') {
        return api.warranties.updateRequest(id, action)
    }

    static bulkCancel(params: Params) {
        return api.warranties.bulkCancel({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            reason: params.reason,
            contact_email: params.contact_email,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            state: params.state,
            type: params.type,
            statuses: params.statuses
        })
    }

    static downloadExampleCsv() {
        return api.warranties.downloadExampleCsv()
    }

    /*
    * Nonstatic APIs
    * */
    send(ids: (string | number)[] = [this.id]) {
        return Warranty.send(ids)
    }


    markUnique(reason: string) {
        return api.warranties.markUnique(this.id, reason)
    }


    monitoringUpdate() {
        return api.warranties.monitoringUpdate(this.id)
    }

    download() {
        return api.warranties.download(this.id)
    }

    submitCancellationRequest(payload: Payload) {
        return api.warranties.cancellationRequest({ warranty_id: this.id, ...payload })
    }

    stream() {
        return api.warranties.stream(this.id)
    }

    validate(payload: FormData) {
        return api.warranties.validate(this.id, payload)
    }

    getCalculatePrices(payload: Payload) {
        return api.warranties.getCalculatePrices(this.id, payload)
    }
}
