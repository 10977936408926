import { Link } from 'react-router-dom'

import {
    Button,
    Card,
    IconArrowRight,
    IconBellRinging01, IconBox,
    IconHelpCircle,
    IconMessageChatSquare,
    IconVideoRecorder, PageHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAuth } from '@/hooks'

const SupportIndex = () => {
    const auth = useAuth()
    const items = [{
        id: 1,
        title: 'Tutorial Videos',
        description: 'Take a tour to explore all of our features',
        path: '/support/videos',
        icon: <IconVideoRecorder size="lg" className="stroke-primary-700"/>
    }, {
        id: 2,
        title: 'What\'s New',
        description: 'Get to know more about recently added features',
        path: '/support/whats-new',
        icon: <IconBellRinging01 size="lg" className="stroke-primary-700"/>
    }, {
        id: 3,
        title: 'FAQ’s',
        description: 'Any doubt, find an answer here.',
        path: '/support/faq',
        icon: <IconHelpCircle size="lg" className="stroke-primary-700"/>
    }, {
        id: 4,
        title: 'Contact Support',
        description: 'Any Issue, we are here to help',
        path: '/support/contact',
        icon: <IconMessageChatSquare size="lg" className="stroke-primary-700"/>
    }, {
        id: 5,
        title: 'Resources',
        description: 'You can know more about us here',
        path: '/support/resources',
        icon: <IconBox size="lg" className="stroke-primary-700"/>
    }]

    return <AuthLayout heading={<PageHeader title="Support"/>}>
        <Card className="mt-16 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
            {items.map(item =>
                <Link to={item.path} key={item.id} className="shadow-md bg-gray-50 mt-6 flex flex-col justify-center items-center px-4 py-14 border border-gray-200 rounded-xl relative">
                    <div className="w-12 h-12 rounded-full bg-primary-50 flex justify-center items-center absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2">
                        <div className="w-9 h-9 rounded-full bg-primary-100 flex justify-center items-center">
                            {item.icon}
                        </div>
                    </div>
                    <h2 className="text-xl flex items-center leading-[30px] font-light">
                        {item.title}
                        {auth.user?.article_notifications
                            ?.some(notification => notification.article.category_id === item.id) &&
                                <div className="w-5 h-5 flex justify-center items-center rounded-full bg-error-50 ml-2">
                                    <div className="w-1.5 h-1.5 rounded-full bg-error-500"/>
                                </div>}
                    </h2>
                    <p className="text-gray-500 mt-2 font-light text-center">{item.description}</p>
                    <Button className="mt-5 leading-6 font-light" design="btn-link" size="btn-lg">
                        Learn more <IconArrowRight className="stroke-primary-700 ml-2"/>
                    </Button>
                </Link>)}
        </Card>
    </AuthLayout>
}

export default SupportIndex
