import { useMemo, useState } from 'react'

import {
    DatePicker,
    IconHelpCircle,
    Input,
    Select,
    Tooltip,
    Button,
    Popup,
    FormErrorsType,
    FormDataType, FormChangeEventHandler
} from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { InverterManufacturer } from '@/models'
import { IdType } from '@/types'

export interface InverterFormDataType extends FormDataType {
    inverter_name: string
    inverter_manufacturer: InverterManufacturerEnum | string
    num_microinverters: number | string
    install_date: Date
    submission_date?: Date
    system_id: IdType
}

type InverterFormProps = {
    form: InverterFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
    mode?: 'create' | 'edit'
}

export const InverterForm = ({ form, inputClassName, errors, ...props }: InverterFormProps) => {
    const [showSystemIdGuide, setShowSystemIdGuide] = useState(false)

    const manufacturers = useMemo(() => {
        const additional = []
        if (form.inverter_manufacturer === InverterManufacturerEnum.OTHER) {
            additional.push(InverterManufacturer.other)
        }
        return [...InverterManufacturer.allKnown.filter(item => !item.isAlternative), ...additional]
    }, [])

    const inverterManufacturer = useMemo(() =>
        InverterManufacturer.find(form.inverter_manufacturer as InverterManufacturerEnum), [form])

    const getNumMicroinvertersLabel = () => {
        if (inverterManufacturer.useOptimizers) return 'Number of Optimizers'
        if (inverterManufacturer.useMicroInverters) return 'Number of Microinverters'
        return 'Number of Microinverters/Optimizers'
    }

    return <>
        <Input
            id="inverter-name"
            name="inverter_name"
            label="Inverter Model*"
            placeholder="Inverter Model"
            className={inputClassName}
            onChange={props.onChange}
            value={form.inverter_name}
            errors={errors.inverter_name}
        />

        <div className="grid lg:grid-cols-2 gap-5">
            <Select
                id="inverter-manufacturer"
                name="inverter_manufacturer"
                label="Inverter Manufacturer*"
                options={[
                    ...props.mode === 'create' ? [{ value: '', title: 'Inverter Manufacturer' }] : [],
                    ...manufacturers.map(item => ({
                        value: item.key,
                        title: item.title
                    }))
                ]}
                className={inputClassName}
                onChange={props.onChange}
                value={form.inverter_manufacturer}
                errors={errors.inverter_manufacturer}
            />

            <Input
                id="num_microinverters"
                name="num_microinverters"
                label={getNumMicroinvertersLabel()}
                placeholder={getNumMicroinvertersLabel()}
                className={inputClassName}
                onChange={props.onChange}
                value={form.num_microinverters}
                errors={errors.num_microinverters}
                disabled={!form.inverter_manufacturer}
                mask={{
                    numeral: true,
                    numeralIntegerScale: 3,
                    numeralDecimalScale: 0,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
                postIcon={
                    <Tooltip content={<>
                        <p><strong>Number of Optimizers/Microinverters</strong> (optional)</p><br/>
                        <p>
                            For String Inverter systems (usually with SNA, Fronius, SolarEdge, or Generac Inverters)
                            Solar Insure likes to collect information on the number of optimizers.
                        </p>
                        <br/>
                        <p>
                            For Micro inverter systems (usually with Enphase, Hoymiles, Generac, NEP, or APS inverters)
                            Solar Insure likes to collect information on the number of microinverters present to
                            understand the panel to micro ratio.
                        </p>
                        <br/>
                        <p>
                            Collecting these values helps Solar Insure to more accurately calculate and monitor expected
                            production ranges for a particular solar system.
                        </p>
                    </>}>
                        <IconHelpCircle className="stroke-gray-500" size="sm"/>
                    </Tooltip>
                }
            />
        </div>

        <DatePicker
            id="install-date"
            name="install_date"
            label="Installation Date*"
            placeholder="Installation Date"
            className={inputClassName}
            onChange={props.onChange}
            value={form.install_date}
            errors={errors.install_date}
        />

        {typeof form.submission_date !== 'undefined' && <DatePicker
            id="submission-date"
            name="submission_date"
            label="Submission Date*"
            placeholder="Submission Date"
            className={inputClassName}
            onChange={props.onChange}
            value={form.submission_date}
            errors={errors.submission_date}
        />}


        <Input
            id="system_id"
            name="system_id"
            label="Manufacturer System Identifier"
            placeholder="Manufacturer System Identifier"
            className={inputClassName}
            onChange={props.onChange}
            value={form.system_id}
            errors={errors.system_id}
            trim
            hint={!!inverterManufacturer.systemIdGuide &&
                <Button design="btn-link" type="button" className="!text-primary-700" onClick={() => setShowSystemIdGuide(true)}>How to Find Manufacturer System ID</Button>}
            postIcon={
                <Tooltip content={<div className="flex flex-col gap-4 max-w-xs">
                    <p className="font-semibold">Manufacturer System ID</p>
                    <p>
                        It is a unique identifier assigned by your solar system
                        manufacturer. To find it, log into your manufacturer&apos;s
                        monitoring portal. They typically provide this ID as
                        part of your system details.
                    </p>
                    <p>This ID helps us connect your site to Solar Insure Monitoring.</p>
                </div>}>
                    <IconHelpCircle className="stroke-gray-500" size="sm"/>
                </Tooltip>
            }
        />

        {inverterManufacturer && <Popup open={showSystemIdGuide} onClose={() => setShowSystemIdGuide(false)}>
            <h3 className="flex gap-3 items-center text-xl font-semibold">
                {inverterManufacturer.getLogo()}
                How to Find System ID
            </h3>

            <div className="p-4 mt-2 text-sm text-gray-750">
                {inverterManufacturer.systemIdGuide}
            </div>
        </Popup>}
    </>
}
