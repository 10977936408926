import classnames from 'classnames'
import { useMemo } from 'react'
import { createSearchParams } from 'react-router-dom'

import { Button, DoughnutChart } from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { useFormData, useQuery } from '@/hooks'
import { InverterManufacturer } from '@/models'
import { MonitoringSummaryDataType } from '@/types'


type ManufacturersGraphReportProps = {
    summary: MonitoringSummaryDataType
    isExpanded: boolean
}

type DataType = {
    key: InverterManufacturerEnum | 'rest',
    title: string,
    value: number
    percentage: number
}

export const ManufacturersGraphReport = ({ summary, isExpanded }: ManufacturersGraphReportProps) => {
    const query = useQuery()
    const { convert } = useFormData()
    const getMonitoringIndexPage = (filters: any) => {
        const params = convert(location.pathname === '/monitoring' ? { ...query, ...filters } : filters, {}) as {
            [key: string]: string
        }
        return `/monitoring?${createSearchParams(params)}`
    }

    const manufacturersList = useMemo(() =>
        InverterManufacturer.all.reduce((acc: DataType[], item: InverterManufacturer) =>
            summary.manufacturers[item.key] ? [...acc, {
                key: item.key,
                value: summary.manufacturers[item.key] as number,
                title: item.title,
                percentage: 0
            }] : acc, [] as DataType[])
            .sort((a, b) => b.value - a.value)
    , [summary.manufacturers])

    const sum = useMemo(() =>
        manufacturersList.reduce((acc, item) => acc + item.value, 0), [summary.manufacturers])

    const data = useMemo<DataType[]>(() =>
        manufacturersList.reduce((acc: DataType[], item: DataType, index) => {
            const { key } = item
            const value = summary.manufacturers[key as InverterManufacturerEnum] as number
            if (index < 5) {
                return [...acc, {
                    value,
                    key,
                    title: item.title,
                    percentage: Math.round(value * 100 / sum)
                }]
            }
            if (acc[5]) {
                acc[5].value = acc[5].value + value
                acc[5].percentage = Math.round(acc[5].value * 100 / sum)
                return acc
            }
            return [...acc, {
                value,
                key: 'rest',
                title: 'Various Others',
                percentage: Math.round(value * 100 / sum)
            }]
        }, [] as DataType[]), [summary.manufacturers])

    const colors = [
        '#2eb2d0', // primary-400
        '#ffb953', // orange-300
        '#98d3b8', // success-300
        '#c0b7da', // purple-300
        '#ffe200', // yellow-300
        '#ffb4a5' // error-300
    ]

    return isExpanded
        ? <div
            className={classnames('flex gap-8 justify-center 2xl:justify-between w-full items-center md:items-end flex-wrap mt-4', { 'flex-row-reverse': data.length < 3 })}
        >
            <div className="w-53.5 !h-27 relative">
                <DoughnutChart
                    datasets={[{
                        data: data.map(item => item.value),
                        backgroundColor: colors,
                        hoverBackgroundColor: [
                            '#8bd1e6', // primary-300
                            '#ffd8a2', // orange-200
                            '#c5e6d6', // success-200
                            '#e0dbed', // purple-200
                            '#fff37c', // yellow-200
                            '#ffd5cb' // error-200
                        ],
                        borderColor: 'transparent',
                        borderWidth: 0,
                        cutout: '70%',
                        circumference: 180,
                        rotation: -90
                    }]}
                    options={{
                        aspectRatio: 2,
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }
                    }}
                    customTooltip={index => data[index] && <div className="p-3 font-body bg-white shadow-md rounded-md text-xs text-gray-500">
                        <div className="hidden md:block absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-white rotate-45 origin-center"/>
                        <div className="whitespace-nowrap flex items-baseline gap-1">
                            <Button
                                href={getMonitoringIndexPage({
                                    manufacturers: data[index].key === 'rest'
                                        ? manufacturersList.slice(5).map(item => item.key)
                                        : [data[index].key]
                                })}
                                design="btn-link"
                            >
                                {data[index].title}
                            </Button> – {data[index].percentage.format()}%
                        </div>
                        <div>
                            {data[index].value.format()} Sites
                        </div>
                    </div>}
                />
                <div className="absolute left-1/2 bottom-0 -translate-x-1/2 text-center">
                    <div className="font-semibold text-xl">{sum.format()}</div>
                    <div className="text-gray-500 text-2xs">Total sites</div>
                </div>
            </div>
            <div className={classnames('flex flex-col gap-2 text-sm text-gray-500 grow h-full', { 'justify-center': data.length < 4 })}>
                {data.map((item, index) => <Button
                    href={getMonitoringIndexPage({
                        manufacturers: data[index].key === 'rest'
                            ? manufacturersList.slice(5).map(item => item.key)
                            : [data[index].key]
                    })}
                    design="btn-link"
                    className="flex gap-2 items-center !text-gray-500"
                    key={item.key}
                >
                    <div className="w-3 h-3 rounded-sm shrink-0" style={{ backgroundColor: colors[index] }}/>
                    <div className="flex gap-4 items-center justify-between grow">
                        <div className="flex gap-2 items-center">
                            {InverterManufacturer.find(item.key as InverterManufacturerEnum).getLogo('sm')}
                            {item.title}
                        </div>
                        <span className="font-semibold">
                            {item.value.format()}
                        </span>
                    </div>
                </Button>)}
            </div>
        </div>
        : <div className="flex flex-col mt-2.5">
            {data[0] && <div className="flex gap-2">
                {InverterManufacturer.find(data[0].key as InverterManufacturerEnum).getLogo('lg')}
                <div className="flex gap-1 flex-col">
                    <span className="text-lg">
                        <span className="font-semibold">{data[0].title}</span> {data[0].percentage} %
                    </span>

                    <span className="text-gray-500 text-sm">
                        {data[0].value.format()} Sites
                    </span>
                </div>
            </div>}
        </div>
}
