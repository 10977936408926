import { CompanyTypeDataType } from '@/types'

export enum CompanyTypeEnum {
    CONTRACTOR = 'contractor',
    LENDER_AFFILIATE = 'lender_affiliate',
    SOFTWARE_AFFILIATE = 'software_affiliate'
}

export const COMPANY_TYPES: CompanyTypeDataType[] = [
    {
        key: CompanyTypeEnum.CONTRACTOR,
        title: 'Contractor'
    },
    {
        key: CompanyTypeEnum.LENDER_AFFILIATE,
        title: 'Lender Affiliate'
    },
    {
        key: CompanyTypeEnum.SOFTWARE_AFFILIATE,
        title: 'Software Affiliate'
    }
]
