import { ReactNode } from 'react'

import { DeviceType } from './DeviceCategory'
import { IconDotsHorizontal, Image, Logo } from '@/components'
import { INVERTER_MANUFACTURERS, InverterManufacturerEnum } from '@/enums'
import { IdType, InverterManufacturerDataType } from '@/types'

export const logoSizes = {
    sm: 'w-4',
    md: 'w-5',
    lg: 'w-6',
    xl: 'w-7',
    '2xl': 'w-8'
}

export class InverterManufacturer {
    key: InverterManufacturerEnum

    logo?: InverterManufacturerEnum

    title: string

    useOptimizers: boolean

    useMicroInverters: boolean

    isSupported: boolean

    isUnsupported: boolean

    isUnknown: boolean

    isAlternative: boolean

    deviceTypes: DeviceType[]

    systemIdGuide?: ReactNode

    constructor(data: InverterManufacturerDataType) {
        this.key = Object.values(InverterManufacturerEnum).includes(data.key)
            ? data.key : InverterManufacturerEnum.OTHER
        this.logo = data.logo
        this.title = data.title || ''
        this.useOptimizers = !!data.useOptimizers
        this.useMicroInverters = !!data.useMicroInverters
        this.isSupported = !!data.isSupported
        this.isUnsupported = !!data.isUnsupported
        this.isUnknown = !!data.isUnknown
        this.isAlternative = !!data.isAlternative
        this.deviceTypes = data.deviceTypes || []
        this.systemIdGuide = data.systemIdGuide
    }

    getLogo(size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' = 'md') {
        if (this.key === InverterManufacturerEnum.OTHER) {
            return <IconDotsHorizontal className="stroke-black"/>
        }
        return this.logo
            ? <Logo type={this.logo} className={logoSizes[size]}/>
            : <Image className="w-6 rounded-full" src={`https://ui-avatars.com/api/?name=${this.title.replace(/ /g, '')}&color=7F9CF5&background=EBF4FF`}/>
    }

    get isEnphase() {
        return this.key === InverterManufacturerEnum.ENPHASE
    }

    get isSolaredge() {
        return this.key === InverterManufacturerEnum.SOLAREDGE
    }

    get isGenerac() {
        return this.key === InverterManufacturerEnum.GENERAC
    }

    static find(key: InverterManufacturerEnum): InverterManufacturer {
        const manufacturer = INVERTER_MANUFACTURERS.find(item => item.key === key)
        return manufacturer ? new InverterManufacturer(manufacturer) : InverterManufacturer.other
    }

    static get enphase() {
        return InverterManufacturer.find(InverterManufacturerEnum.ENPHASE)
    }

    static get solaredge() {
        return InverterManufacturer.find(InverterManufacturerEnum.SOLAREDGE)
    }

    static get generac() {
        return InverterManufacturer.find(InverterManufacturerEnum.GENERAC)
    }

    static get other() {
        return InverterManufacturer.find(InverterManufacturerEnum.OTHER)
    }

    static get all() {
        return INVERTER_MANUFACTURERS.map(item => new InverterManufacturer(item))
    }

    static get allKnown() {
        return InverterManufacturer.all.filter(item => !item.isUnknown)
    }

    static get allUnknown() {
        return InverterManufacturer.all.filter(item => item.isUnknown)
    }

    static get allSupported() {
        return InverterManufacturer.all.filter(item => item.isSupported)
    }

    static get allUnsupported() {
        return InverterManufacturer.all.filter(item => item.isUnsupported)
    }

    getEnphaseUrl(id?: IdType) {
        return `https://enlighten.enphaseenergy.com/${id ? `systems/${id}` : ''}`
    }

    getSolarEdgeUrl(id?: IdType) {
        return `https://monitoring.solaredge.com/${id ? `solaredge-web/p/site/${id}` : ''}`
    }

    getGeneracUrl(id?: IdType) {
        return `https://pwrfleet.generac.com/${id ? `sites/${id}` : ''}`
    }

    getUrl(id?: IdType) {
        if (this.isEnphase) {
            return this.getEnphaseUrl(id)
        } else if (this.isSolaredge) {
            return this.getSolarEdgeUrl(id)
        } else if (this.isGenerac) {
            return this.getGeneracUrl(id)
        }
        return undefined
    }
}
