import { useEffect } from 'react'


import {
    Dropdown,
    Tooltip,
    IconEdit,
    Checkbox,
    Button, FormChangeEvent
} from '@/components'
import { ColumnProps } from '@/components/table/Thead'
import { useAnalytics } from '@/hooks'
import { localStorage } from '@/services'

type TableEditColumnsProps = {
    name: string
    columns: ColumnProps[]
    value: string[]
    maxAmount?: number
    minAmount?: number
    onChange: any
    'data-test'?: string
}

type ColumnPreferences = { [key: string]: string[] }

const TableEditColumns = ({ maxAmount = 6, minAmount = 1, columns, value, 'data-test': dataTest, ...props }: TableEditColumnsProps) => {
    const { trackEvent } = useAnalytics()

    const handleChange = (e: FormChangeEvent) => {
        const index = value.indexOf(e.target.name)
        let selectedFields = value
        if (index === -1) {
            if (e.target.checked && value.length < maxAmount) selectedFields =
                [...value as string[], e.target.name]
        } else {
            if (!e.target.checked && value.length > minAmount) selectedFields =
                value.filter(item => item !== e.target.name)
        }
        const columnPreferences = localStorage.get('column_preferences')
        localStorage.set('column_preferences', {
            ...columnPreferences as ColumnPreferences,
            [props.name]: selectedFields
        })
        props.onChange(selectedFields)
    }

    useEffect(() => {
        const columnPreferences = localStorage.get('column_preferences') as ColumnPreferences
        if (!value.length) {
            const savedValue = columnPreferences[props.name]
                ?.filter((item: string) => columns.some(({ field }) => field === item))
            props.onChange(
                savedValue || columns
                    .filter(({ showIf = true }) => showIf)
                    .map(({ field }) => field)
                    .slice(0, maxAmount)
            )
        }
    }, [])

    return <Dropdown withChevron persistent button={<Tooltip content="Edit Columns" data-id={dataTest}>
        <Button
            design="btn-link-gray"
            size="btn-lg"
            className="w-20"
            onMouseOver={() => trackEvent('hover_customize_columns_warranty', 'User Interaction', 'Customize Columns Hover')}
            onClick={() => trackEvent('click_customize_columns_warranty', 'User Interaction', 'Customize Columns Click')}
            data-id={`${dataTest}-button`}
        >
            <div className="w-full px-4">
                <IconEdit/>
            </div>
        </Button>
    </Tooltip>}>
        <div className="-m-2">
            <p className="px-3.5 py-2.5 text-gray-900 font-body" data-id={`${dataTest}-title`}>Select up to {maxAmount}.</p>
            <div className="w-64">
                {columns.map(({ showIf = true, ...column }) => {
                    if (!showIf) return null
                    const isActive = value.includes(column.field)
                    return <div data-id={`${dataTest}-item-${column.field}`} className={`w-full px-3.5 py-2.5 ${isActive ? 'bg-gray-50' : 'bg-white'}`} key={column.field}>
                        <Checkbox
                            id={column.field}
                            label={column.title_raw || column.title}
                            name={column.field}
                            className="text-gray-900"
                            checked={isActive}
                            onChange={handleChange}
                            data-id={`${dataTest}-item-${column.field}-checkbox`}
                        />
                    </div>
                })}
            </div>
        </div>
    </Dropdown>
}

export default TableEditColumns
