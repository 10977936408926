import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Form, FormChangeEvent, FormDataType, FormErrorsType, Input } from '@/components'
import { LoginWrapper } from '@/containers'
import { useToastNotifications, useQuery } from '@/hooks'
import { AuthUser } from '@/models'
import { isApiError } from '@/services'

interface ResetPasswordFormDataType extends FormDataType {
    password: string
    password_confirmation: string
    email: string
    token: string
}

const ResetPassword = () => {
    const query = useQuery()
    const params = useParams()
    const navigate = useNavigate()
    const { success, error } = useToastNotifications()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<ResetPasswordFormDataType>({
        password: '',
        password_confirmation: '',
        email: query.email,
        token: query.token || params.token
    })

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        setErrors({})
        setProcessing(true)
        try {
            await AuthUser.resetPassword(form)
            success('Password successfully updated.')
            navigate('/login')
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <LoginWrapper>
        <div className="w-80 h-auto">
            <h1 className="text-3xl text-left font-medium font-display">
                Reset Password
            </h1>
            <Form onSubmit={handleSubmit} noValidate>
                <Input
                    id="password"
                    name="password"
                    type="password"
                    className="mb-4 mt-4"
                    placeholder="New Password"
                    onChange={handleChange}
                    value={form.password}
                    errors={errors.password}
                />
                <Input
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    className="mb-4 mt-4"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    value={form.password_confirmation}
                    errors={errors.password_confirmation}
                />
                <div className="flex justify-start-left">
                    <Button className="w-2/6 text-white" disabled={processing}>
                        Reset
                    </Button>
                </div>

            </Form>
        </div>
    </LoginWrapper>
}

export default ResetPassword
