import { ReactNode } from 'react'

import { uuid } from '@/services'
import store, { showNotification, hideNotification } from '@/store'

export class ToastNotification {
    id: string

    message: ReactNode

    timeout?: NodeJS.Timeout

    delay?: number | 'permanent'

    onClose?: () => void

    constructor(message: ReactNode, delay: number | 'permanent' = 4000) {
        this.id = uuid()
        this.message = message
        this.delay = delay
        store.dispatch(showNotification(this))
        this.closeWithDelay()
    }

    close = () => {
        store.dispatch(hideNotification(this))
        if (this.onClose) this.onClose()
    }

    closeWithDelay = () => {
        if (this.delay !== 'permanent') {
            this.timeout = setTimeout(this.close, this.delay)
        }
    }

    freeze = () => {
        if (this.delay !== 'permanent') {
            if (this.timeout) clearTimeout(this.timeout)
        }
    }
}
