import {
    Card,
    Button,
    WarrantyMarkUniquePopup,
    WarrantiesCancelOrReinstateButton
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyPotentialDuplicateCardsProps = {
    warranty: Warranty
    activeIndex: number
    onChange: () => void
    onIndexChange: (index: number) => void
}

export const WarrantyPotentialDuplicateCards = ({
    warranty,
    activeIndex,
    ...props
}: WarrantyPotentialDuplicateCardsProps) => {
    const auth = useAuth()

    const isSingleDuplicate = warranty.all_duplicates.length > 1

    return <div className="grid lg:grid-cols-2 gap-6 mb-6">
        <div>
            {isSingleDuplicate && <span className="text-lg text-lg font-body">Current Warranty</span>}
            <Card className={isSingleDuplicate ? 'mt-2 h-32' : 'mt-2'}>
                <div className="flex justify-between items-center h-full">
                    {isSingleDuplicate
                        ? <div className="p-2 flex flex-col items-start">
                            <span className="text-xs text-gray-500">Plan ID</span>
                            <span>{warranty.policy_num}</span>
                        </div>
                        : <div className="p-2 flex gap-2 items-center">
                            <span className="text-lg text-gray-900">Current Warranty</span>
                            <span className="text-primary-700 bg-primary-50 text-xs rounded-full px-2 py-1">
                                {warranty.created_at?.format()}
                            </span>
                        </div>}
                    {(auth.user?.isAdminOrStaff || auth.user?.isContractor) && <div className="flex gap-3">
                        <WarrantiesCancelOrReinstateButton
                            selected={[warranty]}
                            onChange={props.onChange}
                        />
                        <WarrantyMarkUniquePopup
                            warranty={warranty}
                            onChange={props.onChange}
                        />
                    </div>}
                </div>
            </Card>
        </div>
        {warranty.all_duplicates[activeIndex] && <div>
            {isSingleDuplicate && <span className="text-lg text-lg font-body">Potential Duplicates</span>}
            <Card className={isSingleDuplicate ? 'mt-2 h-32' : 'mt-2'}>
                <div className="flex justify-between items-center h-full">
                    {isSingleDuplicate
                        ? <div className="flex gap-2 items-center max-w-[67%] overflow-auto">
                            {warranty.all_duplicates.map((item: Warranty, index: number) =>
                                <Button
                                    design="btn-link"
                                    className={`flex flex-col !p-2 ${activeIndex === index ? 'bg-primary-50' : ''}`}
                                    key={item.id}
                                    onClick={() => props.onIndexChange(index)}
                                >
                                    <span className="text-xs text-gray-500 self-baseline">Plan ID</span>
                                    <span className="text-gray-900">{item.policy_num}</span>
                                </Button>)}
                        </div>
                        : <div className="p-2 flex gap-2 items-center">
                            <span className="text-lg text-gray-900">Potential Duplicate</span>
                            <span className="text-primary-700 bg-primary-50 text-xs rounded-full px-2 py-1">
                                {warranty.all_duplicates[activeIndex].created_at?.format()}
                            </span>
                        </div>}
                    {(auth.user?.isAdminOrStaff || auth.user?.isContractor) && <div className="flex gap-3">
                        <WarrantiesCancelOrReinstateButton
                            selected={[warranty.all_duplicates[activeIndex]]}
                            onChange={props.onChange}
                        />
                        <WarrantyMarkUniquePopup
                            warranty={warranty.all_duplicates[activeIndex]}
                            onChange={props.onChange}
                        />
                    </div>}
                </div>
            </Card>
        </div>}
    </div>
}
