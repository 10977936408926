import { SYSTEM_TIER_CATEGORIES_EXCLUDED_SITES } from './system-tier-categories-excluded-sites'
import { SYSTEM_TIER_CATEGORIES_MONITORED_SITES } from './system-tier-categories-monitored-sites'
import { SYSTEM_TIER_CATEGORIES_UNMONITORED_SITES } from './system-tier-categories-unmonitored-sites'
import { SystemStatusCategoryEnum } from '@/enums'
import { SystemStatusCategoryDataType } from '@/types'

export const SYSTEM_STATUS_CATEGORIES: SystemStatusCategoryDataType[] = [
    {
        value: SystemStatusCategoryEnum.MONITORED,
        title: 'Connected Sites',
        description: 'Connected sites are solar systems that have been successfully integrated into the Solar Insure Monitoring dashboard. This is achieved by matching registration data with supported inverter manufacturers’ database, which enables seamless data integration and regular monitoring updates.',
        tiers: SYSTEM_TIER_CATEGORIES_MONITORED_SITES,
        instructions: [
            {
                title: 'How It Works',
                content: <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Automatic Integration:</span> Upon warranty registration, advanced APIs are utilized to connect the homeowner&apos;s solar system to a corresponding system in the Inverter Manufacturer&apos;s fleet monitoring database, utilizing system ID, site ID, or site address for accurate matching.
                    </li>
                    <li>
                        <span className="font-semibold">Regular Updates:</span> Upon warranty registration, advanced APIs are utilized to connect the homeowner&apos;s solar system to a corresponding system in the Inverter Manufacturer&apos;s fleet monitoring database, utilizing system ID, site ID, or site address for accurate matching.
                    </li>
                    <li>
                        <span className="font-semibold">Advanced Alert Classification:</span> Upon warranty registration, advanced APIs are utilized to connect the homeowner&apos;s solar system to a corresponding system in the Inverter Manufacturer&apos;s fleet monitoring database, utilizing system ID, site ID, or site address for accurate matching.
                    </li>
                </ol>
            }
        ]
    },
    {
        value: SystemStatusCategoryEnum.UNMONITORED_SITES,
        title: 'Unmonitored Sites',
        color: 'blue',
        description: 'Unmonitored Sites are warranties for solar systems that Solar Insure is actively trying to match in the supported inverter manufacturers\' databases, with integration still in progress, thus temporarily hindering data access on our platform.',
        tiers: SYSTEM_TIER_CATEGORIES_UNMONITORED_SITES,
        instructions: [
            {
                title: 'Monitoring Workaround for Contractors',
                content: <div>
                    <p>Until Solar Insure is <span className="font-semibold">fully integrated</span> with your system, stay updated by:
                    </p>
                    <ol className="mt-4 list-decimal pl-5">
                        <li><span className="font-semibold">Regularly accessing</span> the manufacturer&apos;s portals.
                        </li>
                        <li>
                            <span className="font-semibold">Monitoring</span> the latest system status, whether operational or with issues.
                        </li>
                    </ol>
                </div>
            },
            {
                title: 'How to Expedite Connection',
                content: <div>
                    <p>
                        <span className="font-semibold">Streamline Your Integration Process:</span> To ensure a quicker and more seamless connection:
                    </p>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            <span className="font-semibold">Align Addresses: </span>
                            Confirm that the addresses in warranty registrations match those in the
                            manufacturer&apos;s system.
                        </li>
                        <li>
                            <span className="font-semibold">System Identification: </span>
                            Where possible, include the unique system ID for Enphase or site
                            ID for SolarEdge and Generac in your registration details.
                        </li>
                    </ol>
                </div>
            }
        ]
    },
    {
        value: SystemStatusCategoryEnum.EXCLUDED_SITES,
        title: 'Excluded Sites',
        description: 'Excluded Sites are warranties for solar systems that either have inverters not supported by Solar Insure Monitoring or those using supported inverters but missing essential contractor-provided fleet-wide access credentials.',
        tiers: SYSTEM_TIER_CATEGORIES_EXCLUDED_SITES,
        instructions: [
            {
                title: 'Monitoring Workaround for Contractors',
                content: <ol className="mt-4 list-decimal pl-5">
                    <li>
                        Contractors are responsible for providing operations and maintenance support for their
                        installations. For systems with unsupported inverters, continue using the respective
                        manufacturer&apos;s portal for system status, a tool available prior to partnering with Solar
                        Insure.
                    </li>
                    <li>
                        If you require troubleshooting assistance or wish to provide Solar Insure with alternative
                        monitoring access for currently unsupported manufacturers, please submit your monitoring access
                        credentials via the Login Credentials for Solar Insure Fleet Monitoring or contact our support
                        team at monitoring@solarinsure.com.
                    </li>
                    <li>
                        For inverters that are supported but not yet authorized, monitor their status via the
                        manufacturer&apos;s portal until you can provide credentials.
                    </li>
                </ol>
            },
            {
                title: 'How to Expedite Connection',
                content: <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Navigate</span> to your profile and select the &apos;Integrations&apos; tab.
                    </li>
                    <li>
                        <span className="font-semibold">Update and save</span> your inverter credentials as required.
                    </li>
                    <li>
                        Ensure all steps are followed for uninterrupted monitoring service.
                    </li>
                </ol>
            }
        ]
    }
]
