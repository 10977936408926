import { ReactNode } from 'react'

import { SystemStatus, colorMap, sizeMap } from './SystemStatus'
import { SystemStatusCategory } from './SystemStatusCategory'
import { IconSize } from '@/components/icons/Icon'
import { SystemTierCategoryEnum } from '@/enums'
import { SystemStatusColorType, SystemTierCategoryDataType } from '@/types'

export class SystemTierCategory {
    statuses: SystemStatus[]

    value: SystemTierCategoryEnum

    title?: string

    color?: SystemStatusColorType

    subtitle?: string

    description?: ReactNode

    severity?: string

    icon: (size?:IconSize) => ReactNode

    secondaryOrder?: number

    constructor(data: SystemTierCategoryDataType) {
        this.value = data.value
        this.title = data.title
        this.subtitle = data.subtitle
        this.description = data.description
        this.severity = data.severity
        this.color = data.color
        this.icon = data.icon || (() => null)
        this.secondaryOrder = data.secondaryOrder
        this.statuses = data?.statuses?.map(item => new SystemStatus(item)) || []
    }

    static get all() {
        return SystemStatusCategory.all.reduce((acc: SystemTierCategory[], item) => [...acc, ...item.tiers], [])
    }

    get category() {
        return SystemStatusCategory.findByTier(this.value) as SystemStatusCategory
    }

    get secondarySortedStatuses() {
        return this.statuses
            .sort((a, b) => a.secondaryOrder && b.secondaryOrder ? a.secondaryOrder - b.secondaryOrder : 0)
    }

    static find(value: string) {
        return SystemTierCategory.all.find(item => item.value === value)
    }

    static findByStatus(value: string) {
        return SystemTierCategory.all.find(item => item.statuses.some(item => item.value === value))
    }

    get circleIcon() {
        return <div className={`border-2 rounded-full w-3 h-3 ${this.colors.border}`}></div>
    }

    get colors() {
        const color = this.color || this.category.color || 'default'
        return colorMap[color] || colorMap.default
    }

    public iconBadge(size: IconSize = 'sm', className = '') {
        return <div
            style={{ boxShadow: '0 0 0 1px #fff' }}
            className={`flex justify-center items-center rounded-full text-sm font-display ${this.colors.bg} ${this.colors.stroke} ${this.colors.text} ${sizeMap[size]} ${className}`}
        >
            {this.icon(size)}
        </div>
    }
}
