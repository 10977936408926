import { ReactNode } from 'react'

import { System } from './System'
import { SystemStatusCategory } from './SystemStatusCategory'
import { SystemTierCategory } from './SystemTierCategory'
import { IconHelpCircle } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { InverterManufacturerEnum, SystemStatusEnum } from '@/enums'
import { SystemStatusColorType, SystemStatusDataType, SystemStatusTroubleshootingType } from '@/types'


export const colorMap: {
    [key in SystemStatusColorType]: { bg: string, text: string, stroke: string, border: string }
} = {
    success: { bg: 'bg-success-100', text: 'text-success-500', stroke: 'stroke-success-600', border: 'border-success-300' },
    error: { bg: 'bg-error-100', text: 'text-error-600', stroke: 'stroke-error-600', border: 'border-error-300' },
    warning: { bg: 'bg-warning-100', text: 'text-warning-600', stroke: 'stroke-warning-600', border: 'border-warning-300' },
    yellow: { bg: 'bg-yellow-200', text: 'text-yellow-900', stroke: 'stroke-yellow-900', border: 'border-yellow-300' },
    blue: { bg: 'bg-blue-100', text: 'text-blue-600', stroke: 'stroke-blue-600', border: 'border-blue-300' },
    default: { bg: 'bg-gray-200', text: 'text-gray-600', stroke: 'stroke-gray-600', border: 'border-gray-300' }
}

export const sizeMap: { [key: string]: string } = {
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12'
}

export class SystemStatus {
    raw: SystemStatusDataType

    system?: System

    value: SystemStatusEnum

    title: string

    description?: string

    action?: ReactNode

    color?: SystemStatusColorType

    secondaryOrder?: number

    refreshable: boolean

    icon: (size:IconSize) => ReactNode

    constructor(data: SystemStatusDataType) {
        this.raw = data
        this.value = data.value
        this.title = data.title
        this.description = data.description
        this.color = data.color
        this.secondaryOrder = data.secondaryOrder
        this.icon = data.icon
        this.action = data.action
        this.refreshable = !!data.refreshable
        this.raw.troubleshooting = data.troubleshooting
    }

    get troubleshooting() {
        const key = this.system?.manufacturer?.key as InverterManufacturerEnum
        const isUsesMaintainer = this.system?.homeowner?.company?.enphaseIntegration?.isUsesMaintainer

        if (typeof this.raw.troubleshooting === 'object') {
            const troubleshooting = this.raw.troubleshooting as SystemStatusTroubleshootingType
            if (troubleshooting.default) {
                if (isUsesMaintainer && troubleshooting.maintainer && troubleshooting.maintainer[key]) {
                    return troubleshooting.maintainer[key]
                } else if (troubleshooting[key]) {
                    return troubleshooting[key]
                }
                return troubleshooting.default
            }
        }
        return this.raw.troubleshooting
    }

    static get defaultStatus() {
        return new SystemStatus({
            value: SystemStatusEnum.UNKNOWN,
            title: 'Unknown',
            color: 'default',
            icon: (size: IconSize) => <IconHelpCircle size={size}/>
        })
    }

    static get all() {
        return SystemTierCategory.all.reduce((acc: SystemStatus[], item) => [...acc, ...item.statuses], [])
    }

    get category() {
        return SystemStatusCategory.findByStatus(this.value) as SystemStatusCategory
    }

    get tier() {
        return SystemTierCategory.findByStatus(this.value) as SystemTierCategory
    }

    static find(status: string) {
        return SystemStatus.all.find(({ value }) => value === status)
    }

    get colors() {
        const color = this.color || this.tier.color || this.category.color || 'default'
        return colorMap[color] || colorMap.default
    }

    public iconBadge(size: IconSize = 'sm', className = '') {
        return <div
            style={{ boxShadow: '0 0 0 1px #fff' }}
            className={`flex justify-center items-center rounded-full text-sm font-display ${this.colors.bg} ${this.colors.stroke} ${this.colors.text} ${sizeMap[size]} ${className}`}
        >
            {this.icon(size)}
        </div>
    }
}
