import { IconCheckCircle } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'
import { SystemStatusDataType } from '@/types'

export const SYSTEM_STATUSES_MONITORED: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.NORMAL,
        title: 'Normal',
        refreshable: true,
        icon: (size: IconSize) => <IconCheckCircle size={size}/>,
        description: 'All systems are functioning within normal parameters; no immediate action is required.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">No action required as no alert or issue statuses were detected.</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    Your system is <span className="font-semibold">functioning normally</span>.
                </li>
                <li>
                    <span className="font-semibold">Continue</span> to <span className="font-semibold">monitor</span> your system periodically through the <span className="font-semibold">Enphase Enlighten</span> or <span className="font-semibold">SolarEdge Monitoring portal</span> to ensure ongoing optimal performance.
                </li>
                <li>
                    Enjoy the <span className="font-semibold">peace of mind</span> that comes with a smoothly operating solar energy system.
                </li>
            </ol>
        </div>
    }
]
