import { SYSTEM_STATUS_CATEGORIES } from './modules/system-statuses/system-status-categories'
import { SystemStatus } from './SystemStatus'
import { SystemTierCategory } from './SystemTierCategory'
import { SystemStatusCategoryEnum } from '@/enums'
import {
    SystemStatusCategoryDataType,
    SystemStatusCategoryInstructionDataType,
    SystemStatusColorType
} from '@/types'

export class SystemStatusCategory {
    tiers: SystemTierCategory[]

    value: SystemStatusCategoryEnum

    title: string

    color?: SystemStatusColorType

    description: string

    instructions: SystemStatusCategoryInstructionDataType[]

    constructor(data: SystemStatusCategoryDataType) {
        this.value = data.value
        this.title = data.title
        this.color = data.color
        this.description = data.description
        this.instructions = data.instructions || []
        this.tiers = data.tiers.map(item => new SystemTierCategory(item)) || []
    }

    static get all() {
        return SYSTEM_STATUS_CATEGORIES.map(item => new SystemStatusCategory(item))
    }

    get statuses() {
        return this.tiers.reduce((acc: SystemStatus[], item) => [...acc, ...item.statuses], [])
    }

    get secondarySortedTiers() {
        return this.tiers
            .sort((a, b) => a.secondaryOrder && b.secondaryOrder ? a.secondaryOrder - b.secondaryOrder : 0)
    }

    get isConnected() {
        return this.value === SystemStatusCategoryEnum.MONITORED
    }

    get isExcluded() {
        return this.value === SystemStatusCategoryEnum.EXCLUDED_SITES
    }

    get isUnmonitored() {
        return this.value === SystemStatusCategoryEnum.UNMONITORED_SITES
    }

    static get connectedSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.MONITORED) as SystemStatusCategory
    }

    static get unmonitoredSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.UNMONITORED_SITES) as SystemStatusCategory
    }

    static get excludedSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.EXCLUDED_SITES) as SystemStatusCategory
    }

    static find(value: string) {
        return SystemStatusCategory.all.find(item => item.value === value)
    }

    static findByStatus(value: string) {
        return SystemStatusCategory.all.find(item => item.statuses.some(item => item.value === value))
    }

    static findByTier(value: string) {
        return SystemStatusCategory.all.find(item => item.tiers.some(item => item.value === value))
    }
}
