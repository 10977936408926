import { ChangeEvent, useEffect, useState } from 'react'

import { FormChangeEvent, Input, InputProps, SelectOptionProps } from '@/components'

export interface SearchableSelectProps extends InputProps {
    options: Array<SelectOptionProps>
    inputValue?: string
}

export const SearchableSelect = ({
    value,
    inputValue,
    options,
    ...props
}: SearchableSelectProps) => {
    const [stateValue, setStateValue] = useState(options.find(item => item.value === value)?.title as string || '')
    const handleChange = (e: FormChangeEvent) => {
        setStateValue(e.target.value as string)
        if (!e.target.value && value && props.onChange) {
            props.onChange({
                target: {
                    name: props.name as string,
                    value: ''
                }
            })
        }
    }

    const getSuggestions = () => options
        .filter(item => (item.title as string).toString()
            .toLowerCase()
            .includes(stateValue.toString()
                .toLowerCase()))
        .map(item => item.title as string)
        .slice(0, 5)

    const handleSuggestionSelect = (index: number) => {
        const title = getSuggestions()[index]
        const option = options.find(item => item.title === title)
        if (props.onChange) {
            props.onChange({
                target: {
                    name: props.name,
                    value: option?.value || ''
                }
            } as ChangeEvent<HTMLInputElement>)
        }
        setStateValue(title)
    }

    useEffect(() => {
        const option = options.find(item => value && item.value === parseInt(value.toString()))
        if (option) setStateValue(option.title as string)
    }, [value, options])

    useEffect(() => {
        if (typeof inputValue === 'string' && inputValue !== stateValue) setStateValue(inputValue)
    }, [inputValue])

    return <Input
        value={stateValue as string}
        suggestions={getSuggestions()}
        autoComplete="off"
        onSuggestionSelect={handleSuggestionSelect}
        {...props}
        onChange={handleChange}
    />
}
