import { Link } from 'react-router-dom'

import { CompanyAccountActivityStatus } from './CompanyAccountActivityStatus'
import { CompanyBatteryEligibility } from './CompanyBatteryEligibility'
import { CompanyMonitoringStatus } from './CompanyMonitoringStatus'
import { CompanyType } from './CompanyType'
import { Integration } from './Integration'
import { Model } from './Model'
import { User } from './User'
import { BatteryEligibilityTypeEnum, CompanyTypeEnum, WarrantyMatchMethodEnum } from '@/enums'
import { api, Options, Params, Payload } from '@/services'
import { CompanyActivityDataType, CompanyDataType, CompanyEligibleCommissionDataType, IdType } from '@/types'

const warrantyMatchMethods = [
    { value: WarrantyMatchMethodEnum.FULL_SUBCONTRACTOR_MATCH, title: '100% Subcontractor Warranties Matched' },
    { value: WarrantyMatchMethodEnum.EXTERNAL_UPLOAD_MATCH, title: 'External Data Uploads Matching' }
]

export class Company extends Model {
    static endpoint = 'api/v1/company'

    raw: CompanyDataType

    unique_id?: string

    name: string

    email: string

    address?: string

    policy_rate: number

    battery_20_year_rate: number

    battery_30_year_rate: number

    lifetime_eligible_commission: number

    warranty_match_method: WarrantyMatchMethodEnum

    monitoringStatus: CompanyMonitoringStatus

    warranties_lifetime: number

    warranties_this_month: number

    users: User[]

    integrations: Integration[]

    eligible_commission_rates: CompanyEligibleCommissionDataType[]

    activity?: CompanyActivityDataType

    subsidiaries: Company[]

    conglomerate?: Company

    affiliate?: Company

    monthly_sales_quota: number

    battery_eligibility: CompanyBatteryEligibility

    conglomerate_id?: IdType

    status: string

    create_warranty?: string

    last_warranty_created_at?: Date

    last_warranty_date?: Date

    account_activity_status: CompanyAccountActivityStatus

    total_claim_payout_amount: number

    account_manager?: string

    account_manager_email?: string

    partner_start_date?: Date


    type: CompanyType

    constructor(data: CompanyDataType) {
        super(data)
        this.raw = data
        this.unique_id = data.unique_id
        this.name = data.name
        this.email = data.email
        this.address = data.address
        this.type = CompanyType.find(data.type)
        this.status = data.status
        this.account_manager = data.account_manager
        this.account_manager_email = data.account_manager_email
        this.total_claim_payout_amount = Model.toNumber(data.total_claim_payout_amount, 0) as number
        this.partner_start_date = Model.toDate(data.partner_start_date)
        this.account_activity_status = CompanyAccountActivityStatus.find(data.account_activity_status)
        this.battery_eligibility = CompanyBatteryEligibility.find(data.battery_eligibility)
        this.policy_rate = data.policy_rate
        this.battery_20_year_rate = data.battery_20_year_rate
        this.battery_30_year_rate = data.battery_30_year_rate
        this.lifetime_eligible_commission = data.lifetime_eligible_commission
        this.warranty_match_method = data.warranty_match_method
        this.monitoringStatus = CompanyMonitoringStatus.find(data.monitoring?.status)
        this.monitoringStatus.company = this
        this.monitoringStatus.discovered_at = Model.toDate(data.monitoring?.discovered_at)
        this.users = data.users?.map(item => new User(item)) || []
        this.subsidiaries = data.subsidiaries?.map(item => new Company(item)) || []
        this.monthly_sales_quota = Model.toNumber(data.monthly_sales_quota, 0) as number
        this.warranties_lifetime = Model.toNumber(data.warranties_lifetime, 0) as number
        this.warranties_this_month = Model.toNumber(data.warranties_this_month, 0) as number
        this.integrations = (data.integrations?.map(item => new Integration(item)) || [])
            .sort((a: Integration, b: Integration) => {
                const orderA = Integration.DEFAULT_ORDER[a.manufacturer.key] || Integration.DEFAULT_ORDER.other
                const orderB = Integration.DEFAULT_ORDER[b.manufacturer.key] || Integration.DEFAULT_ORDER.other
                return orderA - orderB
            })
        this.eligible_commission_rates = data.eligible_commission_rates?.sort((a, b) =>
            +new Date(b.effective_date) - +new Date(a.effective_date)) || []

        this.affiliate = data.affiliate ? new Company(data.affiliate) : undefined
        this.conglomerate_id = data.conglomerate_id
        this.conglomerate = data.conglomerate ? new Company(data.conglomerate) : undefined
        this.create_warranty = data.create_warranty
        this.last_warranty_date = Model.toDate(data.last_warranty_date)
        this.last_warranty_created_at = Model.toDate(data.last_warranty_created_at)
        this.activity = data.activity
    }

    static warrantyMatchMethods = warrantyMatchMethods

    get isEligibleForBatteries() {
        return this.isEligibleFor20YearBatteries || this.isEligibleFor30YearBatteries
    }

    get isEligibleFor20YearBatteries() {
        return this.battery_eligibility.key === BatteryEligibilityTypeEnum.BATTERY_20_YEAR
    }

    get isEligibleFor30YearBatteries() {
        return this.battery_eligibility.key === BatteryEligibilityTypeEnum.BATTERY_30_YEAR
    }


    get isSoftwareAffiliate() {
        return this.type?.key === CompanyTypeEnum.SOFTWARE_AFFILIATE
    }

    get isLenderAffiliate() {
        return this.type?.key === CompanyTypeEnum.LENDER_AFFILIATE
    }

    get isContractor() {
        return this.type?.key === CompanyTypeEnum.CONTRACTOR
    }

    get isAffiliate() {
        return this.isLenderAffiliate || this.isSoftwareAffiliate
    }

    get isSubsidiary() {
        return !!this.conglomerate_id
    }

    get solarEdgeIntegration() {
        return this.integrations?.find((item: Integration) => item.manufacturer.isSolaredge)
    }

    get enphaseIntegration() {
        return this.integrations?.find((item: Integration) => item.manufacturer.isEnphase)
    }

    get generacIntegration() {
        return this.integrations?.find((item: Integration) => item.manufacturer.isGenerac)
    }

    get supportedIntegrations() {
        return this.integrations.filter(integration => !integration.isUnsupported)
    }

    get unsupportedIntegrations() {
        return this.integrations.filter(integration => integration.isUnsupported)
    }

    get statusBadge() {
        switch (this.status) {
            case 'active': {
                return <span className="badge bg-success-100 text-success-700">
                    active
                </span>
            }
            default: {
                return <span className="badge bg-error-100 text-error-700">
                    inactive
                </span>
            }
        }
    }

    get isExternalUploadMatch() {
        return this.warranty_match_method === WarrantyMatchMethodEnum.EXTERNAL_UPLOAD_MATCH
    }

    get isFullSubcontractorMatch() {
        return this.warranty_match_method === WarrantyMatchMethodEnum.FULL_SUBCONTRACTOR_MATCH
    }

    get currentEligibleCommission() {
        return this.eligible_commission_rates.find(item => new Date(item.effective_date) < new Date())
    }

    static get eligibleCommissionTooltipContent() {
        return <div className="text-sm p-1">
            <p className="font-bold">Commission Calculation</p>
            <ul className="list-disc pl-4 py-2">
                <li>Commission rate: 20% per watt of every warranty sold by your subcontractors.</li>
                <li className="text-yellow-100">
                    For example, if a subcontractor sells system with a 30-year warranty priced at $5,000, the
                    commission would be 0.2 x $5,000 = $1000.
                </li>
                <li>Commission is calculated automatically based on your affiliate profile.</li>
                <li>To receive commission, your subcontractors must have paid their invoices.</li>
            </ul>
            <p className="mt-1">
                Please ensure that your subcontractors have paid their invoices on time to ensure timely payment of
                commissions. If you have any questions, please contact us for further assistance.
            </p>
            <div className="w-full block text-right">
                <Link className="text-orange-700" to="/support/contact">
                    Contact Us
                </Link>
            </div>
        </div>
    }

    updateSingleField(payload: Payload) {
        return this.update({
            name: this.name,
            email: this.email,
            address: this.address,
            monthly_sales_quota: this.monthly_sales_quota,
            policy_rate: this.policy_rate,
            battery_20_year_rate: this.battery_20_year_rate,
            battery_30_year_rate: this.battery_30_year_rate,
            conglomerate_id: this.conglomerate?.id,
            unique_id: this.unique_id,
            update_policy: 'all',
            battery_eligibility: this.battery_eligibility.key,
            ...payload
        })
    }

    downloadSystemData() {
        return api.companies.downloadSystemData(this.id)
    }

    activities() {
        return api.companies.activities(this.id)
    }

    updateContacts(payload: Payload) {
        return api.companies.updateCompanyUsers(this.id, payload)
    }

    updateEligibleCommissions(payload: Payload) {
        return api.companies.updateEligibleCommissions(this.id, payload)
    }

    static contractors(params?: Params, options?: Options) {
        return api.companies.contractors(params, options)
    }

    static updateSelf(payload: Payload, params?: Params, options?: Options) {
        return api.http.put(Company.endpoint, payload, params, options)
    }

    static async autocomplete(params?: Params) {
        const res = await api.companies.autocomplete(params)
        return res.data?.map((item: CompanyDataType) => new Company(item)) || []
    }

    static onlyAffiliates() {
        return Company.autocomplete({ type: 'conglomerate' })
    }

    static onlyContractors() {
        return Company.autocomplete({ type: 'contractor' })
    }
}
