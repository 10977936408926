import { IconCheckCircleBroken, IconXCircle } from '@/components'
import { Warranty, WarrantyHistory } from '@/models'

export type WarrantyRequestHandledProps = {
    event: WarrantyHistory
    warranty: Warranty
}

export const WarrantyRequestHandled = ({ event, warranty }: WarrantyRequestHandledProps) =>
    <div>
        <div className="flex items-center gap-2">
            {event.event.key.endsWith('approved')
                ? <IconCheckCircleBroken className="stroke-primary-600" size="lg"/>
                : <IconXCircle className="stroke-error-500" size="lg"/>}
            <div>
                <h2 className="text-xl leading-8 font-semibold text-gray-900">
                    Warranty Plan ID {warranty.policy_num}
                </h2>
                <h3 className="text-sm text-gray-750 leading-5 capitalize">
                    {event.event?.title} Request {event.event.key.endsWith('approved') ? 'Approved' : 'Denied'} - {event.created_at?.format({
                        hour: '2-digit',
                        minute: '2-digit'
                    }) || 'N/A'}
                </h3>
            </div>
        </div>
        <p className="text-sm mt-4 text-gray-500 whitespace-pre-line">
            {event.event.key.endsWith('approved')
                ? `We have processed your request and ${event.event?.action} your warranty successfully.
                If you have any further questions or concerns, please reach out to a Solar Insure representative. Thank you!`
                : `We're sorry to inform you that your warranty ${event.event?.title} request has been denied.
                If you have any questions or need more information, please reach out to a Solar Insure representative. Thank you!`}
        </p>
    </div>
