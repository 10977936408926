import { useMemo } from 'react'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

import { Button, MonitoringIssuesChart, Tooltip } from '@/components'
import { useFormData, useQuery } from '@/hooks'
import { SystemStatusCategory } from '@/models'
import { MonitoringSummaryDataType } from '@/types'

type MonitoringDonutReportProps = {
    summary: MonitoringSummaryDataType
    isExpanded: boolean
}

const MonitoringDonutReport = ({ isExpanded, summary }: MonitoringDonutReportProps) => {
    const location = useLocation()
    const query = useQuery()
    const { convert } = useFormData()

    const getMonitoringIndexPage = (filters: any) => {
        const params = convert(location.pathname === '/monitoring' ? { ...query, ...filters } : filters, {}) as {
            [key: string]: string
        }
        return `/monitoring?${createSearchParams(params)}`
    }

    const connectedTiers = useMemo(() => SystemStatusCategory.connectedSitesCategory.tiers, [])

    return isExpanded
        ? <div className="flex flex-wrap gap-8 w-full justify-center">
            <MonitoringIssuesChart
                key="full-chart"
                summary={summary}
                className="w-35 !h-35"
            />
            <div className="shrink-0">
                {connectedTiers.map(item =>
                    <div key={item.value}>
                        <h5 className="leading-5 flex items-center gap-2">
                            <Button
                                design="btn-link"
                                className="flex gap-2 items-center"
                                href={getMonitoringIndexPage({ statuses: [item.value] })}
                                data-test={`monitoring-index-issues-${item.value}-title`}
                            >
                                {item.circleIcon} {item.title}
                            </Button>
                            <div className="flex">
                                {item.statuses.length > 1 && item.secondarySortedStatuses.map(item =>
                                    !!summary.statuses_count[item.value] && <Tooltip
                                        className="hover:z-10"
                                        key={item.value}
                                        content={`${item.title} (${summary.statuses_count[item.value]?.format()})`}
                                    >
                                        <Link
                                            to={getMonitoringIndexPage({ statuses: [item.value] })}
                                            className="rounded-full block"
                                            data-test={`monitoring-index-issues-${item.value}-icon`}
                                        >
                                            {item.iconBadge('xs')}
                                        </Link>
                                    </Tooltip>)}
                            </div>
                        </h5>
                        <p className="text-xs text-gray-500 leading-[18px]" data-test={`monitoring-index-issues-${item.value}-subtitle`}>
                            {item.subtitle}
                        </p>
                    </div>)}
            </div>
        </div>
        : <div className="flex gap-8 w-full justify-center 2xl:-my-9">
            <MonitoringIssuesChart
                key="half-chart"
                summary={summary}
                className="w-53.5 !h-27"
                type="half-donut"
            />
        </div>
}

export default MonitoringDonutReport
