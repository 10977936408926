import { useMemo, useState } from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router-dom'

import {
    IconCertificate,
    Button,
    Card,
    AddressForm,
    HomeownerForm,
    AffiliatesForm,
    BatteriesForm,
    WarrantyHeader,
    IconBarLineChart,
    IconList,
    WarrantyReviewEditRequestPopup,
    WarrantyConfirmEditPopup,
    InverterForm,
    PanelForm,
    PolicyRatesForm,
    DocumentsForm,
    ImagesForm,
    Form,
    FormErrorsType,
    PanelFormDataType,
    InverterFormDataType,
    BatteriesFormDataType,
    HomeownerFormDataType,
    AddressFormDataType,
    ActionReasonFormDataType,
    PolicyRatesFormDataType,
    AffiliatesFormDataType,
    ImagesFormDataType,
    DocumentsFormDataType, FormChangeEvent
} from '@/components'
import { AuthLayout } from '@/containers'
import { InverterManufacturerEnum } from '@/enums'
import { useAuth, useFormData, useToastNotifications } from '@/hooks'
import { Company, Warranty } from '@/models'
import { isApiError } from '@/services'

interface WarrantyEditFormType extends
    PanelFormDataType,
    InverterFormDataType,
    BatteriesFormDataType,
    HomeownerFormDataType,
    AddressFormDataType,
    ActionReasonFormDataType,
    PolicyRatesFormDataType,
    AffiliatesFormDataType,
    ImagesFormDataType,
    DocumentsFormDataType
{}

const WarrantiesEdit = () => {
    const { type, tab } = useParams()
    const warranty = useLoaderData() as Warranty
    const auth = useAuth()
    const navigate = useNavigate()
    const { convert } = useFormData()
    const { success, error } = useToastNotifications()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false)
    const [newPrice, setNewPrice] = useState(warranty.price)

    const initialForm: WarrantyEditFormType = {
        panel_name: warranty.panel?.model_name || '',
        num_panels: warranty.num_panels || '',
        panel_wattage: warranty.panel_wattage || '',
        size_kw: warranty.size_kw || '',

        inverter_name: warranty.inverter?.model_name || '',
        inverter_manufacturer: warranty.inverter?.provider || InverterManufacturerEnum.OTHER,

        num_microinverters: warranty.num_microinverters || '',
        install_date: warranty.homeowner?.install_date as Date,
        system_id: warranty.homeowner?.system_id || '',

        batteries: Array.isArray(warranty.batteries) ? warranty.batteries.map(item => ({
            id: item.id,
            product_id: item.product_id,
            size_kw: item.size_kw,
            product: {
                id: item.id,
                model_name: item.product.model_name
            }
        })) : [],

        first_name: warranty.homeowner?.first_name || '',
        last_name: warranty.homeowner?.last_name || '',
        email: warranty.homeowner?.email || '',
        phone: warranty.homeowner?.phone || '',

        street_address: warranty.homeowner?.street_address || '',
        city: warranty.homeowner?.city || '',
        state: warranty.homeowner?.state || '',
        zip: warranty.homeowner?.zip || '',

        duplicate_override: (() => {
            if (warranty.homeowner?.allow_duplicate) {
                return 1
            }
            if (warranty.status.isDuplicate) {
                return 0
            }
            return undefined
        })(),

        allow_invalid: warranty.homeowner?.allow_invalid_address ? 1 : undefined,

        affiliate_id: warranty.affiliate_id || '',
        affiliate_name: warranty.affiliate?.name || '',

        policy_rate: warranty.policy_rate,
        battery_rate: warranty.battery_rate,

        edit_reason: '',
        edit_contact_email: ''
    }

    const [form, setForm] = useState({ ...initialForm })

    const isPriceSensitive = useMemo<boolean>(() => Warranty.priceSensitiveFields
        .some(item => !item.compare(form[item.key], initialForm[item.key])), [form])
    const isFormDirty = useMemo<boolean>(() => Object.keys(form)
        .some(key => !Warranty.getField(key).compare(form[key], initialForm[key])), [form])

    const handleChange = (e: FormChangeEvent) => {
        const isChecked = e.target.checked ? 1 : 0
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? isChecked : e.target.value
        }))
    }

    const updateWarranty = async () => {
        const formData = convert(form)
        await warranty.update(formData)
        if (auth.user?.isContractor && isPriceSensitive && (warranty.created_at as Date) < new Date().subtract('day', 1)) {
            success('Warranty edit successfully requested.')
        } else {
            success('Warranty successfully updated.')
        }
        navigate(`/${type}/${warranty.id}/warranty`)
    }

    const validateWarranty = async () => {
        const formData = convert(form)
        await warranty.validate(formData as FormData)
    }

    const fetchNewPrice = async () => {
        const res = await warranty.getCalculatePrices({
            size_kw: form.size_kw,
            policy_rate: form.policy_rate,
            battery_rate: form.battery_rate,
            battery_size_kw: form.batteries
                .reduce((acc: number, { size_kw: sizeKw }) => acc + (parseFloat(sizeKw as string) || 0), 0)
        })
        setNewPrice(res.data.price)
        setIsConfirmationPopupOpen(true)
    }

    const handleSubmit = async () => {
        if (!isFormDirty) {
            navigate(`/${type}/${warranty.id}/warranty`)
            return
        }
        setErrors({})
        setProcessing(true)

        try {
            if (!isConfirmationPopupOpen) {
                await validateWarranty()
                if (isPriceSensitive) {
                    await fetchNewPrice()
                } else {
                    setIsConfirmationPopupOpen(true)
                }
            } else {
                await updateWarranty()
            }
        } catch (err) {
            if (!isApiError(err)) throw err

            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
                if (!err.errors.edit_reason && !err.errors.edit_contact_email && isConfirmationPopupOpen) {
                    setIsConfirmationPopupOpen(false)
                }
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const getBreadcrumb = () => {
        const breadcrumbs: Record<string, [JSX.Element, string]> = {
            monitoring: [<IconBarLineChart key={1} className="stroke-gray-500"/>, 'Monitoring'],
            warranties: [<IconCertificate key={1} className="stroke-gray-500"/>, 'Warranties'],
            'warranties-by-contractors': [<IconList key={1} className="stroke-gray-500"/>, 'Warranties by Contractor']
        }

        const [icon, label] = type ? breadcrumbs[type] : breadcrumbs.warranties

        return [
            icon,
            <Link key={2} to={`/${type}`}>{label}</Link>,
            <Link key={3} to={`/${type}/${warranty.id}/${tab}`}>Homeowner Details - Warranty Tab</Link>,
            'Edit Warranty'
        ]
    }

    return <AuthLayout
        heading={<WarrantyHeader warranty={warranty} backUrl={`/${type}/${warranty.id}/${tab}`}/>} breadcrumb={getBreadcrumb()}
    >
        <Card data-test="warranty-edit-page">
            <Form onSubmit={handleSubmit}>
                <h2 className="card-title mb-6 flex flex-col lg:flex-row lg:justify-between items:start lg:items-center">
                    Edit Warranty
                    <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end mt-4 lg:mt-0">
                        <Button design="btn-secondary-gray" hasError href={`/${type}/${warranty.id}/warranty`} data-test="exit-without-saving">
                            Exit Edit Mode Without Saving
                        </Button>
                        <Button processing={processing} data-test="update-warranty-button-1">
                            Exit & Save Edits
                        </Button>
                    </div>
                </h2>
                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 mt-6">
                    <h3 className="font-semibold lg:col-span-2">Panel Information</h3>
                    <PanelForm form={form} errors={errors} onChange={handleChange}/>

                    <hr className="lg:col-span-2"/>

                    <h3 className="font-semibold lg:col-span-2">Inverter Information</h3>
                    <InverterForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        mode="edit"
                    />

                    {warranty.homeowner.company?.isEligibleForBatteries && <>
                        <hr className="lg:col-span-2"/>
                        <h3 className="font-semibold lg:col-span-2">Battery Information</h3>
                        <BatteriesForm
                            form={form}
                            errors={errors}
                            onChange={handleChange}
                            warranty={warranty}
                            company={warranty.homeowner.company}
                        />
                    </>}

                    <hr className="lg:col-span-2"/>
                    <h3 className="font-semibold lg:col-span-2">Customer Information</h3>
                    <HomeownerForm form={form} errors={errors} onChange={handleChange}/>

                    <hr className="lg:col-span-2"/>
                    <h3 className="font-semibold lg:col-span-2">Address</h3>
                    <AddressForm form={form} errors={errors} onChange={handleChange}/>

                    {auth.user?.isAdminOrStaff && <>
                        <hr className="lg:col-span-2"/>
                        <h3 className="font-semibold lg:col-span-2">Assign Affiliate</h3>
                        <AffiliatesForm form={form} errors={errors} onChange={handleChange} warranty={warranty}/>

                        <hr className="lg:col-span-2"/>
                        <h3 className="font-semibold lg:col-span-2">Rates Information</h3>
                        <PolicyRatesForm
                            form={form}
                            errors={errors}
                            onChange={handleChange}
                            warranty={warranty}
                            company={warranty.homeowner.company as Company}
                        />
                    </>}

                    <hr className="lg:col-span-2"/>
                    <h3 className="font-semibold lg:col-span-2">Related Documents</h3>
                    <DocumentsForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        inputClassName="col-span-2"
                    />

                    <h3 className="font-semibold lg:col-span-2">Related Images</h3>
                    <ImagesForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        inputClassName="col-span-2"
                    />
                </div>
                <div className="mt-6 flex justify-center lg:justify-end">
                    <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end mt-4 lg:mt-0">
                        <Button design="btn-secondary-gray" hasError href={`/${type}/${warranty.id}/warranty`}>
                            Exit Edit Mode Without Saving
                        </Button>
                        <Button processing={processing} data-test="update-warranty-button-2">
                            Exit & Save Edits
                        </Button>
                    </div>
                </div>
            </Form>
        </Card>
        <WarrantyConfirmEditPopup
            processing={processing}
            form={form}
            newPrice={newPrice}
            errors={errors}
            isOpen={isConfirmationPopupOpen}
            warranty={warranty}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onClose={() => setIsConfirmationPopupOpen(false)}
        />

        <WarrantyReviewEditRequestPopup
            warranty={warranty}
            onChange={handleChange}
        />
    </AuthLayout>
}

export default WarrantiesEdit
