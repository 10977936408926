import { useState } from 'react'

import { ArticleForm, Button, Form, FormChangeEvent, FormErrorsType } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Article, ArticleCategory } from '@/models'
import { isApiError } from '@/services'

const ArticleCreate = ({
    category,
    ...props
}: { onComplete: () => void, category: ArticleCategory }) => {
    const { error } = useToastNotifications()
    const [form, setForm] = useState({
        category_id: category.id,
        title: '',
        content: ''
    })
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})
        try {
            await Article.store(form)
            props.onComplete()
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <Form className="w-[50rem] max-w-full md:pr-8 flex flex-col gap-4" onSubmit={handleSubmit}>
        <h3 className="font-semibold mb-4">Create New Article</h3>

        <ArticleForm
            form={form}
            errors={errors}
            onChange={handleChange}
        />

        <div className="flex justify-end">
            <Button design="btn-secondary" hasError type="button" onClick={props.onComplete}>
                Cancel
            </Button>
            <Button className="ml-2" processing={processing}>
                Save
            </Button>
        </div>
    </Form>
}

export default ArticleCreate
