import { useEffect, useRef, useState } from 'react'

const LoadingBar = () => {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const [progress, setProgress] = useState(0)
    const start = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
            setProgress(val => {
                let step = 0.05
                if (val >= 100) {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current)
                    }
                } else if (val >= 80) {
                    step = 0.003
                } else if (val >= 70) {
                    step = 0.004
                } else if (val >= 60) {
                    step = 0.005
                } else if (val >= 50) {
                    step = 0.01
                } else if (val >= 40) {
                    step = 0.025
                } else if (val >= 30) {
                    step = 0.05
                }
                return val + step
            })
        }, 10)
    }
    const stop = async () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
            setProgress(val => {
                if (val >= 100) {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current)
                    }
                    return 0
                }
                return val + 2
            })
        }, 5)
    }

    const set = (e: CustomEvent<{ value: number }>) => {
        setProgress(e.detail.value)
    }

    useEffect(() => {
        document.removeEventListener('onLoaderStart', start)
        document.removeEventListener('onLoaderStop', stop)
        document.removeEventListener('onLoaderChange', set as EventListener)
        document.addEventListener('onLoaderStart', start)
        document.addEventListener('onLoaderStop', stop)
        document.addEventListener('onLoaderChange', set as EventListener)
    }, [])

    if (!progress) return null
    return <div className="loading-bar-container">
        <div className="loading-bar" style={{ width: `${progress}%` }}/>
    </div>
}

export default LoadingBar
