import { Device } from './Device'
import { DeviceCategory } from './DeviceCategory'
import { InverterManufacturer } from './InverterManufacturer'
import { System } from './System'
import { SystemStatus } from './SystemStatus'
import { DeviceTypeEnum, GeneracSystemStatusEnum, SystemStatusEnum } from '@/enums'
import { DeviceDataType, GeneracSystemDataType, IdType } from '@/types'

export class GeneracSystem {
    id: IdType

    serialNumber: string

    status: SystemStatus

    deviceCategories: DeviceCategory[]

    constructor(data: GeneracSystemDataType) {
        this.id = data.systemId
        this.serialNumber = data.serialNumber
        this.status = SystemStatus.find(GeneracSystem
            .statusMap[data.statusName as GeneracSystemStatusEnum] as SystemStatusEnum) as SystemStatus

        this.deviceCategories = Object.keys(data.devices).map(key => new DeviceCategory({
            ...data.devices[key as DeviceTypeEnum],
            id: `${data.systemId} ${key}`,
            type: key as DeviceTypeEnum,
            provider: InverterManufacturer.generac.key,
            devices: data.devices[key as DeviceTypeEnum] as DeviceDataType[],
            system: data.system as System
        }))
    }

    static statusMap: { [key in GeneracSystemStatusEnum]?: SystemStatusEnum } = {
        SUCCESS: SystemStatusEnum.NORMAL,
        ERROR: SystemStatusEnum.COMPONENT_ERROR,
        DISCONNECTED: SystemStatusEnum.SYSTEM_DISCONNECTED,
        NEVER_CONNECTED: SystemStatusEnum.NEVER_CONNECTED,
        UNKNOWN: SystemStatusEnum.UNKNOWN_STATUS
    }

    get allDevices(): Device[] {
        return this.deviceCategories.reduce((acc: Device[], item) => [...acc, ...item.devices], [])
    }

    get normalDevices() {
        return this.allDevices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.allDevices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.allDevices.filter(item => item.isErrored)
    }
}
