import { ChangeEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, IconInfoCircle, Popup, WarrantyUpdateDetails, Tooltip } from '@/components'
import { Warranty } from '@/models'
import { UpdateRequestType } from '@/types'


type WarrantyReviewEditRequestPopupProps = {
    warranty: Warranty
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const WarrantyReviewEditRequestPopup = ({ warranty, ...props }: WarrantyReviewEditRequestPopupProps) => {
    const navigate = useNavigate()
    const { type } = useParams()
    const [processing, setProcessing] = useState(false)
    const [isOpen, setIsOpen] = useState(warranty.status.isEditRequested)

    const request = warranty.pending_update_requests[0] as UpdateRequestType

    const handleSubmit = async (action: 'approve' | 'deny') => {
        setProcessing(true)
        try {
            await Warranty.updateRequest(request.id, action)
            setIsOpen(false)
            navigate(`/${type}/${warranty.id}/warranty`)
        } finally {
            setProcessing(false)
        }
    }

    const handleModifyForm = () => {
        Object.keys(request.requested_changes).forEach(key => {
            let value: any
            if (key === 'install_date') {
                if (new Date(request.requested_changes[key]).isValid()) {
                    value = new Date(request.requested_changes[key])
                } else if (new Date(request.requested_changes[key].replaceAll('-', '/')).isValid()) {
                    value = new Date(request.requested_changes[key].replaceAll('-', '/'))
                } else {
                    value = ''
                }
            } else if (key === 'batteries') {
                value = request.requested_changes[key] || []
            } else if (key === 'duplicate_override') {
                if (warranty.homeowner?.allow_duplicate) {
                    value = 1
                }
                if (warranty.status.isDuplicate) {
                    value = 0
                }
                value = undefined
            } else if (key === 'allow_invalid') {
                value = request.requested_changes[key] ? 1 : undefined
            } else {
                value = request.requested_changes[key] || ''
            }
            props.onChange({ target: { name: key, value } } as unknown as ChangeEvent<HTMLInputElement>)
        })
        props.onChange({ target: { name: 'warranty_update_request_id', value: request.id } } as unknown as ChangeEvent<HTMLInputElement>)
        setIsOpen(false)
    }

    return request && <Popup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data-test="approve-warranty-edits-popup"
    >
        <div className="flex flex-col gap-4 text-gray-900 lg:min-w-[45rem]">
            <p className="text-lg font-semibold flex gap-2 items-center">
                <IconInfoCircle className="stroke-warning-600" size="lg"/>
                Approve Warranty Edits
            </p>

            <WarrantyUpdateDetails
                oldValues={{
                    ...warranty,
                    ...warranty.homeowner,
                    affiliate_name: warranty.affiliate?.name,
                    panel_name: warranty.panel?.model_name,
                    inverter_name: warranty.inverter?.model_name,
                    inverter_manufacturer: warranty.inverter?.provider
                }}
                newValues={{
                    ...request.requested_changes,
                    price: request.new_price
                }}
            />

            <p className="text-sm font-semibold" data-test="approve-edits-text">
                Do you want to save these edits to the warranty?
            </p>

            <div className="flex flex-col lg:flex-row gap-2 justify-between">
                <Tooltip content="Modify Warranty Edits">
                    <Button type="button" design="btn-secondary" onClick={handleModifyForm} data-test="modify-warranty-edits-button">Modify Warranty Edits</Button>
                </Tooltip>

                <div className="flex justify-end gap-2">
                    <Button
                        processing={processing}
                        hasError
                        design="btn-secondary-gray"
                        className="w-40"
                        data-test="deny-warranty-edits-button"
                        onClick={() => handleSubmit('deny')}
                    >
                        Deny Edits
                    </Button>
                    <Button
                        processing={processing}
                        className="w-40"
                        data-test="approve-warranty-edits-button"
                        onClick={() => handleSubmit('approve')}
                    >
                        Yes, Approve Edits
                    </Button>
                </div>
            </div>
        </div>
    </Popup>
}
