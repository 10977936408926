import { Model } from './Model'
import { Product } from './Product'
import { BatteryDataType, IdType } from '@/types'

export class Battery extends Model {
    raw: BatteryDataType

    product_id: IdType

    warranty_id: IdType

    size_kw: number

    size_w: number

    product: Product

    constructor(data: BatteryDataType) {
        super(data)
        this.raw = data
        this.product_id = data.product_id
        this.warranty_id = data.warranty_id
        this.size_kw = Model.toNumber(data.size_kw) as number
        this.size_w = Model.toNumber(data.size_w) as number
        this.product = new Product(data.product)
    }
}
