import { Chart as ChartJs } from 'chart.js'
import { useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'

import {
    Badge,
    BarChart,
    Button,
    Card,
    IconBarChart07,
    IconCertificate,
    IconFlipBackward, IconBarLineChart, IconLineChartUp01, IconList, LineChart, WarrantyHeader,
    WarrantyNavigation
} from '@/components'
import { AuthLayout } from '@/containers'
import { Warranty } from '@/models'

const Graphs = () => {
    const { type, tab } = useParams()
    const data = useLoaderData() as Warranty

    const [warranty, setWarranty] = useState(data)

    const fetchWarranty = async () => {
        const res = await Warranty.show(warranty.id) as Warranty
        setWarranty(res)
    }
    const [graphType, setGraphType] = useState(location.hash?.substring(1) || 'line')

    const getLabels = () => {
        const arr = []
        const end = warranty.homeowner?.system?.production?.end_date || new Date()
        const start = end.clone().subtract('week', 1)
        do {
            start.add('day', 1)
            arr.push(start.format())
        } while (start < end)
        return arr
    }

    const getData = () => ({
        produced: warranty.homeowner?.system?.production?.values || [],
        consumed: warranty.homeowner?.system?.consumption?.values || []
    })

    const getLineDatasets = () => {
        const { produced, consumed } = getData()

        const ctx = document.createElement('canvas').getContext('2d')

        const gradient1 = ctx?.createLinearGradient(0, 0, 0, 300)
        gradient1?.addColorStop(0, 'rgba(255, 185, 83, 0.25)')
        gradient1?.addColorStop(1, 'rgba(255, 216, 162, 0)')
        const gradient2 = ctx?.createLinearGradient(0, 0, 0, 300)
        gradient2?.addColorStop(0, 'rgba(0, 122, 150, 0.1)')
        gradient2?.addColorStop(1, 'rgba(0, 122, 150, 0)')
        return [
            {
                label: 'Produces',
                data: produced,
                borderWidth: 2,
                borderColor: '#e79100',
                tension: 0.25,
                fill: true,
                backgroundColor: gradient1
            },
            {
                label: 'Consumed',
                data: consumed,
                borderWidth: 2,
                borderColor: '#009ab7',
                tension: 0.25,
                fill: true,
                backgroundColor: gradient2
            }
        ]
    }

    const getBarDatasets = () => {
        const { produced, consumed } = getData()

        const ctx = document.createElement('canvas').getContext('2d')

        const gradient1 = ctx?.createLinearGradient(0, 0, 0, 300)
        gradient1?.addColorStop(0, '#ffa807')
        gradient1?.addColorStop(1, '#ffd8a2')
        const gradient2 = ctx?.createLinearGradient(0, 0, 0, 300)
        gradient2?.addColorStop(0, '#007a96')
        gradient2?.addColorStop(1, '#c9e3e9')
        return [
            {
                label: 'Produces',
                data: produced,
                borderColor: 'transparent',
                borderWidth: 2,
                borderRadius: 8,
                barThickness: 40,
                fill: true,
                backgroundColor: gradient1
            },
            {
                label: 'Consumed',
                data: consumed,
                borderColor: 'transparent',
                borderWidth: 2,
                borderRadius: 8,
                barThickness: 40,
                fill: true,
                backgroundColor: gradient2
            }
        ]
    }

    const getOptions = () => ({
        plugins: {
            tooltip: {
                usePointStyle: true,
                padding: 12,
                displayColors: false,
                backgroundColor: '#fff',
                borderColor: '#e1f2f8',
                borderWidth: 1,
                titleColor: '#403f35',
                bodyFont: {
                    size: 18,
                    weight: 'semibold'
                },
                bodyColor: '#403f35',
                footerColor: '#403f35',
                footerFont: {
                    weight: 'light',
                    family: 'open-sans',
                    size: 12
                },
                bodySpacing: 8,
                boxPadding: 8,
                boxWidth: 8,
                boxHeight: 8,
                callbacks: {
                    title() {
                        return ''
                    },
                    labelPointStyle(): any {
                        return {
                            pointStyle: 'circle',
                            rotation: 0
                        }
                    },
                    labelColor(tooltipItem: any): any {
                        return {
                            borderColor: tooltipItem.dataset.borderColor,
                            backgroundColor: tooltipItem.dataset.borderColor
                        }
                    },
                    label(tooltipItem: any): string | string[] | void {
                        if (typeof tooltipItem.raw === 'number') {
                            return `${(tooltipItem.raw / 1000).format({ minimumFractionDigits: 3 })} kW ${tooltipItem.dataset.label}`
                        }

                        return `${(0).format()} kW ${tooltipItem.dataset.label}`
                    },
                    footer(tooltipItems: any) {
                        return tooltipItems[0].label
                    }
                }
            }
        }
    })
    const getBreadcrumb = () => {
        const breadcrumbs: Record<string, [JSX.Element, string]> = {
            monitoring: [<IconBarLineChart key={1} className="stroke-gray-500"/>, 'Monitoring'],
            warranties: [<IconCertificate key={1} className="stroke-gray-500"/>, 'Warranties'],
            'warranties-by-contractors': [<IconList key={1} className="stroke-gray-500"/>, 'Warranties by Contractor']
        }

        const [icon, label] = type ? breadcrumbs[type] : breadcrumbs.warranties

        return [
            icon,
            <Link key={2} to={`/${type}`}>{label}</Link>,
            <Link key={3} to={`/${type}/${warranty.id}/${tab}`}>Homeowner Details - Monitoring Tab</Link>,
            'Energy Graph'
        ]
    }

    return <AuthLayout
        heading={<WarrantyHeader
            warranty={warranty}
            backUrl={`/${type}/${warranty.id}/${tab}`}
        />}
        breadcrumb={getBreadcrumb()}
    >
        <WarrantyNavigation warranty={warranty} onChange={fetchWarranty}/>

        <div className="grid lg:grid-cols-1 grid-rows-2 gap-6 mb-6">
            <Card className="col-span-1 lg:row-span-2">
                <h2 className="card-title mb-6">
                    <div className="flex gap-5 items-center">
                        <Button design="btn-secondary" href={`/${type}/${warranty.id}/monitoring`}>
                            <IconFlipBackward className="stroke-orange-900"/>
                        </Button>

                        <h2 className="text-xl">Energy Graph</h2>
                    </div>
                </h2>

                <p className="text-gray-500 text-xs mb-4">Last updated at {warranty.homeowner?.monitoring_data?.energy_updated_at
                    ? warranty.homeowner?.monitoring_data?.energy_updated_at.format({
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZoneName: 'short',
                        hour12: false
                    })
                    : '-'}
                </p>

                <div className="flex justify-between">
                    <div className="flex">
                        <Badge className="items-center bg-warning-100 flex border border-warning-400 text-gray-500">Past 7 days</Badge>
                    </div>
                    <div className="flex">
                        <Link to={`/${type}/${warranty.id}/monitoring/energy-graph/#line`} onClick={() => setGraphType('line')} className={`flex border-r-0 rounded-l-lg border px-4 py-1 items-center justify-center border-gray-300 text-gray-700 ${graphType === 'line' ? 'bg-orange-100' : 'bg-white'}`}>
                            <IconLineChartUp01 size="sm" className="stroke-black"/>
                        </Link>
                        <Link to={`/${type}/${warranty.id}/monitoring/energy-graph/#bar`} onClick={() => setGraphType('bar')} className={`flex rounded-r-lg border px-4 py-1 items-center justify-center border-gray-300 text-gray-700 ${graphType === 'bar' ? 'bg-orange-100' : 'bg-white'}`}>
                            <IconBarChart07 size="sm" className="stroke-black"/>
                        </Link>
                    </div>
                </div>

                <div className="mt-8 flex gap-16">
                    <div className="">
                        <p className="text-gray-500 text-sm">Maximum Produced</p>
                        <h3 className="text-gray-900 text-3xl font-semibold mb-5 mt-5">
                            {(warranty.homeowner?.system?.production?.past_seven_days?.max || 0)
                                .format({ minimumFractionDigits: 3 })} kW
                        </h3>
                    </div>
                    <div className="grid-rows-1 p-1 text-center">
                        <p className="text-gray-500 text-sm">Maximum Consumed</p>
                        <h3 className="text-gray-900 text-3xl font-semibold mb-5 mt-5">
                            {(warranty.homeowner?.system?.consumption?.past_seven_days?.max || 0)
                                .format({ minimumFractionDigits: 3 })} kW
                        </h3>
                    </div>
                </div>

                <div className="h-[340px]">
                    {graphType === 'line' && <LineChart
                        datasets={getLineDatasets()}
                        labels={getLabels()}
                        options={getOptions()}
                        plugins={[
                            {
                                beforeDraw: (chart: ChartJs) => {
                                    if (!chart.tooltip?.opacity) return
                                    const { ctx, chartArea: { bottom, top } } = chart
                                    ctx.save()
                                    ctx.lineWidth = 2
                                    ctx.beginPath()
                                    ctx.moveTo(chart.tooltip?.caretX || 0, bottom)
                                    ctx.lineTo(chart.tooltip?.caretX || 0, top)
                                    ctx.strokeStyle = '#d5d3d0'

                                    // ctx.arc(chart.tooltip?.caretX, chart.tooltip?.caretY, 4, 0, 2 * Math.PI)
                                    // ctx.arc(chart.tooltip?.caretX, chart.tooltip?.caretY, 12, 0, 2 * Math.PI)
                                    // ctx.arc(chart.tooltip?.caretX, chart.tooltip?.caretY, 20, 0, 2 * Math.PI)
                                    ctx.stroke()
                                }
                            }
                        ]}
                    />}
                    {graphType === 'bar' && <BarChart
                        datasets={getBarDatasets()}
                        labels={getLabels()}

                        options={getOptions()}
                    />}
                </div>

                <div className="flex justify-center mt-3 gap-3">
                    <div className="flex items-center text-gray-500 text-xs">
                        <div className="rounded-full w-2 h-2 bg-orange-500 mr-2"/>
                        Power Produced
                    </div>
                    <div className="flex items-center text-gray-500 text-xs">
                        <div className="rounded-full w-2 h-2 bg-primary-500 mr-2"/>
                        Consumed
                    </div>
                </div>
            </Card>
        </div>
    </AuthLayout>
}

export default Graphs
