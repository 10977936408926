import { useMemo, useState } from 'react'

import { Select, DatePicker, SelectOptionProps, FormChangeEvent, DateRange } from '@/components'
import { FiltersType, GlobalIndex, GlobalIndexChangeEvent, GlobalIndexDataType } from '@/containers'
import { useNavigateWithQuery, useQuery } from '@/hooks'
import { SystemHistory, SystemStatus, SystemStatusCategory, Warranty } from '@/models'

interface SystemHistoryIndexData extends GlobalIndexDataType {
    rows: SystemHistory[]
}

interface SystemHistoryIndexFilters extends FiltersType {
    status: string
    start_date: Date | null
    end_date: Date | null
}

const SystemHistoryIndex = ({ warranty }: { warranty: Warranty }) => {
    const query = useQuery()
    const navigateWithQuery = useNavigateWithQuery()
    const getDefaultFilters = () => ({
        status: query.status || '',
        // Adding ' 00:00' to the end of string date to clear timezone
        start_date: query.start_date ? new Date(`${query.start_date} 00:00`) : null,
        end_date: query.end_date ? new Date(`${query.end_date} 00:00`) : null
    })
    const [filters, setFilters] = useState<SystemHistoryIndexFilters>(getDefaultFilters())

    const [data, setData] = useState<SystemHistoryIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleFiltersChange = (e: FormChangeEvent) => {
        if (e.target.name === 'range') {
            const value = e.target.value as DateRange
            navigateWithQuery({ page: null, start_date: value.start?.toISODate(), end_date: value.end?.toISODate() })
            setFilters({ ...filters, start_date: value.start as Date, end_date: value.end as Date })
        } else {
            setFilters({ ...filters, [e.target.name]: e.target.value as string })
            navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        }
    }

    const handleChange = (e: GlobalIndexChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }
    const statusOptions = useMemo(() => [
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc, item, index) =>
            [
                ...acc,
                {
                    value: item.value,
                    title: <span className="font-semibold w-full">
                        {item.title}
                    </span>,
                    group: index > 0
                },
                ...item.tiers.reduce((acc, item, _, all) =>
                    [
                        ...acc,
                        ...all.length > 1 ? [{
                            value: item.value,
                            title: <span className="font-semibold w-full text-sm">
                                • {item.title}
                            </span>
                        }] : [],
                        ...item.statuses.map((item: SystemStatus) => ({
                            value: item.value,
                            title: <div className="flex w-full gap-2 items-center font-body text-gray-900 text-sm">
                                {item.iconBadge('xs')} {item.title}
                            </div>
                        }))
                    ], [] as SelectOptionProps[])
            ], [] as SelectOptionProps[])
    ], [] as SelectOptionProps[])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        status: <div className="flex items-center gap-3">
            {item.status.iconBadge()}
            {item.status.title}
        </div>,
        status_raw: item.status.value,
        duration_seconds: (() => {
            const timeUnits: any = {
                year: 31536000,
                month: 2592000,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1
            }

            for (const unit in timeUnits) {
                if (item.duration_seconds >= timeUnits[unit]) {
                    const value = Math.floor(item.duration_seconds / timeUnits[unit])
                    return `${value} ${unit.pluralize(value)}`
                }
            }

            return '0 seconds'
        })(),
        detection_start_date: item.detection_start_date?.format({
            hour: '2-digit',
            minute: '2-digit'
        }) || '',
        detection_end_date: item.detection_end_date?.format({
            hour: '2-digit',
            minute: '2-digit'
        }) || ''
    })), [data.rows])

    return <>
        <GlobalIndex
            id="system-history"
            selectable={false}
            searchable={false}
            columns={[
                { field: 'status', title: 'System Status' },
                { field: 'duration_seconds', title: 'Status Duration' },
                { field: 'detection_start_date', title: 'Detection Start Date' },
                { field: 'detection_end_date', title: 'Detection End Date' }
            ]}
            rows={rowsMemo}
            data={data}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            api={(params, options) => SystemHistory.index({ id: warranty.homeowner.id, ...params }, options)}
            dataType="Events"
            onChange={handleChange}
            leftSide={<>
                <DatePicker
                    clearable
                    id="date-range"
                    name="range"
                    placeholder="Select dates"
                    value={{
                        start: filters.start_date,
                        end: filters.end_date
                    }}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                />

                <Select
                    name="status"
                    id="status"
                    options={statusOptions}
                    value={filters.status}
                    onChange={handleFiltersChange}
                />
            </>}
        />

        <p className="italic text-sm text-gray-500 mt-6">
            History collection started on {new Date('02/29/2024').format()}
        </p>
    </>
}

export default SystemHistoryIndex
