import { Model } from './Model'
import { api, Options, Params, Payload } from '@/services'
import { IdType, ProductDataType, ProductTypeType, ProductPropertyValueType } from '@/types'

export class Product extends Model {
    static endpoint = 'api/v1/products'

    raw: ProductDataType

    properties: ProductPropertyValueType[]

    model_name: string

    provider: string

    pdf_template: string

    pdf_template_id: IdType

    product_type_id: IdType

    type?: ProductTypeType

    constructor(data: ProductDataType) {
        super(data)
        this.raw = data
        this.model_name = data.model_name
        this.provider = data.provider
        this.properties = data.properties || []
        this.pdf_template = data.pdf_template
        this.pdf_template_id = data.pdf_template_id
        this.product_type_id = data.product_type_id
        this.type = data.type
    }

    update = (payload: Payload, params?: Params, options?: Options) => {
        if (payload instanceof FormData) {
            payload.append('_method', 'PATCH')
            payload.append('id', this.id.toString())
            return api.http.post(Product.endpoint, payload, params, options)
        }
        return api.http.put(Product.endpoint, { id: this.id, ...payload }, params, options)
    }

    static onlyBatteries = async (params?: Params) => {
        const res = await api.products.batteries(params)
        return res.data.map((item: ProductDataType) => new Product(item))
    }

    static indexTypes = () => api.products.indexTypes()
}
