import { ReactElement } from 'react'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'

import {
    ActivitiesIndex,
    ClaimsCreate,
    CompaniesCreate,
    CompaniesEdit,
    CompaniesIndex,
    CompaniesShow,
    Contact,
    ContractorsIndex,
    Dashboard,
    Error404,
    ForgotPassword,
    HomeownerShow,
    InvoicesIndex,
    InvoicesShow,
    Login,
    Logout,
    MonitoringGraphs,
    MonitoringIndex,
    Privacy,
    ProductsCreate,
    ProductsEdit,
    ProductsIndex,
    Profile,
    ResetPassword,
    Resources,
    SignUp,
    SupportIndex,
    SupportShow,
    TermsOfConditions,
    UploadAffiliatesData,
    UsersCreate,
    UsersEdit,
    UsersIndex,
    UsersShow,
    WarrantiesByContractorsIndex,
    WarrantiesCreate,
    WarrantiesEdit,
    WarrantiesIndex,
    WarrantiesUpload
} from '@/containers'
import { WarrantyRouteTabParamType, WarrantyRouteTypeParamType } from '@/containers/homeowners/Show'
import { Article, AuthUser, Company, Invoice, Product, User, Warranty } from '@/models'
import { cookies, feature } from '@/services'
import store, { login } from '@/store'

export type RouteType = {
    name: string
    path: string
    element: ReactElement
    title: string
    isPrivate?: boolean
    isPublic?: boolean
    showIf?: boolean | ((user: User | null) => boolean)
    loader?: (e: LoaderFunctionArgs) => any
}

export default [
    {
        name: 'home',
        path: '/',
        title: 'Home',
        element: null,
        loader: () => {
            const { user } = store.getState().auth
            return user?.isAuthenticated ? redirect('/dashboard') : redirect('/login')
        }
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        element: <Dashboard/>,
        title: 'Dashboard',
        isPrivate: true,
        showIf: true
    },
    {
        name: 'login',
        path: '/login',
        element: <Login/>,
        title: 'Login',
        isPublic: true
    },
    {
        name: 'logout',
        path: '/logout',
        element: <Logout/>,
        title: 'Logout',
        isPublic: false
    },
    {
        name: 'forgot.password',
        path: '/forgot-password',
        element: <ForgotPassword/>,
        title: 'Forgot Password',
        isPublic: true
    },
    {
        name: 'reset.password',
        path: '/reset-password',
        element: <ResetPassword/>,
        title: 'Reset Password',
        isPublic: true
    },
    {
        name: 'reset.password.token',
        path: '/reset-password/:token',
        element: <ResetPassword/>,
        title: 'Reset Password',
        isPublic: true
    },
    {
        name: 'profile',
        path: '/profile/:tab?/:provider?',
        element: <Profile/>,
        title: 'Profile',
        isPrivate: true,
        showIf: true,
        loader: async () => {
            const user = await AuthUser.currentUser()
            await store.dispatch(login({ user, apiToken: cookies.get('api_token') }))
            return null
        }
    },
    {
        name: 'companies',
        path: '/companies',
        element: <CompaniesIndex/>,
        title: 'Companies',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'companies.show',
        path: '/companies/:id',
        element: <CompaniesShow/>,
        title: 'Company Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string } }) => {
            const company = await Company.show(params.id)
            if (company) company.activity = await company.activities()
            return company
        }
    },
    {
        name: 'companies.subsidiaries.show',
        path: '/companies/:affiliateId/subsidiaries/:id',
        element: <CompaniesShow/>,
        title: 'Company Subsidiary Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string, affiliateId: string } }) => {
            const affiliate = await Company.show(params.affiliateId)
            const subsidiary = await Company.show(params.id)
            if (subsidiary) subsidiary.activity = await subsidiary.activities()
            if (!affiliate?.isAffiliate) return redirect('/dashboard')
            return subsidiary
        }
    },
    {
        name: 'companies.create',
        path: '/companies/create',
        element: <CompaniesCreate/>,
        title: 'Create a New Company',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff
    },
    {
        name: 'companies.edit',
        path: '/companies/:id/edit/:tab?/:provider?',
        element: <CompaniesEdit/>,
        title: 'Edit Company',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: ({ params }: { params: { id: string } }) => Company.show(params.id)
    },
    {
        name: 'companies.subsidiaries.edit',
        path: '/companies/:affiliateId/subsidiaries/:id/edit/:tab?/:provider?',
        element: <CompaniesEdit/>,
        title: 'Edit Company Subsidiary',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: async ({ params }: { params: { id: string, affiliateId: string } }) => {
            const affiliate = await Company.show(params.affiliateId)
            if (!affiliate?.isAffiliate) return redirect('/dashboard')
            return Company.show(params.id)
        }
    },
    {
        name: 'users',
        path: '/users',
        element: <UsersIndex/>,
        title: 'Users',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'users.show',
        path: '/users/:id',
        element: <UsersShow/>,
        title: 'User Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string } }) => {
            const user = await User.show(params.id)
            if (user) user.activity = await user.activities()
            return user
        }
    },
    {
        name: 'users.create',
        path: '/users/create',
        element: <UsersCreate/>,
        title: 'Create a New Company',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff
    },
    {
        name: 'companies.users.show',
        path: '/companies/:companyId/users/:id',
        element: <UsersShow/>,
        title: 'Company User Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string } }) => {
            const user = await User.show(params.id)
            if (user) user.activity = await user.activities()
            return user
        }
    },
    {
        name: 'companies.subsidiaries.users.show',
        path: '/companies/:affiliateId/subsidiaries/:companyId/users/:id',
        element: <UsersShow/>,
        title: 'Affiliate Subsidiary User Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string, affiliateId: string } }) => {
            const affiliate = await Company.show(params.affiliateId)
            if (!affiliate?.isAffiliate) return redirect('/dashboard')
            const user = await User.show(params.id)
            if (user) user.activity = await user.activities()
            return user
        }
    },
    {
        name: 'users.edit',
        path: '/users/:id/edit/:tab?/:provider?',
        element: <UsersEdit/>,
        title: 'Edit User',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: async ({ params }: { params: { id: string } }) => {
            const user = await User.show(params.id)
            const company = user?.isAffiliateOrContractor && user?.company?.id
                ? await Company.show(user.company.id)
                : null
            return { user, company }
        }
    },
    {
        name: 'companies.users.edit',
        path: '/companies/:companyId/users/:id/edit/:tab?/:provider?',
        element: <UsersEdit/>,
        title: 'Edit Company User',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: async ({ params }: { params: { id: string } }) => {
            const user = await User.show(params.id)
            const company = user?.isAffiliateOrContractor && user?.company?.id
                ? await Company.show(user.company.id)
                : null
            return { user, company }
        }
    },
    {
        name: 'affiliates.subsidiaries.users.edit',
        path: '/companies/:affiliateId/subsidiaries/:companyId/users/:id/edit/:tab?/:provider?',
        element: <UsersEdit/>,
        title: 'Edit Affiliate Company User',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: async ({ params }: { params: { id: string, affiliateId: string } }) => {
            const affiliate = await Company.show(params.affiliateId)
            if (!affiliate?.isAffiliate) return redirect('/dashboard')
            const user = await User.show(params.id)
            const company = user?.isAffiliateOrContractor && user?.company?.id
                ? await Company.show(user.company.id)
                : null
            return { user, company }
        }
    },
    {
        name: 'contractors',
        path: '/contractors',
        element: <ContractorsIndex/>,
        title: 'Contractors',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAffiliate
    },
    {
        name: 'contractors.show',
        path: '/contractors/:id',
        element: <CompaniesShow/>,
        title: 'Company Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAffiliate,
        loader: async ({ params }: { params: { id: string } }) => {
            const company = await Company.show(params.id)
            if (company) company.activity = await company.activities()
            return company
        }
    },
    {
        name: 'invoices',
        path: '/invoices',
        element: <InvoicesIndex/>,
        title: 'Invoices',
        isPrivate: true,
        showIf: (user: User | null) => user?.isContractor || user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'invoices.show',
        path: '/invoices/:id/:date',
        element: <InvoicesShow/>,
        title: 'Invoice Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isContractor || user?.isAdminOrStaffOrAdvisor,
        loader: async ({ params }: { params: { id: string, date: string } }) => {
            const { user } = store.getState().auth
            const invoice = await Invoice.show(`${params.id}/${params.date}`)
            let company
            if (user?.isContractor) {
                ({ company } = user)
            } else if (user?.isAffiliate && invoice?.homeowners.length) {
                company = await invoice.homeowners[0].company
            } else if (user?.isAdminOrStaffOrAdvisor) {
                company = await Company.show(params.id)
            }
            return { invoice, company }
        }
    },
    {
        name: 'activities',
        path: '/activities',
        element: <ActivitiesIndex/>,
        title: 'Activities',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'activities.show',
        path: '/activities/:id',
        element: <ActivitiesIndex/>,
        title: 'Activity Details',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'products',
        path: '/products',
        element: <ProductsIndex/>,
        title: 'Products',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'products.create',
        path: '/products/create',
        element: <ProductsCreate/>,
        title: 'Create a Product',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff
    },
    {
        name: 'products.edit',
        path: '/products/:id/edit',
        element: <ProductsEdit/>,
        title: 'Edit Product',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff,
        loader: ({ params }: { params: { id: string } }) => Product.show(params.id)
    },
    {
        name: 'sign-up',
        path: '/sign-up',
        element: <SignUp/>,
        title: 'Sign Up',
        isPublic: true,
        showIf: feature('onboarding')
    },
    {
        name: 'sign-up.step',
        path: '/sign-up/:step',
        element: <SignUp/>,
        title: 'Sign Up',
        isPublic: true,
        showIf: feature('onboarding')
    },
    {
        name: 'support',
        path: '/support',
        element: <SupportIndex/>,
        title: 'Support',
        isPrivate: true,
        showIf: true
    },
    {
        name: 'support.contact',
        path: '/support/contact',
        element: <Contact/>,
        title: 'Contact Us',
        isPrivate: true,
        showIf: true
    },
    {
        name: 'support.resources',
        path: '/support/resources',
        element: <Resources/>,
        title: 'Resources',
        isPrivate: true,
        showIf: true
    },
    {
        name: 'support.resources.show',
        path: '/support/resources/:id',
        element: <Resources/>,
        title: 'Resources Show',
        isPrivate: true,
        showIf: true
    },
    {
        name: 'support.show',
        path: '/support/:code',
        element: <SupportShow/>,
        title: 'Support Show',
        isPrivate: true,
        showIf: true,
        loader: ({ params }) => Article.index({ category: params.code })
    },
    {
        name: 'support.show',
        path: '/support/:code/:id',
        element: <SupportShow/>,
        title: 'Support Show',
        isPrivate: true,
        showIf: true,
        loader: ({ params }) => Article.index({ category: params.code })
    },
    {
        name: 'claims.create',
        path: '/claims/create',
        element: <ClaimsCreate/>,
        title: 'Claims Create',
        isPrivate: true,
        showIf: (user: User | null) => feature('claims') && user?.isContractor
    },
    {
        name: 'terms-of-service',
        path: '/terms-of-service',
        element: <TermsOfConditions/>,
        title: 'SolarInsure',
        isPublic: true
    },
    {
        name: 'privacy-policy',
        path: '/privacy-policy',
        element: <Privacy/>,
        title: 'SolarInsure',
        isPublic: true
    },
    {
        name: 'monitoring',
        path: '/monitoring',
        element: <MonitoringIndex/>,
        title: 'Monitoring',
        isPrivate: true,
        showIf: (user: User | null) => !user?.isSoftwareAffiliate
    },
    {
        name: 'warranties',
        path: '/warranties',
        element: <WarrantiesIndex/>,
        title: 'Warranties',
        isPrivate: true,
        showIf: (user: User | null) => user?.isContractor || user?.isAdminOrStaffOrAdvisor
    },
    {
        name: 'warranties.contractors',
        path: '/warranties-by-contractors',
        element: <WarrantiesByContractorsIndex/>,
        title: 'Warranties by Contractors',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaffOrAdvisor || user?.isAffiliate
    },
    {
        name: 'warranties.contractors-upload',
        path: '/warranties-by-contractors/upload-affiliates-data',
        element: <UploadAffiliatesData/>,
        title: 'Upload Affiliate Data',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff
    },
    {
        name: 'warranties.create',
        path: '/:type/create',
        element: <WarrantiesCreate/>,
        title: 'Warranty Create',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff ||
            (user?.isContractor && user.isAllowedToCreateWarranty),
        loader: ({ params }: { params: { type: string } }) => {
            const { user } = store.getState().auth
            if (
                !['warranties', 'warranties-by-contractors'].includes(params.type) ||
                user?.company.account_activity_status.isDisabled
            ) {
                return redirect('/dashboard')
            }
            return null
        }
    },
    {
        name: 'warranties.upload',
        path: '/warranties/upload',
        element: <WarrantiesUpload/>,
        title: 'Warranty Upload',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff ||
            (user?.isContractor && user.isAllowedToCreateWarranty),
        loader: ({ request }) => {
            const id = new URL(request.url).searchParams.get('user_id') || undefined
            const { user } = store.getState().auth
            if (id && user?.isAdminOrStaff) {
                return User.show(id)
            }
            return user?.company.account_activity_status.isDisabled
                ? redirect('/dashboard') : null
        }
    },
    {
        name: 'warranties.edit',
        path: '/:type/:id/:tab/edit',
        element: <WarrantiesEdit/>,
        title: 'Warranty Edit',
        isPrivate: true,
        showIf: (user: User | null) => user?.isAdminOrStaff || user?.isContractor,
        loader: async ({ params }: {
            params: { id: string, type: WarrantyRouteTypeParamType, tab: WarrantyRouteTabParamType }
        }) => {
            const warranty = await Warranty.show(params.id)
            const { user } = store.getState().auth
            if (
                user?.company?.account_activity_status.isDisabled ||
                (warranty?.status.isEditRequested && !user?.isAdminOrStaff) ||
                warranty?.status.isCanceled ||
                warranty?.status.isReinstatementRequested ||
                !['monitoring', 'warranties', 'warranties-by-contractors'].includes(params.type) ||
                !['warranty'].includes(params.tab)
            ) {
                return redirect('/dashboard')
            }
            return warranty
        }
    },
    {
        name: 'monitoring.energy-graph',
        path: '/:type/:id/:tab/energy-graph',
        element: <MonitoringGraphs/>,
        title: 'Monitoring Graphs',
        isPrivate: true,
        showIf: (user: User | null) => user?.isLenderAffiliate || user?.isAdminOrStaffOrAdvisor || user?.isContractor,
        loader: async ({ params }: {
            params: { id: string, type: WarrantyRouteTypeParamType, tab: WarrantyRouteTabParamType }
        }) => {
            const { user } = store.getState().auth
            if (
                !['monitoring', 'warranties', 'warranties-by-contractors'].includes(params.type) ||
                !['monitoring'].includes(params.tab) ||
                user?.isSoftwareAffiliate
            ) {
                return redirect('/dashboard')
            }
            return Warranty.show(params.id)
        }
    },
    {
        name: 'homeowners.show',
        path: '/:type/:id/:tab?',
        element: <HomeownerShow/>,
        title: 'Homeowner Details',
        isPrivate: true,
        showIf: () => true,
        loader: ({ params }: {
            params: { id: string, type: WarrantyRouteTypeParamType, tab: WarrantyRouteTabParamType }
        }) => {
            const { user } = store.getState().auth
            if (
                !['monitoring', 'warranties', 'warranties-by-contractors'].includes(params.type) ||
                ((params.type === 'monitoring' || params.tab === 'monitoring') && user?.isSoftwareAffiliate)
            ) {
                return redirect('/dashboard')
            }

            const activityMap = {
                warranty: 'warranty.show',
                monitoring: 'monitoring.show',
                'system-history': 'monitoring.system-history',
                devices: 'monitoring.device-details'
            }
            const activityTracker = activityMap[params.tab] || 'unknown'
            return Warranty.show(params.id, { activity_tracker: activityTracker })
        }
    },
    {
        name: '404',
        path: '*',
        element: <Error404/>,
        title: '404'
    }
] as RouteType[]
