import { createSearchParams, useLocation } from 'react-router-dom'

import { Button, DoughnutChart } from '@/components'
import { useAnalytics, useQuery } from '@/hooks'
import { SystemStatusCategory } from '@/models'
import { MonitoringSummaryDataType } from '@/types'

type MonitoringIssuesChartProps = {
    summary: MonitoringSummaryDataType
    type?: 'donut' | 'half-donut'
    className?: string
    onMouseOver?: any
    onFocus?: any
    label?: string
}

const MonitoringIssuesChart = ({
    summary,
    className = '',
    type = 'donut',
    label = 'Connected Sites',
    ...props
}: MonitoringIssuesChartProps) => {
    const { trackEvent } = useAnalytics()
    const location = useLocation()
    const query = useQuery()

    const getMonitoringIndexPage = (filters: any) => {
        if (location.pathname === '/monitoring') {
            return `/monitoring?${createSearchParams({ ...query, ...filters })}`
        }
        return `/monitoring?${createSearchParams(filters)}`
    }

    const tiers = SystemStatusCategory.connectedSitesCategory.secondarySortedTiers

    const trackClickEvent = (action: string, label: string) => {
        trackEvent(`click_${action}`, 'User Interaction', `${label} Click`)
    }

    return <div className={`${className} relative h-fit`} {...props}>
        <DoughnutChart
            datasets={[{
                data: [
                    summary.groups.normal_sites,
                    summary.groups.tier_1,
                    summary.groups.tier_2,
                    summary.groups.tier_3
                ],
                backgroundColor: ['#c5e6d6', '#ffb4a5', '#ffd8a2', '#fff37c'],
                hoverBackgroundColor: ['#98d3b8', '#ff7d6b', '#ffb953', '#ffe200'],
                borderColor: 'transparent',
                borderWidth: 0,
                ...type === 'donut'
                    ? {
                        cutout: 50,
                        radius: 70
                    }
                    : {
                        cutout: '70%',
                        circumference: 180,
                        rotation: -90
                    }
            }]}
            options={{
                aspectRatio: type === 'donut' ? 1 : 2,
                responsive: true,
                maintainAspectRatio: false
            }}
            customTooltip={index => <div className="px-3 py-2 font-body bg-white shadow-md rounded-md">
                <div className="hidden md:block absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-white rotate-45 origin-center"/>
                <h4 className="flex text-xs whitespace-nowrap leading-[18px]">
                    <span className="font-semibold">{tiers[index].title} </span>
                    {!!tiers[index].severity && '-'} {tiers[index].severity}
                </h4>
                <Button
                    href={getMonitoringIndexPage({ statuses: [tiers[index].value] })}
                    design="btn-link"
                    className="whitespace-nowrap"
                    onClick={() => trackClickEvent('fleet_monitoring_summary', `Fleet Monitoring Summary ${tiers[index].severity}`)}
                >
                    {summary.groups[tiers[index].value]?.format()} Sites
                </Button>
            </div>}
        />
        <div className={`w-20 rounded-full flex justify-center items-center flex-col absolute left-1/2 -translate-x-1/2 ${type === 'donut' ? '-translate-y-1/2 top-1/2' : 'bottom-0'}`}>
            <h4 className="text-2xl font-display font-semibold" data-test="monitoring-index-issues-count">
                {summary.groups.monitored?.format()}
            </h4>
            <p className="text-gray-500 font-body text-xs text-center leading-[18px]" data-test="monitoring-index-issues-subtitle">
                {label}
            </p>
        </div>
    </div>
}

export default MonitoringIssuesChart
