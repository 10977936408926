import { useEffect, useMemo, useState } from 'react'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import {
    Select,
    DatePicker,
    Image,
    PageHeader,
    WarrantyCreateButton,
    WarrantyIndexButtons,
    WarrantyTableHeading, RowProps, FormChangeEvent, DateRange
} from '@/components'
import {
    AuthLayout,
    FiltersType,
    GlobalIndex,
    GlobalIndexChangeEvent,
    GlobalIndexDataType,
    MetaType
} from '@/containers'
import { useAuth, useNavigateWithQuery, useQuery } from '@/hooks'
import { Company, Warranty, WarrantyProductType, WarrantyStatus } from '@/models'

interface WarrantyIndexMeta extends MetaType {
    warranty_status_list: string[]
}

export interface WarrantyIndexData extends GlobalIndexDataType {
    meta: WarrantyIndexMeta,
    rows: Warranty[]
    selectedRows: RowProps[] | 'all'
    excludedRows: RowProps[]
}

export interface WarrantyIndexFilters extends FiltersType {
    statuses: string[]
    type: string
    company_id: string
    start_date: Date | null
    end_date: Date | null
}

export const WarrantiesIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const auth = useAuth()
    const getDefaultFilters = () => ({
        statuses: query.statuses || WarrantyStatus.defaultStatuses,
        type: query.type || '',
        company_id: query.company_id || '',
        // Adding ' 00:00' to the end of string date to clear timezone
        start_date: query.start_date ? new Date(`${query.start_date} 00:00`) : null,
        end_date: query.end_date ? new Date(`${query.end_date} 00:00`) : null
    })

    const [filters, setFilters] = useState<WarrantyIndexFilters>(getDefaultFilters())
    const [reload, setReload] = useState(false)
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [data, setData] = useState<WarrantyIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0,
            warranty_status_list: []
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })

    useEffect(() => {
        setData(prevData => ({ ...prevData, selectedRows: [] }))
    }, [filters])

    const getAvailableStatuses = () => WarrantyStatus.all
        .filter(item => data.meta.warranty_status_list.includes(item.key))

    const fetchAffiliates = async () => {
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    useEffect(() => {
        if (auth.user?.isAdminOrStaffOrAdvisor) {
            fetchAffiliates()
        }
        if (auth.user?.isContractor && auth.user?.company?.affiliate?.isExternalUploadMatch) {
            setAffiliates([auth.user?.company?.affiliate])
        }
    }, [])

    const handleChange = (e: GlobalIndexChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }
    const handleFiltersChange = (e: FormChangeEvent) => {
        if (e.target.name === 'range') {
            const value = e.target.value as DateRange
            navigateWithQuery({ page: null, start_date: value.start?.toISODate(), end_date: value.end?.toISODate() })
            setFilters({ ...filters, start_date: value.start as Date, end_date: value.end as Date })
        } else {
            setFilters({ ...filters, [e.target.name]: e.target.value as string })
            navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        }
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        type: item.type.icons('xs', true),
        customer_info: <div className="flex flex-col items-start gap-1">
            {item.customer_info?.full_name}
            {item.status.badge()}
        </div>,
        'homeowner.created_at': item.submission_date?.format() || '',
        'homeowner.street_address': item.install_address ? <>
            <div className="text-gray-900">{item.install_address.street_address}</div>
            <div className="text-gray-500">{item.install_address.city}, {item.install_address.state} {item.install_address.zip}</div>
        </> : '',
        'warranties.size_w': Warranty.getField('size_kw').format(item.size_kw),
        'warranties.sent_at': item.sent_date?.format(),
        'homeowner.install_date': item.install_date?.format(),
        'warranties.policy_num': item.policy_num,
        city_state: item.install_address ? `${item.install_address.city}, ${item.install_address.state}` : '',
        _path: `/warranties/${item.id}/warranty`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Warranties"/>}>
        <GlobalIndex
            id="warranties"
            infinity
            data={data}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            maxColumnsAmount={4}
            api={(...args) => Warranty.index(...args)}
            selectable
            searchPlaceholder="Find Homeowners by Email, Address, Plan ID, System ID, or More"
            onChange={handleChange}
            onFiltersChange={filters => setFilters(filters)}
            dataType="Warranties"
            reload={reload}
            onReloadChange={setReload}
            columns={[
                { title: 'Type', field: 'type' },
                { title: 'Homeowner Name', field: 'customer_info' },
                { title: 'Submission Date', field: 'homeowner.created_at' },
                { title: 'Address', field: 'homeowner.street_address' },
                { title: 'Emailed Date', field: 'warranties.sent_at' },
                { title: 'Install Date', field: 'homeowner.install_date' },
                { title: 'System Size', field: 'warranties.size_w' },
                { title: 'Policy Number', field: 'warranties.policy_num' },
                { title: 'City, State', field: 'city_state' }
            ]}
            rows={rowsMemo}
            placeholder={data.meta.total === 0 && !auth.user?.isAdvisor && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        Any data from registered warranties will live here. Start registering Solar Insure warranties
                        today
                    </p>
                    <WarrantyCreateButton className="mt-6"/>
                </div>
            </>}
            leftSide={<>
                <DatePicker
                    clearable
                    id="date-range"
                    name="range"
                    placeholder="Date range"
                    value={{
                        start: filters.start_date,
                        end: filters.end_date
                    }}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                    data-test="warranties-index-date-range-filter"
                    className="w-full md:w-auto"
                />

                {(auth.user?.isAdminOrStaffOrAdvisor || auth.user?.company?.affiliate?.isExternalUploadMatch) &&
                    <Select
                        id="company-id"
                        name="company_id"
                        value={filters.company_id}
                        disabled={!affiliates.length}
                        options={[
                            { value: '', title: 'Any Affiliate' },
                            ...affiliates.map(item => ({
                                value: item.id,
                                title: item.name
                            }))
                        ]}
                        onChange={handleFiltersChange}
                        data-test="warranties-index-company-id-filter"
                    />}
                <Select
                    name="type"
                    id="warranty-type"
                    className="grow"
                    options={[
                        { value: '', title: 'Any Type' },
                        ...WarrantyProductType.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2">
                                <div className="w-12">{item.icons()}</div>
                                {item.title}
                            </div>
                        }))
                    ]}
                    value={filters.type}
                    onChange={handleFiltersChange}
                    data-test="warranties-index-type-filter"
                />
            </>}
            rightSide={<>
                <WarrantyIndexButtons
                    data={data}
                    filters={filters}
                    onChange={() => setReload(true)}
                    data-test="warranties-index"
                />
            </>}
            heading={<WarrantyTableHeading
                statuses={getAvailableStatuses()}
                onChange={handleFiltersChange}
                value={filters.statuses}
                data-test="warranties-index-heading"
            />}
        />
    </AuthLayout>
}
