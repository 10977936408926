import { useMemo, useState } from 'react'

import {
    Button,
    IconEdit05, PageHeader,
    Tooltip
} from '@/components'
import { AuthLayout, GlobalIndex, GlobalIndexChangeEvent, GlobalIndexDataType } from '@/containers'
import { useAuth } from '@/hooks'
import { Product } from '@/models'

interface ProductIndexData extends GlobalIndexDataType {
    rows: Product[]
}

const ProductsIndex = () => {
    const auth = useAuth()
    const [data, setData] = useState<ProductIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = (e: GlobalIndexChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        model_name: item.model_name,
        type: item.type?.name,
        pdf_template: item.pdf_template,
        actions: <div className="flex items-center gap-2">
            <Tooltip content="Edit Product">
                <Button design="btn-link" href={`/products/${item.id}/edit`}>
                    <IconEdit05 className="stroke-gray-300 hover:stroke-green-600"/>
                </Button>
            </Tooltip>
        </div>,
        _path: auth.user?.isAdminOrStaff ? `/products/${item.id}/edit` : ''
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Products"/>}>
        <GlobalIndex
            id="products"
            data={data}
            api={(...args) => Product.index(...args)}
            dataType="Products"
            onChange={handleChange}
            columns={[
                { field: 'model_name', title: 'Model Name' },
                { field: 'type', title: 'Type' },
                { field: 'pdf_template', title: 'PDF Template', sortable: false },
                { title: 'Actions', field: 'actions', clickable: false, sortable: false, showIf: auth.user?.isAdminOrStaff }
            ]}
            rows={rowsMemo}
            rightSide={<>
                {auth.user?.isAdminOrStaff && <Button href="/products/create">
                    New Product
                </Button>}
            </>}
        />
    </AuthLayout>
}

export default ProductsIndex
