import { Model } from './Model'
import { User } from './User'
import { Badge } from '@/components'
import { ActivityDescriptionEnum } from '@/enums'
import { ActivityDataType, ActivityPropertiesDataType, ActivitySubjectDataType } from '@/types'

export class Activity extends Model {
    static endpoint = 'api/v1/activities'

    raw: ActivityDataType

    description: ActivityDescriptionEnum

    causer?: User

    action: string

    properties: ActivityPropertiesDataType

    user_name: string

    subject_type: string

    subject?: ActivitySubjectDataType

    constructor(data: ActivityDataType) {
        super(data)
        this.raw = data
        this.description = data.description
        this.causer = data.causer ? new User(data.causer) : undefined
        this.action = data.action
        this.user_name = data['users.name']
        this.properties = data.properties
        this.subject_type = data.subject_type
        this.subject = data.subject
        this.created_at = Model.toDate(data['activity_logs.created_at'])
    }


    get actionBadge() {
        switch (this.description) {
            case ActivityDescriptionEnum.CREATED: {
                return <Badge className="bg-success-50 text-success-700">
                    Created
                </Badge>
            }
            case ActivityDescriptionEnum.UPDATED: {
                return <Badge className="bg-blue-50 text-blue-700">
                    Updated
                </Badge>
            }
            case ActivityDescriptionEnum.DELETED: {
                return <Badge className="bg-error-50 text-error-700">
                    Canceled
                </Badge>
            }
            case ActivityDescriptionEnum.RESTORED: {
                return <Badge className="bg-purple-50 text-purple-700">
                    Restored
                </Badge>
            }
        }
    }
}
