import { ReactNode } from 'react'

import { Model } from './Model'
import { NotificationType } from './NotificationType'
import { api, Options, Params } from '@/services'
import { JobsBatchType, NotificationDataType, NotificationMessageDataType } from '@/types'

export class Notification extends Model {
    static endpoint = 'api/v1/notifications'

    type: NotificationType

    data: NotificationMessageDataType

    read_at?: Date

    constructor(data: NotificationDataType) {
        super(data)
        this.type = NotificationType.find(data.key)
        this.data = data.data
        this.read_at = Model.toDate(data.read_at)
    }

    get message(): ReactNode {
        return this.type.message(this.data)
    }

    static async index<T extends typeof Model>(
        this: T,
        params: Params = {},
        options: Options = {}
    ): Promise<{ data: InstanceType<T>[], [key: string]: any }> {
        const res = await api.http.get(this.endpoint, params, options)
        res.data = res.data?.map((item: NotificationDataType) => new this(item)) || []
        for (const key in res.data) {
            const item = res.data[key]
            const jobs = item.data['jobs-batch'] as JobsBatchType
            if (jobs && jobs.percentage < 100) {
                const { data } = await api.jobs.progress(jobs.id)
                res.data[key].data['jobs-batch'] = data
            }
        }
        res.data.sort((a: Notification, b: Notification) => {
            const jobsA = a.data['jobs-batch'] as JobsBatchType
            const jobsB = b.data['jobs-batch'] as JobsBatchType
            if (jobsA && jobsA.percentage < 100) {
                return -1
            }
            if (jobsB && jobsB.percentage < 100) {
                return 1
            }
            return 0
        })
        return res
    }

    markAsRead() {
        return Notification.show(this.id, { 'mark-as-read': true })
    }

    static destroy(params?: Params, options?: Options) {
        return api.http.delete(`${this.endpoint}`, params, options)
    }

    static markAllAsRead() {
        return this.index({ 'mark-as-read': true })
    }
}
