import { ReactElement } from 'react'

import { Tooltip } from '@/components'
import { IconProps, IconSize, BadgeSizes } from '@/components/icons/Icon'
import { BATTERY_ELIGIBILITY_TYPES, BatteryEligibilityTypeEnum } from '@/enums'
import { CompanyBatteryEligibilityColorType, CompanyBatteryEligibilityDataType } from '@/types'

const COLOR_MAP = {
    gray: 'bg-gray-100 stroke-gray-600',
    purple: 'bg-purple-100 stroke-purple-600',
    success: 'bg-success-100 stroke-success-600'
}

export class CompanyBatteryEligibility {
    key: BatteryEligibilityTypeEnum

    title: string

    secondaryTitle?: string

    subtitle?: string

    color: CompanyBatteryEligibilityColorType

    IconComponent: (props: IconProps) => ReactElement

    constructor(data: CompanyBatteryEligibilityDataType) {
        this.key = data.key
        this.title = data.title
        this.secondaryTitle = data.secondaryTitle
        this.subtitle = data.subtitle
        this.color = data.color
        this.IconComponent = data.IconComponent
    }

    static get all() {
        return BATTERY_ELIGIBILITY_TYPES.map(item => new CompanyBatteryEligibility(item))
    }

    static find(key: BatteryEligibilityTypeEnum) {
        return CompanyBatteryEligibility.all.find(item => item.key === key) ||
            CompanyBatteryEligibility.defaultType
    }

    static get defaultType() {
        return CompanyBatteryEligibility.all
            .find(item => item.key === BatteryEligibilityTypeEnum.NO_BATTERY) as CompanyBatteryEligibility
    }

    static get noBattery() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.NO_BATTERY) as CompanyBatteryEligibility
    }

    static get battery20() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_20_YEAR) as CompanyBatteryEligibility
    }

    static get battery30() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_30_YEAR) as CompanyBatteryEligibility
    }

    icon(size: IconSize = 'xs') {
        const Icon = this.IconComponent
        return <Tooltip
            content={<div className="flex flex-col">{this.title}: <span className="italic">{this.subtitle}</span></div>}
            className={`rounded-full flex justify-center border border-white items-center ${COLOR_MAP[this.color]} ${BadgeSizes[size]}`}
        >
            <Icon size={size}/>
        </Tooltip>
    }
}
