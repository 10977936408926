import { useEffect, useState } from 'react'

import { FormChangeEvent, FormChangeEventHandler, FormDataType, FormErrorsType, Select } from '@/components'
import { Company, Warranty } from '@/models'
import { IdType } from '@/types'

export interface AffiliatesFormDataType extends FormDataType {
    affiliate_name: string
    affiliate_id: IdType
}

type AffiliatesFormProps = {
    form: AffiliatesFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    warranty: Warranty
}
export const AffiliatesForm = ({ warranty, form, errors, ...props }: AffiliatesFormProps) => {
    const [affiliates, setAffiliates] = useState<Company[]>([])

    const fetchAffiliates = async () => {
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    const handleChange = (e: FormChangeEvent) => {
        props.onChange(e)
        props.onChange({
            target: {
                name: 'affiliate_name',
                value: affiliates.find(affiliate => affiliate.id === e.target.value)?.name || ''
            }
        })
    }

    useEffect(() => {
        fetchAffiliates()
    }, [])

    return <>
        <Select
            id="conglomerate-id"
            name="affiliate_id"
            label="Assign Affiliate"
            disabled={!!warranty.affiliate}
            options={[
                {
                    value: '',
                    title: 'No Affiliate Association'
                },
                ...affiliates.filter((item: Company) => warranty.affiliate?.isFullSubcontractorMatch
                    ? true
                    : item.isExternalUploadMatch)
                    .map((item: Company) => ({
                        value: item.id,
                        title: item.name
                    }))
            ]}
            value={form.affiliate_id}
            errors={errors.affiliate_id}
            onChange={handleChange}
        />
    </>
}
