import { Model } from './Model'
import { ProductionConsumptionDataType, MonitoringDataDataType } from '@/types'

export type PeriodType = {
    max: number
    sum: number
}

export type ConsumptionType = {
    end_date?: Date
    past_seven_days: PeriodType
    month_to_date: PeriodType
    life_time: PeriodType
    values: number[]
    today?: PeriodType;
    yesterday?: PeriodType;
    last_month?: PeriodType;
    year_to_date?: PeriodType;
}

export type ProductionType = {
    end_date?: Date
    past_seven_days: PeriodType
    month_to_date: PeriodType
    life_time: PeriodType
    values: number[]
    today?: PeriodType;
    yesterday?: PeriodType;
    last_month?: PeriodType;
    year_to_date?: PeriodType;
}

export class MonitoringData extends Model {
    raw: MonitoringDataDataType

    energy_updated_at?: Date

    errors: {
        message: string | string[]
    }

    system: {
        production: ProductionType
        consumption: ConsumptionType
        summary: {
            current_power_production_w: number
            pto_date?: Date
        }
    }

    constructor(data: MonitoringDataDataType) {
        super(data)
        this.raw = data
        this.errors = data.errors
        this.system = {
            production: this.parseProductionOrConsumption(data.system.production),
            consumption: this.parseProductionOrConsumption(data.system.consumption),
            summary: {
                current_power_production_w: Model.toNumber(data.system.summary.current_power_production_w, 0) as number,
                pto_date: Model.toDate(data.system.summary.pto_date)
            }
        }
        this.energy_updated_at = Model.toDate(data.energy_updated_at)
    }

    parseProductionOrConsumption(data: ProductionConsumptionDataType) {
        return {
            end_date: Model.toDate(data?.end_date),
            past_seven_days: {
                max: Model.toNumber(data?.past_seven_days?.max, 0) as number,
                sum: Model.toNumber(data?.past_seven_days?.sum, 0) as number
            },
            month_to_date: {
                max: Model.toNumber(data?.month_to_date?.max, 0) as number,
                sum: Model.toNumber(data?.month_to_date?.sum, 0) as number
            },
            life_time: {
                max: Model.toNumber(data?.life_time?.max, 0) as number,
                sum: Model.toNumber(data?.life_time?.sum, 0) as number
            },
            values: data?.values?.map ? data?.values?.map(item => Model.toNumber(item, 0) as number) : []
        }
    }
}
