import { useState } from 'react'

import {
    Badge,
    Button,
    Form,
    FormChangeEvent,
    IconCheckCircleBroken,
    IconClose,
    Switch,
    Table,
    Tooltip
} from '@/components'
import { Warranty } from '@/models'
import { loadingBar } from '@/services'

type UploadFileStepProps = {
    form: any
    onCancel: any
    onNext: any
    onChange: any
}

const ValidateDataStep = ({ form, ...props }: UploadFileStepProps) => {
    const [processing, setProcessing] = useState(false)
    const [showOnlyErrors, setShowOnlyErrors] = useState(true)

    const getRowsWithErrors = () => form.response
        .filter((item: any) => item.errors.policy_num || item.errors.company_unique_id)

    const handleValidate = async () => {
        setProcessing(true)
        loadingBar.start()
        try {
            const res = await Warranty.validateMatch(form.payload)
            props.onChange({ target: { name: 'response', value: res.data } })
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const handleSubmit = async () => {
        if (getRowsWithErrors().length) {
            handleValidate()
            return
        }
        setProcessing(true)
        loadingBar.start()
        try {
            await Warranty.match(form.payload)
            props.onNext()
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        const clone = [...form.payload]
        clone[parseInt(e.target.dataset?.index as string)][e.target.name] = e.target.value
        props.onChange({ target: { name: 'payload', value: clone } })
    }

    const handleDeleteRow = (index: number) => {
        const payloadClone = [...form.payload]
        payloadClone.splice(index, 1)
        props.onChange({ target: { name: 'payload', value: payloadClone } })
        const responseClone = [...form.response]
        responseClone.splice(index, 1)
        props.onChange({ target: { name: 'response', value: responseClone } })
    }

    return <Form onSubmit={handleSubmit}>
        <h4 className="text-primary-700 pt-4 mb-2">Validate and Upload Data</h4>
        <div className="mb-8">
            {getRowsWithErrors().length
                ? <>
                    <p className="mb-4 text-sm">
                        Our system checks the values of each row for any errors. To edit the data in a cell, click on
                        the cell, make your changes, and then press Enter or click on any other cell to apply the
                        modifications.
                    </p>
                    <p className="mb-4 text-sm text-error-800">Resolve all errors before uploading the spreadsheet.</p>

                    <Badge className="text-gray-700 bg-error-50">
                        {getRowsWithErrors().length} rows contain errors
                    </Badge>
                </>
                : <>
                    {showOnlyErrors &&
                        <p className="mb-4 text-sm">All good! You have no errors and are ready to upload the data.</p>}

                    <Badge className="text-gray-700 bg-primary-50">
                        {form.payload.length} warranties will be associated with {form.affiliate.name} Affiliate
                    </Badge>
                </>}
        </div>

        <Switch
            label="Only Show Rows with Errors"
            input-size="sm"
            className="mb-4"
            checked={showOnlyErrors}
            onChange={(e: FormChangeEvent) => setShowOnlyErrors(!!e.target.checked)}
        />

        {showOnlyErrors && !getRowsWithErrors().length
            ? <div className="border border-gray-200 rounded-sm flex justify-center flex-col items-center p-4 text-gray-900 text-sm">
                <div className="w-12 h-12 rounded-full bg-success-50 flex justify-center items-center mb-3">
                    <div className="w-9 h-9 rounded-full bg-success-100 flex justify-center items-center">
                        <IconCheckCircleBroken className="stroke-success-700"/>
                    </div>
                </div>

                <p className="font-semibold mb-1">No errors found</p>
                <p>All good! You have no errors and are ready to upload the data.</p>
            </div>
            : <Table
                className="stripped-table"
                searchable={false}
                sortable={false}
                showPerPage={false}
                useQueryParams={false}
                columns={[
                    {
                        title: '#',
                        field: 'number',
                        className: 'w-13.5',
                        cellClassName(row: any) {
                            return form.response[row.index]?.errors.company_unique_id ||
                            form.response[row.index]?.errors.policy_num ? 'bg-warning-50' : ''
                        }
                    },
                    {
                        title: 'Company ID',
                        field: 'company_unique_id',
                        cellClassName(row: any) {
                            return `!p-0 ${form.response[row.index]?.errors?.company_unique_id ? 'text-error-800' : ''}`
                        }
                    },
                    { title: 'Company', field: 'company_name', cellClassName: '!p-0' },
                    {
                        title: 'Policy Number',
                        field: 'policy_num',
                        cellClassName(row: any) {
                            return `!p-0 ${form.response[row.index]?.errors?.policy_num ? 'text-error-800' : ''}`
                        }
                    },
                    { title: 'First Name', field: 'first_name', cellClassName: '!p-0' },
                    { title: 'Last Name', field: 'last_name', cellClassName: '!p-0' },
                    { title: 'Street Address', field: 'street_address', cellClassName: '!p-0' },
                    { title: '', field: 'actions' }
                ]}
                rows={form.payload.map((item: any, index: number) => {
                    const errors = form.response[index]?.errors
                    return {
                        index,
                        id: item.key,
                        number: index + 1,
                        company_unique_id: <Tooltip
                            content={errors?.company_unique_id}
                            disabled={!errors?.company_unique_id}
                        >
                            <input
                                data-index={index}
                                name="company_unique_id"
                                value={item.company_unique_id}
                                onChange={handleChange}
                                onBlur={handleValidate}
                                className="bg-transparent px-6.5 py-6"
                            />
                        </Tooltip>,
                        company_name: item.company_name,
                        policy_num: <Tooltip
                            content={errors?.policy_num}
                            disabled={!errors?.policy_num}
                        >
                            <input
                                data-index={index}
                                name="policy_num"
                                value={item.policy_num}
                                onChange={handleChange}
                                onBlur={handleValidate}
                                className="bg-transparent px-6.5 py-6"
                            />
                        </Tooltip>,
                        first_name: item.first_name,
                        last_name: item.last_name,
                        street_address: item.street_address,
                        actions: <div className="flex items-center">
                            <Tooltip content="Remove the warranty row if needed before uploading the spreadsheet.">
                                <Button design="btn-link" type="button" onClick={() => handleDeleteRow(index)}>
                                    <IconClose className="stroke-gray-300 hover:stroke-error-600"/>
                                </Button>
                            </Tooltip>
                        </div>,
                        hasError: errors?.company_unique_id || errors?.policy_num
                    }
                }).filter((item: any) => showOnlyErrors ? item.hasError : true)}
            />}

        <div className="flex justify-end gap-3 mt-4">
            <Button processing={processing} design="btn-secondary-gray" onClick={props.onCancel} type="button">
                Previous
            </Button>

            <Button processing={processing} disabled={getRowsWithErrors().length}>
                Upload {form.payload.length} warranties
            </Button>
        </div>
    </Form>
}

export default ValidateDataStep
