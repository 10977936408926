import classnames from 'classnames'
import { CleaveOptions } from 'cleave.js/options'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
    InputHTMLAttributes,
    FocusEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
    KeyboardEvent as ReactKeyboardEvent
} from 'react'

import {
    Button,
    Dropdown,
    IconChevronDown,
    IconChevronUp,
    IconEye,
    IconEyeOff,
    FormControl,
    FormControlProps,
    DropdownOptions, FormChangeEventHandler, FormChangeEvent
} from '@/components'
import { useCleave } from '@/hooks'

export interface InputProps extends FormControlProps, InputHTMLAttributes<HTMLInputElement> {
    mask?: CleaveOptions
    trim?: boolean
    dropdownOptions?: DropdownOptions
    suggestions?: string[]
    onSuggestionSelect?: (index: number) => void
    onChange?: FormChangeEventHandler
}

export const Input = ({
    id,
    label,
    errors = [],
    warnings = [],
    hint,
    suggestions,
    preIcon,
    postIcon,
    mask,
    transparent,
    trim,
    tooltip,
    onSuggestionSelect,
    className,
    dropdownOptions,
    'input-size': size,
    'data-test': dataTest,
    ...props
}: InputProps) => {
    const [type, setType] = useState(props.type)
    const [open, setOpen] = useState(false)
    const [focused, setFocused] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const dropdownRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if (suggestions?.length && focused) {
            setOpen(true)
        }
    }, [suggestions])

    const handleChange = (e: FormChangeEvent) => {
        if (props.onChange) {
            if (e.currentTarget) {
                e.target = e.currentTarget
            }
            props.onChange(e)
        }
    }

    const inputRef = useCleave(mask, props.value as string, handleChange)

    const handleKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
        if (!suggestions?.length || !onSuggestionSelect) return
        const lastIndex = suggestions.length - 1
        if (e.code === 'ArrowUp') {
            e.preventDefault()
            const index = activeIndex > 0 ? activeIndex - 1 : lastIndex
            setActiveIndex(index)
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView()
            }
        }
        if (e.code === 'ArrowDown') {
            e.preventDefault()
            const index = activeIndex < lastIndex ? activeIndex + 1 : 0
            setActiveIndex(index)
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView({ block: 'end' })
            }
        }
        if (e.code === 'Enter') {
            e.preventDefault()
            if (open) {
                onSuggestionSelect(activeIndex)
                setOpen(false)
            } else {
                setOpen(true)
            }
        }
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setOpen(true)
        setFocused(true)
        if (props.onFocus) props.onFocus(e)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setOpen(false)
        setFocused(false)
        e.target.value = props.value?.toString()
            .trim() || ''
        if (trim) {
            handleChange(e)
        }
        if (props.onBlur) props.onBlur(e)
    }

    const handleDocumentKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpen(false)
        }
    }

    const handleDocumentClick = (e: MouseEvent) => {
        if (inputRef.current !== e.target && !inputRef.current?.contains(e.target as Node)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick)
        document.addEventListener('keydown', handleDocumentKeyDown)
        return () => {
            document.removeEventListener('click', handleDocumentClick)
            document.removeEventListener('keydown', handleDocumentKeyDown)
        }
    }, [])

    dataTest = dataTest || id

    const postIconMemo = useMemo(() => {
        if (postIcon) {
            return postIcon
        } else if (props.type === 'password') {
            return type === 'password'
                ? <Button
                    type="button"
                    design="btn-link"
                    data-test={`${dataTest}-post-icon-eye`}
                    onClick={() => setType('text')}
                >
                    <IconEye className="stroke-gray-400"/>
                </Button>
                : <Button
                    type="button"
                    design="btn-link"
                    data-test={`${dataTest}-post-icon-eye-off`}
                    onClick={() => setType('password')}
                >
                    <IconEyeOff className="stroke-gray-400"/>
                </Button>
        } else if (props.type === 'number') {
            return <div className="flex flex-col" data-test={`${dataTest}-post-icon`}>
                <button
                    type="button"
                    data-test={`${dataTest}-post-icon-plus`}
                    name={props.name}
                    value={parseFloat(props.value as string) + 1}
                    onClick={e => handleChange(e as unknown as FormChangeEvent)}
                >
                    <IconChevronUp className="stroke-black" size="sm"/>
                </button>
                <button
                    type="button"
                    data-test={`${dataTest}-post-icon-minus`}
                    name={props.name}
                    value={parseFloat(props.value as string) - 1}
                    onClick={e => handleChange(e as unknown as FormChangeEvent)}
                >
                    <IconChevronDown className="stroke-black" size="sm"/>
                </button>
            </div>
        }
    }, [props.value, type, postIcon])

    const suggestionsMemo = useMemo(() =>
        !!onSuggestionSelect && !!suggestions?.length && suggestions.map((item, index) => {
            let title = item
            const value = props.value?.toString()
            if (value && typeof value === 'string') {
                const indices = item.toLowerCase()
                    .indicesOf(value.toLowerCase())

                title = indices.reduce((acc: any, index) => {
                    const lastItem = acc[acc.length - 1]
                    const start = lastItem.toLowerCase()
                        .indexOf(value)
                    const end = start + value.length
                    return [
                        ...acc.slice(0, -1),
                        lastItem.slice(0, start),
                        <span key={index} className="font-bold">{lastItem.slice(start, end)}</span>,
                        lastItem.slice(end)
                    ]
                }, [item])
            }
            return <li key={index}>
                <button
                    onMouseDown={() => onSuggestionSelect(index)}
                    name={props.name}
                    type="button"
                    className={props.value === item || index === activeIndex ? 'active' : ''}>
                    <span>{title}</span>
                </button>
            </li>
        }), [suggestions, props.value])

    const input = <input
        id={id}
        ref={inputRef}
        data-test={`${dataTest}-input`}
        {...props}
        type={type}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
    />

    return <FormControl
        id={id}
        label={label}
        input-size={size}
        data-test={dataTest}
        preIcon={preIcon}
        postIcon={postIconMemo}
        errors={errors}
        warnings={warnings}
        hint={hint}
        transparent={transparent}
        tooltip={tooltip}
        className={classnames('form-control-input', className)}
        data-value={props.value}
    >
        {onSuggestionSelect
            ? <Dropdown
                trigger="manual"
                show={open && !!suggestions?.length}
                button={input}
                options={dropdownOptions}
            >
                {!!suggestions?.length &&
                    <ul className="input-suggestions-list" ref={dropdownRef} data-test={`${dataTest}-suggestions-list`}>
                        {suggestionsMemo}
                    </ul>}
            </Dropdown>
            : input}
    </FormControl>
}
