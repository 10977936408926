import { IconBatteryCharging, IconSlashCircle01 } from '@/components'
import { CompanyBatteryEligibilityDataType } from '@/types'

export enum BatteryEligibilityTypeEnum {
    NO_BATTERY = 'no_battery',
    BATTERY_20_YEAR = 'battery_20_year',
    BATTERY_30_YEAR = 'battery_30_year'
}

export const BATTERY_ELIGIBILITY_TYPES: CompanyBatteryEligibilityDataType[] = [
    {
        key: BatteryEligibilityTypeEnum.BATTERY_20_YEAR,
        title: 'SI-30:20 Legacy',
        secondaryTitle: '20-Year Battery Coverage',
        subtitle: '30-Year Solar & 20-Year Battery Warranty',
        color: 'purple',
        IconComponent: IconBatteryCharging
    },
    {
        key: BatteryEligibilityTypeEnum.BATTERY_30_YEAR,
        title: 'SI-30 Total',
        secondaryTitle: '30-Year Battery Coverage',
        subtitle: '30-Year Solar & Battery Warranty',
        color: 'success',
        IconComponent: IconBatteryCharging
    },
    {
        key: BatteryEligibilityTypeEnum.NO_BATTERY,
        title: 'Not Eligible to Sell Battery',
        color: 'gray',
        IconComponent: IconSlashCircle01
    }
]
