import { Model } from './Model'
import { User } from './User'
import { WarrantyStatus } from './WarrantyStatus'
import { WARRANTY_HISTORY_EVENT_TYPES, WarrantyHistoryTypeEnum, WarrantyStatusEnum } from '@/enums'
import { ActivityPropertiesDataType, WarrantyHistoryDataType, WarrantyHistoryType } from '@/types'

export class WarrantyHistory extends Model {
    static endpoint = 'api/v1/warranty/history'

    raw: WarrantyHistoryDataType

    properties?: ActivityPropertiesDataType

    event: WarrantyHistoryType

    causer?: User

    status?: WarrantyStatus

    constructor(data: WarrantyHistoryDataType) {
        super(data)
        this.raw = data
        this.status = WarrantyStatus.find(data.status)
        this.causer = data.causer ? new User(data.causer) : undefined
        try {
            this.properties = JSON.parse(data.properties)
        } catch {
            this.properties = undefined
        }
        this.event = WARRANTY_HISTORY_EVENT_TYPES.find(({ key }) => key === data.event) as WarrantyHistoryType
        if (data.event === WarrantyHistoryTypeEnum.WARRANTY_CANCELED &&
            data.old_status === WarrantyStatusEnum.CANCELLATION_REQUESTED) {
            this.event = WARRANTY_HISTORY_EVENT_TYPES.find(item =>
                item.key === WarrantyHistoryTypeEnum.WARRANTY_CANCELLATION_REQUEST_APPROVED) as WarrantyHistoryType
        }
        if (data.event === WarrantyHistoryTypeEnum.WARRANTY_REINSTATED &&
            data.old_status === WarrantyStatusEnum.REINSTATEMENT_REQUESTED) {
            this.event = WARRANTY_HISTORY_EVENT_TYPES.find(item =>
                item.key === WarrantyHistoryTypeEnum.WARRANTY_REINSTATEMENT_REQUEST_APPROVED) as WarrantyHistoryType
        }
    }

    get title() {
        return this.event.key.replaceAll('_', ' ')
            .capitalize() || '-'
    }
}
