import { useEffect, useState } from 'react'

import { Button, FormChangeEvent, IconFileMultiple, Popup, Radio, RowProps, Tabs, WarrantyDetails } from '@/components'
import { Warranty } from '@/models'
import { Payload } from '@/services'

type Props = {
    isOpen: boolean
    row: RowProps
    form: any
    onChange: any
    onClose: () => void
}

const HandleDuplicatesPopup = ({
    isOpen,
    row,
    ...props
}: Props) => {
    const [form, setForm] = useState({ action: '', duplicate_override: false })
    const [duplicates, setDuplicates] = useState<Warranty[]>([])
    const [showDuplicates, setShowDuplicates] = useState(false)
    const [tab, setTab] = useState<string | number | null>(null)
    const handleChange = (e: FormChangeEvent) => {
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }))
    }

    const fetchDuplicates = async () => {
        const res = await Warranty.getDuplicates(row as Payload)
        if (res.data.length) {
            setTab(res.data[0].id)
            setDuplicates(res.data)
        }
    }

    useEffect(() => {
        fetchDuplicates()
    }, [row])

    const handleConfirm = () => {
        const clone = [...props.form.warranties]
        if (form.action === 'allow') {
            clone[row.index as number].duplicate_override = true
        } else {
            clone.splice(row.index as number, 1)
        }
        props.onChange({ target: { name: 'warranties', value: clone } })
        props.onClose()
    }

    const duplicate = duplicates.find(item => item.id === tab)

    return <Popup open={isOpen} onClose={props.onClose} className="w-full md:w-auto">
        <div className="flex flex-col gap-6">
            <h4 className="text-xl text-gray-900 flex gap-3 items-center">
                <IconFileMultiple className="stroke-primary-700" size="xl"/>
                Duplicate Address
            </h4>
            <p className="text-gray-700">
                We detected a duplicate address in this row. This address already exists for another warranty entry.
                Please review your options to resolve this issue:
            </p>
            <div className="flex gap-4 flex-col items-start">
                <Radio
                    label="Remove Row from Export"
                    id="allow-duplicate-radio-remove"
                    name="action"
                    value="remove"
                    onChange={handleChange}
                    checked={form.action === 'remove'}
                />
                {form.action === 'remove' && <p className="text-sm text-gray-700">
                    Select this option to exclude this row from the export due to the duplicate address. This will help
                    prevent duplicate billing if the warranty has already been registered.
                </p>}
                <Radio
                    id="allow-duplicate-radio-allow"
                    label="Allow Duplicate Address"
                    name="action"
                    value="allow"
                    onChange={handleChange}
                    checked={form.action === 'allow'}
                />
                {form.action === 'allow' && <>
                    <p className="text-sm text-gray-700">
                        Select this option if the duplicate address is valid, such as for multiple installations at the
                        same location.
                    </p>
                    <p className="text-sm text-gray-700">
                        <span className="font-semibold">Note:</span> If this is a duplicate address, there is a high
                        chance that this warranty has already been registered on the platform. If it has already been
                        registered, removing the row from the uploaded file will prevent getting billed twice. Use this
                        option only when the duplicate address is legitimate, like multiple warranties at the same
                        address for different structures within the same property. If the warranty is valid and not a
                        mistaken duplicate, select this option to mark it as unique.
                    </p>
                </>}
                {form.action === 'allow' && !!duplicates.length && <>
                    <Button design="btn-link" className="!text-primary-700" onClick={() => setShowDuplicates(!showDuplicates)}>
                        Click to {showDuplicates ? 'hide' : 'show'}
                    </Button>

                    {showDuplicates && <div className="w-full">
                        <Tabs
                            className="w-fit"
                            size="sm"
                            value={tab}
                            tabs={duplicates.map(item => ({
                                id: item.id,
                                title: `Plan ID ${item.policy_num}`
                            }))}
                        />

                        {duplicate && <div className="grid grid-cols-2 gap-8 mt-8">
                            <WarrantyDetails warranty={duplicate}/>
                        </div>}
                    </div>}
                </>}
            </div>

            <div className="flex flex-wrap gap-4 justify-center">
                <Button hasError design="btn-secondary-gray" type="button" onClick={props.onClose}>
                    Exit without Changing
                </Button>
                <Button type="button" onClick={handleConfirm} disabled={!form.action}>
                    Confirm Changes
                </Button>
            </div>
        </div>
    </Popup>
}

export default HandleDuplicatesPopup
