export enum SystemTierCategoryEnum {
    // Connected Sites
    TIER_1 = 'tier_1',
    TIER_2 = 'tier_2',
    TIER_3 = 'tier_3',
    NORMAL_SITES = 'normal_sites',

    // Unmonitored sites
    UNMONITORED = 'unmonitored',

    // Excluded Sites
    EXCLUDED = 'excluded'
}
