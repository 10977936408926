import { Fragment, useEffect, useState } from 'react'

import {
    ContactForm,
    ContactFormDataType,
    FormChangeEvent,
    FormChangeEventHandler,
    FormDataType,
    FormErrorsType,
    Input
} from '@/components'
import { Company } from '@/models'

export interface ContactsFormDataType extends FormDataType {
    contacts: ContactFormDataType[]
}

type ContactsFormProps = {
    company?: Company
    form: ContactsFormDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
}

export const ContactsForm = ({
    form,
    errors,
    company,
    ...props
}: ContactsFormProps) => {
    const [newContactsCount, setNewContactsCount] = useState(form.contacts.length || 1)

    useEffect(() => {
        const contacts = [...form.contacts]
        do {
            if (contacts.length > newContactsCount) {
                contacts.pop()
            } else if (contacts.length < newContactsCount) {
                contacts.push({
                    company_id: company?.id,
                    name: '',
                    email: '',
                    job_title: '',
                    phone: '',
                    role: (company?.isAffiliate ? 'conglomerate' : 'contractor') || 'contractor',
                    is_new: true
                })
            }
        } while (contacts.length !== newContactsCount)
        props.onChange({ target: { name: 'contacts', value: contacts } })
    }, [newContactsCount])

    useEffect(() => {
        if (form.contacts.length !== newContactsCount) {
            setNewContactsCount(form.contacts.length)
        }
    }, [form.contacts.length])
    const handleChange = (e: FormChangeEvent, index: number) => {
        const contacts = [...form.contacts]
        contacts[index][e.target.name] = e.target.value
        props.onChange({ target: { name: 'contacts', value: contacts } })
    }

    const handleCountChange = (e: FormChangeEvent) => {
        const value = parseInt(e.target.value as string)
        if (!isNaN(value) && value > 0) {
            setNewContactsCount(value > 10 ? 10 : value)
        } else {
            setNewContactsCount(1)
        }
    }

    const getErrors = (index: number) => {
        if (errors.contacts) return errors.contacts[index]
        const obj: any = {}
        const keyStart = `contacts.${index}.`
        Object.keys(errors).forEach(key => {
            const fieldName = key.replace(keyStart, '')
            const value = errors[key]
            if (typeof value === 'string' && key.startsWith(keyStart)) {
                obj[fieldName] = value.replace(key, fieldName).replaceAll('_', ' ')
            }
        })
        return obj
    }

    return <>
        {form.contacts.map((item: any, index: number) => !item.is_new && <Fragment key={item.id}>
            <h3 className="text-sm lg:col-span-2">Contact {index + 1}:</h3>
            <ContactForm
                index={index}
                form={item}
                errors={getErrors(index)}
                onChange={e => handleChange(e, index)}
            />
        </Fragment>)}
        <div className="flex lg:col-span-2 flex-col lg:flex-row gap-6">
            <Input
                id="num_users"
                name="num_users"
                type="number"
                label="Number of Users*"
                placeholder="Number of Users"
                onChange={handleCountChange}
                value={newContactsCount}
            />
            <div className="grid lg:grid-cols-2 gap-6 grow">
                {form.contacts.map((item: any, index: number) => item.is_new && <Fragment key={index}>
                    <h3 className="text-sm lg:col-span-2">Contact {index + 1}:</h3>
                    <ContactForm
                        index={index}
                        form={item}
                        errors={getErrors(index)}
                        onChange={e => handleChange(e, index)}
                    />
                </Fragment>)}
            </div>
        </div>
    </>
}
