import { Model } from './Model'
import { ArticleCategoryDataType } from '@/types'

export class ArticleCategory extends Model {
    raw: ArticleCategoryDataType

    name: string

    constructor(data: ArticleCategoryDataType) {
        super(data)
        this.raw = data
        this.name = data.name
    }
}
