import { useEffect, useMemo } from 'react'

import { FormChangeEventHandler, FormDataType, FormErrorsType, Select, SelectOptionProps } from '@/components'
import { Product, Warranty } from '@/models'
import { IdType } from '@/types'

export interface BatteryFormDataType extends FormDataType {
    id: IdType
    product_id: IdType
    size_kw: string | number
    product?: {
        id: IdType
        model_name: string
    }
}

type BatteryFormProps = {
    form: BatteryFormDataType
    products?: Product[]
    onChange: FormChangeEventHandler
    inputClassName?: string
    labelPrefix?: string
    errors: FormErrorsType
}

export const BatteryForm = ({
    form,
    inputClassName,
    products,
    errors,
    labelPrefix = '',
    ...props
}: BatteryFormProps) => {
    const capacityOptions = useMemo<SelectOptionProps[]>(() => {
        const battery = products?.find(({ id }) => id === parseInt(form.product_id as string))
        const availableSizes = battery?.properties
            .find(item => item.property.name === 'available_sizes')?.value as string[] || []
        return [{
            value: '',
            title: ''
        }, ...availableSizes.map((item: string) => ({
            value: parseFloat(item),
            title: Warranty.getField('size_kw')
                .format(item)
        }))]
    }, [products, form.product_id])

    useEffect(() => {
        if (capacityOptions.every(({ value }) => value.toString() !== form.size_kw.toString())) {
            props.onChange({ target: { value: '', name: 'size_kw' } })
        }
    }, [form.product_id])

    return <>
        <Select
            id="product_id"
            name="product_id"
            label={`${labelPrefix} Make & Model`}
            options={[{ title: '', value: '' }, ...products?.map(({ id, model_name: title }) => ({
                value: id,
                title
            })) || []]}
            onChange={props.onChange}
            value={form.product_id}
            errors={errors.product_id}
            className={inputClassName}
            data-test="battery-product-id"
        />
        <Select
            id="size_kw"
            name="size_kw"
            label={`${labelPrefix} Capacity (kWh)`}
            options={capacityOptions}
            onChange={props.onChange}
            value={form.size_kw}
            errors={errors.size_kw}
            disabled={!form.product_id}
            className={inputClassName}
            data-test="battery-size-kw"
        />
    </>
}
