import { SYSTEM_STATUSES_MONITORED } from './system-statuses-monitored-normal'
import { SYSTEM_STATUSES_MONITORED_TIER_1 } from './system-statuses-monitored-tier-1'
import { SYSTEM_STATUSES_MONITORED_TIER_2 } from './system-statuses-monitored-tier-2'
import { SYSTEM_STATUSES_MONITORED_TIER_3 } from './system-statuses-monitored-tier-3'
import { IconCheckCircle, IconLightBulb05, IconLightning02, IconSpeedometer } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemTierCategoryEnum } from '@/enums'
import { SystemTierCategoryDataType } from '@/types'

export const SYSTEM_TIER_CATEGORIES_MONITORED_SITES: SystemTierCategoryDataType[] = [
    {
        value: SystemTierCategoryEnum.TIER_1,
        title: 'Tier 1',
        severity: 'High Severity',
        subtitle: 'Critical alert with maximum severity score.',
        description: <div>
            <span className="font-semibold">Tier 1</span> are of high severity and require immediate attention.
            They include production issues causing known energy losses, component errors requiring on-site
            intervention, or persistent communication issues lasting over a week, potentially indicating
            underlying energy production loss issues.
        </div>,
        color: 'error',
        icon: (size?: IconSize) => <IconLightning02 size={size}/>,
        secondaryOrder: 2,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_1
    },
    {
        value: SystemTierCategoryEnum.TIER_2,
        title: 'Tier 2',
        severity: 'Medium Severity',
        subtitle: 'Moderate severity, may need intervention.',
        description: <div>
            <span className="font-semibold">Tier 2</span> Issues have medium severity and may not need immediate
            intervention. They may resolve on their own but should be monitored in case they escalate. Examples
            include microinverters not reporting data or system controller issues.
        </div>,
        color: 'warning',
        icon: (size?: IconSize) => <IconSpeedometer size={size}/>,
        secondaryOrder: 3,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_2
    },
    {
        value: SystemTierCategoryEnum.TIER_3,
        title: 'Tier 3',
        severity: 'Low Severity',
        subtitle: 'Low-tier alert, indicates a minor issue.',
        description: <div>
            <span className="font-semibold">Tier 3</span> are low priority watch alerts. They usually resolve on
            their own or relate to administrative settings, such as incomplete activations. These issues do not
            require immediate action.
        </div>,
        color: 'yellow',
        icon: (size?: IconSize) => <IconLightBulb05 size={size}/>,
        secondaryOrder: 4,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_3
    },
    {
        value: SystemTierCategoryEnum.NORMAL_SITES,
        title: 'Normal Sites',
        description: 'All systems are functioning within normal parameters, no immediate action is required.',
        color: 'success',
        icon: (size?: IconSize) => <IconCheckCircle size={size}/>,
        secondaryOrder: 1,
        statuses: SYSTEM_STATUSES_MONITORED
    }
]
