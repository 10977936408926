import classnames from 'classnames'
import { HTMLAttributes, ReactNode, useMemo } from 'react'

import { Button, FormChangeEventHandler, IconArrowLeft, IconArrowRight } from '@/components'

interface PaginationProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    name?: string
    current: number
    total: number
    perPage?: number
    nextButtonText?: ReactNode
    prevButtonText?: ReactNode
    'data-test'?: string
    onChange?: FormChangeEventHandler
}

export const Pagination = ({
    name = 'page',
    current,
    total,
    perPage = 10,
    nextButtonText,
    prevButtonText,
    'data-test': dataTest,
    onChange,
    ...props
}: PaginationProps) => {
    const handleChange = (value: number) => {
        if (onChange) onChange({
            target: {
                name,
                value
            }
        })
    }
    const lastPage = Math.ceil(total / perPage)

    const pages = useMemo(() => {
        if (lastPage < 8) return Array.from({ length: lastPage }, (_, i) => i + 1)

        const arr = []
        if (current > 1) arr.push(1)
        if (current > lastPage - 3) arr.push(2)
        if (current > lastPage - 2) arr.push(3)
        if (current > 3) arr.push(0)
        if (current === lastPage) arr.push(current - 2)
        if (current > 2) arr.push(current - 1)
        arr.push(current)
        if (lastPage - current > 1) arr.push(current + 1)
        if (current === 1) arr.push(current + 2)
        if (lastPage - current > 2) arr.push(0)
        if (current < 3) arr.push(lastPage - 2)
        if (current < 4) arr.push(lastPage - 1)
        if (current < lastPage) arr.push(lastPage)

        return arr
    }, [current, total, perPage])

    const className = classnames('pagination', props.className)

    return <div {...props} className={className}>
        <Button type="button" design="btn-secondary-gray" className="mr-4 2xl:mr-6" data-test={`${dataTest}-previous-page-button`} onClick={() => handleChange(
            current - 1)} disabled={current === 1}>
            {prevButtonText || <><IconArrowLeft className="lg:mr-2"/> <span className="hidden lg:inline">Previous</span></>}
        </Button>

        {pages.map((item, index) => {
            const className = classnames('pagination-button', {
                active: current === item,
                next: item === current + 1,
                prev: item === current - 1
            })
            return <button
                key={index}
                className={className}
                disabled={!item}
                type="button"
                onClick={() => handleChange(item)}
                data-test={`${dataTest}-${item}-page-button`}
            >
                {item || '...'}
            </button>
        })}

        <Button
            type="button"
            design="btn-secondary-gray"
            className="ml-4 2xl:ml-6"
            data-test={`${dataTest}-next-page-button`}
            onClick={() => handleChange(current + 1)} disabled={current === lastPage || !lastPage}
        >
            {nextButtonText || <><span className="hidden lg:inline">Next</span> <IconArrowRight className="lg:ml-2"/></>}
        </Button>
    </div>
}
