import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
    Badge,
    DatePicker, IconActivity, IconBankNote,
    IconUser01,
    Popup, Select,
    SearchableSelect,
    PageHeader, FormChangeEvent, DateRange
} from '@/components'
import { AuthLayout, FiltersType, GlobalIndex, GlobalIndexChangeEvent, GlobalIndexDataType } from '@/containers'
import { useNavigateWithQuery, useQuery } from '@/hooks'
import { Activity } from '@/models'
import { UserDataType } from '@/types'

interface ActivityIndexData extends GlobalIndexDataType {
    rows: Activity[]
    rest: {
        users: UserDataType[]
        actionTypes: string[]
    }
}

interface ActivityIndexFilters extends FiltersType {
    action_type: string
    causer_id: string
    start_date: Date
    end_date: Date
}

const ActivitiesIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const getDefaultFilters = () => ({
        action_type: query.action_type || '',
        causer_id: query.causer_id || '',
        // Adding ' 00:00' to the end of string date to clear timezone
        start_date: query.start_date ? new Date(`${query.start_date} 00:00`) : new Date().startOf('month'),
        end_date: query.end_date ? new Date(`${query.end_date} 00:00`) : new Date().endOf('month')
    })
    const [activity, setActivity] = useState<Activity | null>(null)
    const [filters, setFilters] = useState<ActivityIndexFilters>(getDefaultFilters())
    const [data, setData] = useState<ActivityIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        rest: {
            users: [],
            actionTypes: []
        }
    })

    const handleChange = (e: GlobalIndexChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const handleFiltersChange = (e: FormChangeEvent) => {
        if (e.target.name === 'range') {
            const value = e.target.value as DateRange
            navigateWithQuery({ page: null, start_date: value.start?.toISODate(), end_date: value.end?.toISODate() })
            setFilters({ ...filters, start_date: value.start as Date, end_date: value.end as Date })
        } else {
            setFilters({ ...filters, [e.target.name]: e.target.value as string })
            navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        }
    }


    const fetchActivity = async () => {
        const activity = data.rows.find(item => item.id?.toString() === id?.toString())
        setActivity(activity || null)
    }
    useEffect(() => {
        fetchActivity()
    }, [id])

    const formatValue = (value?: string | number | null) => {
        if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/)) {
            const date = new Date(value)
            return date.format()
        }
        if (typeof value === 'object') {
            return JSON.stringify(value)
        }
        return value
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        'users.name': item.user_name,
        subject_type: item.subject_type,
        description: item.actionBadge,
        'activity_logs.created_at': item.created_at?.format({
            hour: '2-digit',
            minute: '2-digit'
        }),
        _path: `/activities/${item.id}${location.search}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Activities"/>}>
        <GlobalIndex
            id="activities"
            dataType="Actions"
            api={(...args) => Activity.index(...args)}
            data={data}
            onChange={handleChange}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            onFiltersChange={filters => setFilters(filters)}
            columns={[
                { title: 'User', field: 'users.name' },
                { title: 'Type', field: 'subject_type' },
                { title: 'Action', field: 'description' },
                { title: 'Date', field: 'activity_logs.created_at' }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <DatePicker
                    id="date-range"
                    name="range"
                    placeholder="Date range"
                    value={{
                        start: filters.start_date,
                        end: filters.end_date
                    }}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                />
                <SearchableSelect
                    id="causer_id"
                    name="causer_id"
                    value={filters.causer_id}
                    placeholder="Filter by User"
                    options={data.rest.users.map(item => ({ value: item.id, title: item.name }))}
                    onChange={handleFiltersChange}
                />
                <Select
                    name="action_type"
                    id="activity-action-type"
                    options={[
                        { value: '', title: 'Action Type' },
                        ...data.rest.actionTypes.map(actionType => ({
                            value: actionType,
                            title: actionType.capitalize()
                        }))
                    ]}
                    className="md:ml-3"
                    value={filters.action_type}
                    onChange={handleFiltersChange}
                />
            </>}
        />

        {activity && <Popup open={!!activity.id} onClose={() => navigate(`/activities${location.search}`)}>
            {activity.id && <div className="flex flex-col max-w-full min-w-full sm:min-w-[39rem]">
                <h3 className="font-semibold flex gap-2 items-center">
                    <Badge className="badge-circle bg-primary-100 stroke-primary-700">
                        <IconActivity/>
                    </Badge>
                    {activity.subject_type} #{activity.subject?.id}
                </h3>
                <div className="lg:pl-12">
                    <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">General</h4>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">User:</span>
                        <span className="text-primary-700 flex">
                            <IconUser01 className="stroke-primary-700 mr-2"/>
                            {activity.causer?.name || 'System'}
                        </span>
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">{activity.subject_type}:</span>
                        <span className="text-primary-700 flex">
                            <IconBankNote className="stroke-primary-700 mr-2"/>
                            {activity.subject_type} ID: #{activity.subject?.id}
                        </span>
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">Action:</span>
                        {activity.actionBadge}
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">Date:</span>
                        {activity.created_at?.format()}
                    </p>

                    <div className="flex flex-col lg:flex-row gap-8 break-anywhere">
                        <div className="lg:w-1/2">
                            <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">Old Attributes</h4>
                            {activity.properties?.old && Object.keys(activity.properties.old)
                                .map(key =>
                                    <p key={key} className="flex mt-2 text-sm break-anywhere">
                                        <span className="font-semibold mr-2">{key}:</span>
                                        {formatValue(activity.properties.old[key])}
                                    </p>)}
                        </div>
                        <div className="lg:w-1/2">
                            <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">New Attributes</h4>
                            {activity.properties?.attributes && Object.keys(activity.properties.attributes)
                                .map(key =>
                                    <p key={key} className="flex mt-2 text-sm break-anywhere">
                                        <span className="font-semibold mr-2">{key}:</span>
                                        {formatValue(activity.properties.attributes[key] as string | number | null)}
                                    </p>)}
                        </div>
                    </div>
                </div>
            </div>}
        </Popup>}
    </AuthLayout>
}

export default ActivitiesIndex
