import {
    Card,
    DeviceDetailsDefault,
    DeviceDetailsGenerac
} from '@/components'
import { Warranty } from '@/models'

const DevicesIndex = ({ warranty }: { warranty: Warranty }) =>
    <Card>
        {warranty.homeowner?.system?.manufacturer.isGenerac
            ? <DeviceDetailsGenerac warranty={warranty}/>
            : <DeviceDetailsDefault warranty={warranty}/>}
    </Card>

export default DevicesIndex
