import { useEffect, useState } from 'react'

import { Button, IconHelpCircle, MonitoringIssuesChart, Tooltip } from '@/components'
import { SystemStatusCategoryEnum, SystemTierCategoryEnum } from '@/enums'
import { InverterManufacturer, System, SystemStatusCategory } from '@/models'


type MonitoringSummaryProps = {
    className?: string
    onChange: any
    processing: boolean
}

const MonitoringSummary = ({ className, processing, ...props }: MonitoringSummaryProps) => {
    const [data, setData] = useState<any>({
        groups: {
            [SystemTierCategoryEnum.TIER_1]: 0,
            [SystemTierCategoryEnum.TIER_2]: 0,
            [SystemTierCategoryEnum.TIER_3]: 0,
            [SystemTierCategoryEnum.NORMAL_SITES]: 0,
            [SystemStatusCategoryEnum.UNMONITORED_SITES]: 0,
            [SystemStatusCategoryEnum.EXCLUDED_SITES]: 0,
            [SystemStatusCategoryEnum.MONITORED]: 0
        },
        sites: { total: 0 }
    })
    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const fetchData = async () => {
        const controller = new AbortController
        setAbortController(controller)
        try {
            const { data } = await System.summary({}, { signal: controller.signal })
            setData(data)
        } finally {
            props.onChange('monitoringSummary')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    return <div className={className}>
        <div className="flex justify-between items-center text-gray-900">
            <h3 className="text-lg text-center" data-test="dashboard-monitoring-summary-title">Fleet Monitoring Summary</h3>

            <Tooltip interactive color="white" backdrop placement={window.innerWidth > 768 ? 'right' : 'bottom'} content={<div className="p-4 text-gray-750 max-w-full">
                <h4 className="text-sm font-semibold text-gray-600">Fleet Monitoring Summary</h4>
                <p className="mt-2">
                    Solar Insure reports system or component level errors based on the inverter manufacturer&apos;s
                    monitoring data. These issues help identify potential problems in your solar system.
                </p>

                <h5 className="mt-4 flex 3">
                    <div className="border-b border-orange-400 grow">
                        Supported Inverter Manufacturers
                    </div>

                    <div className="flex">
                        <div className="p-0.5 rounded-full border border-gray-300 bg-white translate-x-3 z-[1] hover:z-10">
                            {InverterManufacturer.enphase.getLogo()}
                        </div>
                        <div className="p-0.5 rounded-full border border-gray-300 bg-white translate-x-1.5 z-[2] hover:z-10">
                            {InverterManufacturer.solaredge.getLogo()}
                        </div>
                        <div className="p-0.5 rounded-full border border-gray-300 bg-white z-[3] hover:z-10">
                            {InverterManufacturer.generac.getLogo()}
                        </div>
                    </div>
                </h5>

                <div className="mt-4 flex flex-col gap-4">
                    {SystemStatusCategory.all.map(item =>
                        <div key={item.value}>
                            <h6 className="text-sm px-4 py-1 bg-gray-100 rounded-3xl w-fit text-gray-600 font-semibold flex items-center gap-2">
                                {item.title}:

                                <div className="flex">
                                    {item.tiers.length > 1
                                        ? item.tiers.map(item => <div key={item.value}>{item.iconBadge('xs')}</div>)
                                        : item.statuses.map(item => <div key={item.value}>{item.iconBadge('xs')}</div>)}
                                </div>
                            </h6>
                            <p className="mt-2 pl-6">{item.description}</p>
                        </div>)}
                </div>
            </div>}>
                <IconHelpCircle className="stroke-gray-400"/>
            </Tooltip>
        </div>
        <div className="w-full mt-16">
            <MonitoringIssuesChart className="w-full" label="Monitored" summary={data} type="half-donut"/>
        </div>

        <div className="flex justify-between mt-9">
            {SystemStatusCategory.connectedSitesCategory.secondarySortedTiers.map(item =>
                <div className="flex items-center" key={item.value}>
                    <span data-test={`dashboard-monitoring-summary-${item.value}-icon`} className={`w-2 h-2 rounded-full mr-2 ${item.colors.bg}`}></span>
                    <span data-test={`dashboard-monitoring-summary-${item.value}-title`} className="text-gray-500 text-sm">{item.title}</span>
                </div>
            )}
        </div>

        <div className="flex justify-between items-center pt-5 mt-5 border-t -mx-6 px-6 -mb-6 pb-6">
            <div className="flex flex-col gap-1">
                <Button
                    className="!justify-start"
                    design="btn-link"
                    href={`/monitoring?statuses[0]=${SystemStatusCategoryEnum.UNMONITORED_SITES}`}
                    data-test="dashboard-monitoring-summary-unmonitored-sites-link"
                >
                    {data.groups[SystemStatusCategoryEnum.UNMONITORED_SITES]?.format() || 0} Unmonitored Sites
                </Button>
                <Button
                    className="!justify-start"
                    design="btn-link"
                    href={`/monitoring?statuses[0]=${SystemStatusCategoryEnum.EXCLUDED_SITES}`}
                    data-test="dashboard-monitoring-summary-excluded-sites-link"
                >
                    {data.groups[SystemStatusCategoryEnum.EXCLUDED_SITES]?.format() || 0} Excluded Sites
                </Button>
            </div>
            <div className="flex flex-col justify-start items-center">
                <span className="text-2xl text-gray-900 font-semibold" data-test="dashboard-monitoring-summary-total-value">{(data.sites.total?.format() || 0)}</span>
                <span className="text-xs text-gray-500" data-test="dashboard-monitoring-summary-total-title">Total</span>
            </div>
        </div>
    </div>
}

export default MonitoringSummary
