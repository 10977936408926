/**
 * Returns the domain of the current website, or an empty string if the domain is an IP address.
 *
 * @returns {string} The domain of the website.
 */
const getDefaultCookieDomain = () => {
    const isIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.hostname)
    const res = /\.(.*)\.(.*)/.exec(location.hostname)
    return res && res[0] !== '.vercel.app' && !isIp ? res[0] : ''
}

/**
 * Utility class for working with cookies.
 */
class Cookie {
    /**
     * Set a cookie with a given name and value.
     *
     * @param {string} name - The name of the cookie.
     * @param {string | number} value - The value of the cookie.
     * @param {string} [domain] - The domain of the cookie. Defaults to the current domain.
     * @param {number} [days] - The number of days until the cookie expires. Defaults to 1.
     */
    static set(name: string, value: string | number, domain: string = getDefaultCookieDomain(), days = 1) {
        let expires = ''
        if (days) {
            const date = new Date()
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
            expires = `; expires=${date.toUTCString()}`
        }
        document.cookie = `${name}=${value || ''}${expires};domain=${domain};path=/`
    }

    /**
     * Get the value of a cookie by name.
     *
     * @param {string} name - The name of the cookie.
     * @returns {string | null} - The value of the cookie, or null if it doesn't exist.
     */
    static get(name: string): string | null {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null
        return null
    }

    /**
     * Delete a cookie by name.
     *
     * @param {string} name - The name of the cookie.
     * @param {string} [path='/'] - The path of the cookie. Defaults to '/'.
     * @param {string} [domain] - The domain of the cookie. Defaults to the current domain.
     * @returns {boolean} - True if the cookie was successfully deleted, false otherwise.
     */
    static delete(name: string, path = '/', domain: string = getDefaultCookieDomain()) {
        if (this.get(name)) {
            document.cookie = `${name}=;path=${path};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`
            return true
        }
        return false
    }
}

export default Cookie
