import { useEffect, useMemo, useRef, useState } from 'react'

import {
    Button,
    ExportButton, ExportFileType,
    IconDownload,
    IconMail,
    WarrantiesCancelOrReinstateButton
} from '@/components'
import {
    WarrantyByContractorsIndexData,
    WarrantyByContractorsIndexFilters,
    WarrantyIndexData,
    WarrantyIndexFilters
} from '@/containers'
import { WarrantyStatusEnum } from '@/enums'
import { useAuth, useQuery } from '@/hooks'
import { Warranty, WarrantyStatus } from '@/models'
import { api } from '@/services'
import { JobsBatchType } from '@/types'

type WarrantyIndexButtonsProps = {
    data: WarrantyIndexData | WarrantyByContractorsIndexData
    filters: WarrantyIndexFilters | WarrantyByContractorsIndexFilters
    warrantiesByContractors?: boolean
    'data-test'?: string
    onChange: () => void
}

const DOWNLOAD_LIMIT = 20

export const WarrantyIndexButtons = ({
    data,
    filters,
    warrantiesByContractors = false,
    'data-test': dataTest,
    ...props
}: WarrantyIndexButtonsProps) => {
    const query = useQuery()
    const [processing, setProcessing] = useState<boolean>(false)
    const auth = useAuth()
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const jobBatchRef = useRef<JobsBatchType | null>(null)

    const selected = useMemo(() => data.selectedRows === 'all' ? 'all' : data.selectedRows
        .map(item => data.rows.find(row => row.id === item.id) as Warranty).filter(Boolean)
    , [data.rows, data.selectedRows])
    const excluded = useMemo(() => data.excludedRows
        .map(item => data.rows.find(row => row.id === item.id) as Warranty).filter(Boolean)
    , [data.rows, data.excludedRows])

    const selectedWarrantiesCount = selected === 'all' ? data.meta.total - excluded.length : selected.length

    const handleSend = async () => {
        setProcessing(true)
        try {
            await Warranty.send(selected === 'all' ? data.rows.map(({ id }) => id) : selected.map(({ id }) => id))
        } finally {
            setProcessing(false)
        }
    }

    const handleDownload = () => Warranty.downloadMany({
        ids: selected === 'all'
            ? excluded.map(({ id }) => id)
            : selected.map(({ id }) => id),
        warranties_by_contractors: warrantiesByContractors
    })

    const handleExport = (format: ExportFileType) => Warranty.export(selected === 'all'
        ? {
            format,
            warranties_by_contractors: warrantiesByContractors,
            select_all: true,
            excluded_ids: excluded.map(({ id }) => id),
            ...query
        }
        : {
            format,
            warranties_by_contractors: warrantiesByContractors,
            ids: selected.map(({ id }) => id)
        })

    const pingJobBatch = async () => {
        if (jobBatchRef.current) {
            const { data } = await api.jobs.progress(jobBatchRef.current.id)
            if (data.percentage > jobBatchRef.current.percentage) {
                props.onChange()
            }
            if (data.percentage === 100 && intervalRef.current) {
                clearInterval(intervalRef.current)
            } else {
                jobBatchRef.current = data
            }
        }
    }

    const handlePing = (data: JobsBatchType) => {
        jobBatchRef.current = data
        intervalRef.current = setInterval(() => pingJobBatch(), 3000)
    }

    useEffect(() => () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }, [])

    const isInactiveWarrantySelected = selected === 'all'
        ? query.statuses?.some((status: WarrantyStatusEnum) => WarrantyStatus.find(status).isInactive)
        : selected.some(item => item.status.isInactive)

    return <>
        {(auth.user?.isAdminOrStaff || auth.user?.isContractor) &&
            <WarrantiesCancelOrReinstateButton
                selected={selected}
                excluded={excluded}
                data={data}
                size="btn-lg"
                quantity={selectedWarrantiesCount}
                onChange={props.onChange}
                onPingJob={handlePing}
            />}

        <ExportButton
            disabled={!selected.length}
            data-test={dataTest}
            onExport={handleExport}
        />

        {(auth.user?.isAdminOrStaff || auth.user?.isContractor) &&
            <Button
                square
                tooltip={isInactiveWarrantySelected
                    ? 'This functionality is disabled for canceled warranties' : 'Email Warranty'}
                tooltipDisabled={!data.selectedRows.length || processing}
                design="btn-secondary-gray"
                size="btn-lg"
                disabled={!data.selectedRows.length || processing || isInactiveWarrantySelected}
                onClick={handleSend}
                data-test={`${dataTest}-send-button`}
            >
                <IconMail/>
            </Button>}

        <Button
            square
            tooltip={isInactiveWarrantySelected
                ? 'This functionality is disabled for canceled warranties' : 'Download Warranty'}
            tooltipDisabled={selectedWarrantiesCount < 1 || selectedWarrantiesCount > DOWNLOAD_LIMIT}
            design="btn-secondary-gray"
            size="btn-lg"
            disabled={selectedWarrantiesCount < 1 ||
                selectedWarrantiesCount > DOWNLOAD_LIMIT ||
                isInactiveWarrantySelected}
            onClick={handleDownload}
            data-test={`${dataTest}-download-button`}
        >
            <IconDownload/>
        </Button>

        {auth.user?.isAdminOrStaff && <Button
            size="btn-lg"
            href="/warranties/create"
            className="text-center whitespace-nowrap"
            data-test={`${dataTest}-create-button`}
        >
            Create Warranty
        </Button>}
    </>
}
