import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, IconRefreshCw01, Tooltip } from '@/components'
import { useToastNotifications } from '@/hooks'
import { ToastNotification, Warranty } from '@/models'

type RefreshMonitoringButtonProps = {
    warranty: Warranty
    onChange: () => void
}

const RefreshMonitoringButton = ({ warranty, ...props }: RefreshMonitoringButtonProps) => {
    const location = useLocation()
    const { success } = useToastNotifications()
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const [notification, setNotification] = useState<ToastNotification | null>(null)
    const [processing, setProcessing] = useState(false)

    const handleRefresh = async () => {
        await warranty.monitoringUpdate()
        await props.onChange()
        intervalRef.current = setInterval(props.onChange, 5000)
    }

    const handleManualRefresh = async () => {
        setProcessing(true)
        try {
            await handleRefresh()
            const notification = success('Update Queued: Your request for fresh data is being processed. The page will automatically refresh with the latest information shortly.', undefined, 'permanent')
            setNotification(notification)
        } finally {
            setProcessing(false)
        }
    }

    const handleAutoRefresh = async () => {
        if (warranty.refresh_allowed && location.pathname.includes('monitoring/energy-graph')) {
            await handleRefresh()
            success('Monitoring data is being refreshed and will update automatically.')
        }
    }

    useEffect(() => {
        handleAutoRefresh()
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current)
        }
    }, [])

    useEffect(() => () => {
        notification?.close()
    }, [notification])

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
            notification?.close()
            success('System successfully refreshed.')
        }
    }, [warranty.homeowner.monitoring_data?.raw.updated_at])

    return <Tooltip
        interactive
        disabled={warranty.refresh_allowed}
        content={<>
            Refresh Limit Reached: Users are allowed a single daily refresh of system
            data to optimize system performance and manage monitoring expenses. This
            daily quota resets every 24 hours. Should you require additional data updates
            within the same day, please view the most recent data in the system history or
            log into the manufacturer&lsquo;s portal for real-time information. For further
            assistance, contact your account manager or <a href="mailto:monitoring@solarinsure.com">monitoring@solarinsure.com.</a>
        </>}>
        <Button
            design="btn-secondary-gray"
            onClick={handleManualRefresh}
            disabled={!warranty.refresh_allowed}
            processing={processing}
            className="gap-2"
        >
            <IconRefreshCw01/> Refresh Monitoring
        </Button>
    </Tooltip>
}

export default RefreshMonitoringButton
