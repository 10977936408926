import classnames from 'classnames'
import { ReactNode, useMemo } from 'react'

import { Pagination, Select } from '@/components'

type TFooterProps = {
    total: number,
    page: number
    perPage: number
    nextButtonText?: ReactNode
    prevButtonText?: ReactNode
    onChange: (name: string, value: string) => void
    showPerPage?: boolean
    'data-test'?: string
}

const PER_PAGE_OPTIONS = [
    5,
    10,
    15,
    25,
    35,
    50
]

const TFooter = ({
    total,
    perPage,
    showPerPage = true,
    page,
    nextButtonText,
    prevButtonText,
    'data-test': dataTest,
    ...props
}: TFooterProps) => {
    const perPageOptions = useMemo(() => PER_PAGE_OPTIONS.includes(perPage)
        ? PER_PAGE_OPTIONS
        : [...PER_PAGE_OPTIONS, perPage].sort((a, b) => a - b), [perPage])
    if (total <= perPageOptions[0]) return null

    const className = classnames('datatable-footer', { 'show-per-page': showPerPage })
    return <div className={className}>
        <Pagination
            current={page}
            perPage={perPage}
            total={total}
            nextButtonText={nextButtonText}
            prevButtonText={prevButtonText}
            onChange={e => props.onChange(e.target.name, e.target.value as string)}
            data-test={dataTest}
        />
        {showPerPage &&
            <Select
                id="per-page-select"
                input-size="sm"
                options={perPageOptions.map(item => ({
                    value: item,
                    title: item
                }))}
                value={perPage}
                name="per_page"
                onChange={e => props.onChange(e.target.name, e.target.value)}
                data-test={`${dataTest}-per-page-select`}
            />}
    </div>
}

export default TFooter
