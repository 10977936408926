import { useState, MouseEvent } from 'react'

import { Button, Form, FormChangeEvent, FormErrorsType, TextArea } from '@/components'
import { Article } from '@/models'
import { isApiError } from '@/services'

const VoteForm = ({ article }: { article: Article }) => {
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [showThankYou, setShowThankYou] = useState(false)
    const [form, setForm] = useState({
        feedback: '',
        voted_helpful: ''
    })

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        handleChange({
            target: {
                name: e.currentTarget.name,
                value: e.currentTarget.value
            }
        })
    }

    const handleSubmit = async () => {
        setProcessing(true)
        try {
            await article.feedback(form)
            setShowThankYou(true)
        } catch (err) {
            if (!isApiError(err)) throw err
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }
    return showThankYou ? <div className="text-lg">Thank you for your feedback!</div> : <Form onSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row items-center">
            <span className="text-lg font-light">Was this tutorial helpful?</span>
            <div className="flex gap-4 md:ml-2">
                <Button
                    design="btn-secondary"
                    onClick={handleClick}
                    processing={processing}
                    value="1"
                    name="voted_helpful"
                    className="font-light"
                >
                    Yes, Thank You
                </Button>
                <Button
                    design="btn-secondary-gray"
                    onClick={handleClick}
                    processing={processing}
                    value="0"
                    name="voted_helpful"
                    type="button"
                    className="font-light"
                >
                    No, Not Really
                </Button>
            </div>
        </div>
        {form.voted_helpful === '0' && <>
            <TextArea
                rows={6}
                id="feedback"
                name="feedback"
                placeholder="Your feedback"
                onChange={handleChange}
                value={form.feedback}
                errors={errors.feedback}
                className="mt-4"
            />
            <Button type="submit" className="w-full mt-4" disabled={processing}>Save</Button>
        </>}
    </Form>
}

export default VoteForm
