import { User } from './User'
import { Company } from '@/models/Company'
import { api, cookies } from '@/services'
import { AuthUserDataType } from '@/types'

export class AuthUser extends User {
    company: Company

    raw: AuthUserDataType

    constructor(data: AuthUserDataType) {
        super(data)
        this.raw = data
        this.company = new Company(data.company)
    }

    get isAuthenticated() {
        return !!cookies.get('api_token')
    }

    get isImpersonated() {
        return this.email === User.impersonateEmail
    }

    toJSON() {
        return JSON.stringify(this.raw)
    }

    static async signIn(payload: { email: string, password: string }) {
        const { data } = await api.auth.signIn(payload)
        cookies.set('api_token', data.api_token)
        const { data: user } = await api.auth.user()
        return { user: new AuthUser(user), apiToken: data.api_token }
    }

    static async forgotPassword(payload: { email: string }) {
        const { data } = await api.auth.forgotPassword(payload)
        return data
    }

    static async resetPassword(payload: { email: string }) {
        const { data } = await api.auth.resetPassword(payload)
        return data
    }

    static async currentUser() {
        const { data: user } = await api.auth.user()
        return new AuthUser(user)
    }
}
