import { WarrantyStatusDataType } from '@/types'

export enum WarrantyStatusEnum {
    EDIT_REQUESTED = 'edit_requested',
    ACTIVE = 'active',
    POTENTIAL_DUPLICATE = 'potential_duplicate',
    CANCELLATION_REQUESTED = 'cancellation_requested',
    REINSTATEMENT_REQUESTED = 'reinstatement_requested',
    CANCELED = 'canceled',
    UNKNOWN = 'unknown'
}

export const WARRANTY_STATUSES: WarrantyStatusDataType[] = [
    {
        key: WarrantyStatusEnum.EDIT_REQUESTED,
        title: 'Edit Requested',
        color: 'yellow',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.ACTIVE,
        title: 'Active',
        color: 'success',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.POTENTIAL_DUPLICATE,
        title: 'Potential Duplicate',
        color: 'orange',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.CANCELLATION_REQUESTED,
        title: 'Cancellation Requested',
        color: 'gray',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.REINSTATEMENT_REQUESTED,
        title: 'Reinstatement Requested',
        color: 'indigo',
        isDefault: true,
        isInactive: true
    },
    {
        key: WarrantyStatusEnum.CANCELED,
        title: 'Canceled',
        color: 'error',
        isDefault: false,
        isInactive: true
    },
    {
        key: WarrantyStatusEnum.UNKNOWN,
        title: 'Unknown',
        color: 'gray',
        isDefault: false,
        isInactive: true
    }
]
