import { ReactNode } from 'react'

import { DeviceCategory } from './DeviceCategory'
import { Badge, IconAlertCircle, IconCheckCircle, IconMinusCircle } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { Model } from '@/models/Model'
import { DeviceDataType } from '@/types'

export class Device extends Model {
    status: string

    type?: string

    category: DeviceCategory

    form?: string

    serial_number?: string

    last_report_at?: Date

    state?: string

    model?: string

    connected_to?: string

    name?: string

    manufacturer?: string

    deviceRcpn?: string

    serialNumber?: string

    pvlRatedCurrentA?: string

    pvlRatedPowerWDC?: string

    constructor(data: DeviceDataType) {
        super({ id: data.name || data.deviceRcpn || data.serial_number as string, ...data })
        this.status = data.category.deviceType.defaultStatus || data.status
        this.category = data.category
        this.deviceRcpn = data.deviceRcpn
        this.form = data.form
        this.serial_number = data.serial_number
        this.type = data.type
        this.last_report_at = Model.toDate(data.last_report_at)
        this.state = data.state
        this.model = data.model
        this.connected_to = data.connectedTo

        this.name = data.name
        this.manufacturer = data.manufacturer
        this.deviceRcpn = data.deviceRcpn
        this.serialNumber = data.serialNumber
        this.pvlRatedCurrentA = data.pvlRatedCurrentA
        this.pvlRatedPowerWDC = data.pvlRatedPowerWDC
    }

    get isNormal() {
        return ['normal', 'success'].includes(this.status) && !this.alert
    }

    get isDisabled() {
        return this.status === 'disabled' && !this.alert
    }

    get isErrored() {
        return !this.isDisabled && !this.isNormal
    }

    get alert() {
        return this.category.system.other?.device_alerts?.find(item => item.deviceRcpn === this.deviceRcpn)
    }

    get alertCode() {
        return this.alert ? <Badge className="bg-error-100 capitalize mr-1 flex text-center w-fit">
            {this.alert.stateName} ({this.alert.state.toString(16)})
        </Badge> : ''
    }

    get isVirtual() {
        return this.form === 'virtual'
    }

    get statusBadge() {
        if (this.alertCode) {
            return this.alertCode
        }
        if (this.isNormal) {
            return <Badge className="bg-success-100 capitalize">{this.status}</Badge>
        } else if (this.isDisabled) {
            return <Badge className="bg-gray-100 capitalize">{this.status}</Badge>
        }
        return <Badge className="bg-error-100 capitalize">{this.status?.toLowerCase() === 'disconnected' ? this.status : 'Error'}</Badge>
    }

    get statusIcon() {
        if (this.isNormal) {
            return Device.getIcon('normal')
        } else if (this.isDisabled) {
            return Device.getIcon('disabled')
        }
        return Device.getIcon('error')
    }

    static getIcon(type: 'normal' | 'error' | 'disabled', size: IconSize = 'sm', circle = true, icon?: ReactNode) {
        let circleSize = 'w-8 h-8'
        let circleColor = ''
        if (size === 'xs') {
            circleSize = 'w-5 h-5'
        }
        if (type === 'normal') {
            icon = icon || <IconCheckCircle className="stroke-success-600" size={size}/>
            circleColor = 'bg-success-100'
        }
        if (type === 'error') {
            icon = icon || <IconAlertCircle className="stroke-error-600" size={size}/>
            circleColor = 'bg-error-100'
        }
        if (type === 'disabled') {
            icon = icon || <IconMinusCircle className="stroke-gray-600" size={size}/>
            circleColor = 'bg-gray-200'
        }
        if (circle) {
            return <div className={`${circleSize} ${circleColor} rounded-full flex justify-center items-center`}>
                {icon}
            </div>
        }
        return icon
    }
}
