import { DeviceAttributeEnum } from '@/enums/DeviceAttributeEnum'
import { DeviceType } from '@/models'

export enum DeviceTypeEnum {
    GATEWAYS = 'gateways',
    METERS = 'meters',
    ENPOWERS = 'enpowers',
    ENCHARGES = 'encharges',
    MICROS = 'micros',
    SENSORS = 'sensors',
    BATTERIES = 'batteries',
    INVERTERS = 'inverters',
    PVLS = 'pvls',
    LOADCONTROLLERS = 'loadcontrollers',
    GENERATORS = 'generators',
    RGMS = 'rgms',
    BEACONS = 'beacons',
    ICMS = 'icms',
    UNKNOWN = 'unknown'
}

export const ENPHASE_DEVICE_TYPES: DeviceType[] = [
    { key: DeviceTypeEnum.GATEWAYS, title: 'Gateways' },
    { key: DeviceTypeEnum.METERS, title: 'Meters' },
    { key: DeviceTypeEnum.ENPOWERS, title: 'Enpower System Controllers' },
    { key: DeviceTypeEnum.ENCHARGES, title: 'Encharge Batteries' },
    { key: DeviceTypeEnum.MICROS, title: 'Micros' }
]

export const SOLAREDGE_DEVICE_TYPES: DeviceType[] = [
    { key: DeviceTypeEnum.GATEWAYS, title: 'Gateways' },
    { key: DeviceTypeEnum.METERS, title: 'Meters' },
    { key: DeviceTypeEnum.SENSORS, title: 'Sensors' },
    { key: DeviceTypeEnum.BATTERIES, title: 'Batteries' },
    { key: DeviceTypeEnum.MICROS, title: 'Inverters' }
]

export const GENERAC_DEVICE_TYPES: DeviceType[] = [
    {
        key: DeviceTypeEnum.INVERTERS,
        title: 'Inverters',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.SERIAL_NUMBER, title: 'Serial Number' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' },
            { key: DeviceAttributeEnum.PVL_RATED_POWER_WDC, title: 'Rated Power (W, DC)' },
            { key: DeviceAttributeEnum.STATUS_BADGE, title: 'Status' }
        ]
    },
    {
        key: DeviceTypeEnum.PVLS,
        title: 'PV Links',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' },
            { key: DeviceAttributeEnum.PVL_RATED_CURRENT_A, title: 'Rated Current (A)' },
            { key: DeviceAttributeEnum.PVL_RATED_POWER_WDC, title: 'Rated Power (W, DC)' },
            { key: DeviceAttributeEnum.STATUS_BADGE, title: 'Status' }
        ]
    },
    {
        key: DeviceTypeEnum.BATTERIES,
        title: 'Batteries',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' },
            { key: DeviceAttributeEnum.PVL_RATED_CURRENT_A, title: 'Rated Charge Capacity (A)' },
            { key: DeviceAttributeEnum.PVL_RATED_POWER_WDC, title: 'Rated Power (W, DC)' },
            { key: DeviceAttributeEnum.STATUS_BADGE, title: 'Status' }
        ]
    },
    {
        key: DeviceTypeEnum.LOADCONTROLLERS,
        title: 'Load Controllers',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' },
            { key: DeviceAttributeEnum.STATUS_BADGE, title: 'Status' }
        ]
    },
    {
        key: DeviceTypeEnum.GENERATORS,
        title: 'Generators',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' }
        ]
    },
    {
        key: DeviceTypeEnum.RGMS,
        title: 'RGMs',
        defaultStatus: 'disabled',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' },
            { key: DeviceAttributeEnum.SERIAL_NUMBER, title: 'Serial Number' }
        ]
    },
    {
        key: DeviceTypeEnum.BEACONS,
        title: 'Beacons',
        defaultStatus: 'disabled',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' }
        ]
    },
    {
        key: DeviceTypeEnum.ICMS,
        title: 'ICMs',
        defaultStatus: 'disabled',
        attributes: [
            { key: DeviceAttributeEnum.DEVICE_RCPN, title: 'Device RCP' },
            { key: DeviceAttributeEnum.MANUFACTURER, title: 'Manufacturer' },
            { key: DeviceAttributeEnum.NAME, title: 'Device Name' }
        ]
    }
]

export const DEFAULT_DEVICE_TYPE: DeviceType = {
    key: DeviceTypeEnum.UNKNOWN,
    title: 'Unknown'
}
