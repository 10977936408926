import {
    IconFlipBackward,
    IconXCircle
} from '@/components'
import {
    WarrantyEditDetails,
    WarrantyRequestHandled,
    PotentialDuplicateWarrantyNotice,
    WarrantyStatusChange
} from '@/components/warranty-histories'
import { WarrantyHistoryType } from '@/types'

export enum WarrantyHistoryTypeEnum {
    WARRANTY_CREATED = 'warranty_created',
    WARRANTY_EDITED = 'warranty_edited',
    WARRANTY_UPDATED = 'warranty_updated',
    WARRANTY_EDIT_REQUESTED = 'warranty_edit_requested',
    WARRANTY_EDIT_DENIED = 'warranty_edit_denied',
    WARRANTY_EDIT_APPROVED = 'warranty_edit_approved',
    WARRANTY_EDIT_MODIFIED_AND_APPROVED = 'warranty_edit_modified_and_approved',
    WARRANTY_EDIT_REQUEST_CANCELLED = 'warranty_edit_request_cancelled',
    WARRANTY_CANCELED = 'warranty_canceled',
    WARRANTY_CANCELLATION_REQUESTED = 'warranty_cancellation_requested',
    WARRANTY_CANCELLATION_REQUEST_DENIED = 'warranty_cancellation_request_denied',
    WARRANTY_CANCELLATION_REQUEST_APPROVED = 'warranty_cancellation_request_approved',
    WARRANTY_CANCELLATION_REQUEST_CANCELLED = 'warranty_cancellation_request_cancelled',
    WARRANTY_REINSTATED = 'warranty_reinstated',
    WARRANTY_REINSTATEMENT_REQUESTED = 'warranty_reinstatement_requested',
    WARRANTY_REINSTATEMENT_REQUEST_DENIED = 'warranty_reinstatement_request_denied',
    WARRANTY_REINSTATEMENT_REQUEST_APPROVED = 'warranty_reinstatement_request_approved',
    WARRANTY_REINSTATEMENT_REQUEST_CANCELLED = 'warranty_reinstatement_request_cancelled',
    POTENTIAL_DUPLICATE_FOUND = 'potential_duplicate_found',
    DUPLICATE_RESOLVED = 'duplicate_resolved',
    AFFILIATE_CHANGED = 'affiliate_changed'
}

export const WARRANTY_HISTORY_EVENT_TYPES: WarrantyHistoryType[] = [
    { key: WarrantyHistoryTypeEnum.WARRANTY_CREATED },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_EDITED,
        Component: WarrantyEditDetails
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_UPDATED,
        Component: WarrantyEditDetails
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_EDIT_REQUESTED,
        Component: WarrantyEditDetails
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_EDIT_DENIED,
        title: 'edit',
        Component: WarrantyRequestHandled
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_EDIT_APPROVED,
        title: 'edit',
        action: 'edited',
        Component: WarrantyRequestHandled
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_EDIT_MODIFIED_AND_APPROVED,
        Component: WarrantyEditDetails
    },
    { key: WarrantyHistoryTypeEnum.WARRANTY_EDIT_REQUEST_CANCELLED },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_CANCELED,
        title: 'cancellation',
        icon: <IconXCircle className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_CANCELLATION_REQUESTED,
        title: 'cancellation',
        icon: <IconXCircle className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_CANCELLATION_REQUEST_DENIED,
        title: 'cancellation',
        action: 'cancelled',
        Component: WarrantyRequestHandled
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_CANCELLATION_REQUEST_APPROVED,
        title: 'cancellation',
        action: 'cancelled',
        Component: WarrantyRequestHandled
    },
    { key: WarrantyHistoryTypeEnum.WARRANTY_CANCELLATION_REQUEST_CANCELLED },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_REINSTATED,
        title: 'reinstatement',
        icon: <IconFlipBackward className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_REINSTATEMENT_REQUESTED,
        title: 'reinstatement',
        icon: <IconFlipBackward className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_REINSTATEMENT_REQUEST_DENIED,
        title: 'reinstatement',
        action: 'reinstated',
        Component: WarrantyRequestHandled
    },
    {
        key: WarrantyHistoryTypeEnum.WARRANTY_REINSTATEMENT_REQUEST_APPROVED,
        title: 'reinstatement',
        action: 'reinstated',
        Component: WarrantyRequestHandled
    },
    { key: WarrantyHistoryTypeEnum.WARRANTY_REINSTATEMENT_REQUEST_CANCELLED },
    {
        key: WarrantyHistoryTypeEnum.POTENTIAL_DUPLICATE_FOUND,
        Component: PotentialDuplicateWarrantyNotice
    },
    { key: WarrantyHistoryTypeEnum.DUPLICATE_RESOLVED },
    {
        key: WarrantyHistoryTypeEnum.AFFILIATE_CHANGED,
        Component: WarrantyEditDetails
    }
]
