import Litepicker from 'litepicker'
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react'

import {
    IconCalendar,
    IconCalendarDate,
    IconClose,
    FormControl,
    FormControlProps,
    FormChangeEventHandler
} from '@/components'

type LitePickerOptions = {
    singleMode?: boolean
    maxDate?: Date
    minDate?: Date
}

export type DateRange = {
    start: Date | null
    end: Date | null
}

interface Props extends FormControlProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    value: Date | DateRange
    onChange: FormChangeEventHandler
    options?: LitePickerOptions
    clearable?: boolean
}

export const DatePicker = ({
    id,
    label,
    errors = [],
    warnings = [],
    hint,
    preIcon,
    postIcon,
    options = { singleMode: true },
    clearable,
    value,
    onChange,
    transparent,
    tooltip,
    'input-size': size,
    'data-test': dataTest,
    ...props
}: Props) => {
    dataTest = dataTest || id
    const inputRef = useRef(null)
    let instance: Litepicker | null = null
    const [inst, setInst] = useState<Litepicker | null>(null)

    const handleChange = ({ start, end }: { start: Date, end: Date }) => {
        if (options.singleMode) {
            if (
                !!start !== !!(value as DateRange).start ||
                start.toISODate() !== (value as Date).toISODate()
            ) {
                onChange({
                    target: {
                        name: props.name as string,
                        value: start
                    }
                })
            }
        } else {
            const rangeValue = value as DateRange
            if (
                !!start !== !!rangeValue.start ||
                !!end !== !!rangeValue.end ||
                start.toISODate() !== rangeValue.start?.toISODate() || end.toISODate() !== rangeValue.end?.toISODate()
            ) {
                onChange({
                    target: {
                        value: { start, end },
                        name: props.name as string
                    }
                })
            }
        }
    }

    const init = () => {
        if (!inputRef.current) return
        instance = new Litepicker({
            element: inputRef.current,
            autoApply: true,
            format: 'MMM DD, YYYY',
            dropdowns: { minYear: 2000, maxYear: 2100, months: true, years: true },
            startDate: options.singleMode ? value as Date : (value as DateRange).start || undefined,
            endDate: options.singleMode ? undefined : (value as DateRange).end || undefined,
            buttonText: {
                apply: 'Apply',
                cancel: 'Cancel',
                previousMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.5 15-5-5 5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                nextMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.5 15 5-5-5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                reset: 'Reset'
            },
            setup: picker => {
                picker.on('render', ui => {
                    ui.querySelectorAll('.month-item-weekdays-row').forEach((item: HTMLDivElement) => {
                        item.querySelectorAll('div').forEach((item: HTMLDivElement, index: number) => {
                            item.innerHTML = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat', 'Su'][index]
                        })
                    })
                })
                picker.on('selected', (start, end) => {
                    handleChange({ start: start.toJSDate(), end: end?.toJSDate() })
                })
            },
            ...options
        })
        setInst(instance)
    }

    useEffect(() => {
        init()
        return () => {
            instance?.destroy()
        }
    }, [])

    useEffect(() => {
        if (inst) {
            if (options.singleMode) {
                const singleValue = value as Date
                const date = inst.getDate()?.toJSDate()
                if (value) {
                    if (singleValue.toISODate() !== date?.toISODate()) {
                        inst.setDate(value)
                    }
                } else if (date) {
                    inst.clearSelection()
                }
            } else {
                const rangeValue = value as DateRange
                const start = inst.getStartDate()?.toJSDate()
                const end = inst.getEndDate()?.toJSDate()
                if (rangeValue.start && rangeValue.end) {
                    if (rangeValue.start.toISODate() !== start?.toISODate() ||
                        rangeValue.end.toISODate() !== end?.toISODate()) {
                        inst.setDateRange(rangeValue.start, rangeValue.end)
                    }
                } else if (start || end) {
                    inst.clearSelection()
                }
            }
        }
    }, [value])

    const getPostIcon = () => {
        if (clearable) {
            const rangeValue = value as DateRange
            if ((options.singleMode && value) || (!options.singleMode && (rangeValue.start || rangeValue.end))) {
                return <button
                    data-test={`${dataTest}-clear-icon`}
                    onClick={() => {
                        inst?.clearSelection()
                        onChange({
                            target: {
                                value: {
                                    start: null,
                                    end: null
                                },
                                name: props.name as string
                            }
                        })
                    }}
                >
                    <IconClose className="stroke-gray-500"/>
                </button>
            }
            return postIcon
        }
        return <IconCalendarDate className="stroke-gray-500"/>
    }

    return <FormControl
        id={id}
        label={label}
        data-test={dataTest}
        preIcon={clearable ? <IconCalendar className="stroke-gray-500"/> : preIcon}
        postIcon={getPostIcon()}
        errors={errors}
        warnings={warnings}
        input-size={size}
        transparent={transparent}
        tooltip={tooltip}
        className="form-control-datepicker"
    >
        <input
            id={id}
            ref={inputRef}
            autoComplete="off"
            data-test={`${dataTest}-input`}
            {...props}
        />
    </FormControl>
}
