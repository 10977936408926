import { Badge, IconCheck } from '@/components'
import { WARRANTY_STATUSES, WarrantyStatusEnum } from '@/enums'
import { WarrantyStatusColorType, WarrantyStatusDataType } from '@/types'

const COLOR_MAP = {
    yellow: {
        className: 'bg-yellow-50',
        activeClassName: 'border-yellow-500',
        iconClassName: 'stroke-yellow-500 bg-yellow-500'
    },
    success: {
        className: 'bg-success-50',
        activeClassName: 'border-success-500',
        iconClassName: 'stroke-success-500 bg-success-500'
    },
    orange: {
        className: 'bg-orange-50',
        activeClassName: 'border-orange-500',
        iconClassName: 'stroke-orange-500 bg-orange-500'
    },
    gray: {
        className: 'bg-gray-100',
        activeClassName: 'border-gray-500',
        iconClassName: 'stroke-gray-500 bg-gray-500'
    },
    indigo: {
        className: 'bg-indigo-50',
        activeClassName: 'border-indigo-500',
        iconClassName: 'stroke-indigo-500 bg-indigo-500'
    },
    error: {
        className: 'bg-error-50',
        activeClassName: 'border-error-500',
        iconClassName: 'stroke-error-500 bg-error-500'
    }
}

export class WarrantyStatus {
    key: WarrantyStatusEnum

    color: WarrantyStatusColorType

    title: string

    isDefault: boolean

    isInactive: boolean


    constructor(data: WarrantyStatusDataType) {
        this.key = data.key
        this.color = data.color
        this.title = data.title
        this.isDefault = !!data.isDefault
        this.isInactive = !!data.isInactive
    }

    get isActive() {
        return this.key === WarrantyStatusEnum.ACTIVE
    }

    get isDuplicate() {
        return this.key === WarrantyStatusEnum.POTENTIAL_DUPLICATE
    }

    get isCanceled() {
        return this.key === WarrantyStatusEnum.CANCELED
    }

    get isCancellationRequested() {
        return this.key === WarrantyStatusEnum.CANCELLATION_REQUESTED
    }

    get isReinstatementRequested() {
        return this.key === WarrantyStatusEnum.REINSTATEMENT_REQUESTED
    }

    get isEditRequested() {
        return this.key === WarrantyStatusEnum.EDIT_REQUESTED
    }

    static get all() {
        return WARRANTY_STATUSES.map(item => new WarrantyStatus(item))
    }

    static find(key: WarrantyStatusEnum) {
        return WarrantyStatus.all.find(item => item.key === key) || WarrantyStatus.defaultStatus as WarrantyStatus
    }

    static get defaultStatuses() {
        return WarrantyStatus.all
            .filter(item => item.isDefault)
            .map(item => item.key)
    }

    static get defaultStatus() {
        return WarrantyStatus.all.find(item => item.key === WarrantyStatusEnum.UNKNOWN)
    }

    badge(isActive = false, showIcon = true) {
        const {
            className,
            activeClassName,
            iconClassName
        } = COLOR_MAP[this.color]
        return <Badge
            className={`text-xs text-gray-800 flex gap-1.5 items-center text-center ${className} ${isActive ? `${activeClassName} border` : ''}`}
        >
            {showIcon && (isActive
                ? <IconCheck className={`${iconClassName} !bg-transparent`} size="xs"/>
                : <div className="w-3 h-3 flex items-center justify-center">
                    <div className={`${iconClassName} w-1.5 h-1.5 rounded-full`}/>
                </div>)}
            {this.title}
        </Badge>
    }
}
