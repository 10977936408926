import { IconClockSnoozed, IconLoading01, IconSearch, Button } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'
import { SystemStatusDataType } from '@/types'

export const SYSTEM_STATUSES_UNMONITORED: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.PENDING,
        title: 'Connection Pending',
        refreshable: true,
        icon: (size: IconSize) => <IconLoading01 size={size}/>,
        description: 'Initial status for newly registered warranties, indicating the initial efforts to match with the manufacturer\'s database, typically transitioning to a Connected Site status within two weeks.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    Your solar system <span className="font-semibold">monitoring is in the setup process.</span>
                </li>
                <li>
                    <span className="font-semibold">No immediate action</span> is needed as setup is expected to complete within two weeks.
                </li>
                <li>
                    <span className="font-semibold">Monitor</span> the status periodically in the manufacturer&apos;s monitoring portal.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.DELAYED,
        title: 'Connection Delayed',
        refreshable: true,
        icon: (size: IconSize) => <IconClockSnoozed size={size}/>,
        description: 'Indicates a site remains unmonitored beyond 10 days or after three connection attempts, suggesting matching difficulties; resolution can be expedited by verifying address details or providing the system ID.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;</span> on the <span className="font-semibold">Solar Insure platform.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">&quot;Edit Warranty&quot;</span> option to access and <span className="font-semibold">modify</span> the warranty registration details.
                </li>
                <li>
                    <span className="font-semibold">Ensure</span> that the <span className="font-semibold">Manufacturer System Identifier</span> is correctly populated. This field, though not mandatory during initial registration, can significantly increase the success rate of system connection.
                </li>
                <li>
                    <span className="font-semibold">Review</span> all registration details, particularly the address, to ensure they are accurate and match the address listed for the same site on the manufacturer&apos;s portal.
                </li>
                <li>
                    <span className="font-semibold">Verify</span> that the inverter manufacturer is correctly identified in the registration.
                </li>
                <li>
                    If all details are correct and the issue persists, <span className="font-semibold">contact Solar Insure customer support</span> for further assistance.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM_NOT_FOUND,
        title: 'System Not Found',
        refreshable: true,
        icon: (size: IconSize) => <IconSearch size={size}/>,
        description: 'We were unable to locate the system ID registered for this warranty in the inverter manufacturer\'s database. This may be due to incorrect entry or data sharing issues. Please verify the system ID and/or ensure Solar Insure is authorized as a subcontractor in the manufacturer\'s monitoring portal.',
        troubleshooting: {
            generac: <div className="font-normal text-sm p-3 bg-blue-50 text-gray-700 rounded font-body">
                <span className="font-semibold text-gray-800">For Generac Systems</span>
                <div className="my-4">Your company successfully associated your PWRfleet account with Solar Insure at the company level, but we were unable to view details for this specific site. This most often occurs when a company forgets to share an individual site or neglects to add a site ID to the warranty registration, hindering Solar Insure’s ability to locate it in PWRfleet. You must share access with Solar Insure at the fleet and site level for data to appear on the Solar Insure platform.</div>
                <span className="font-semibold text-gray-800">Step One: Add a Manufacturer System ID to the Warranty Details</span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;.</span>
                    </li>
                    <li>
                        <span className="font-semibold">Click</span> on the <span className="font-semibold">&quot;Edit Warranty&quot;</span> option to access and modify the warranty registration details.
                    </li>
                    <li>
                        Make sure that the Manufacturer System Identifier is populated and correct.
                        This should be populated with the site ID for the related installation found
                        in your <a href="https://pwrfleet.generac.com/login" target="_blank" rel="noreferrer" className="text-primary-700 underline">PWRfleet portal.</a>
                        <div>
                            a. In PWRfleet, Navigate to the &quot;All Sites&quot; list to find the related system.
                        </div>
                        <div>
                            b. Click on the system to open its details page.
                        </div>
                        <div>
                            c. The Site ID can be found in the URL of the site. It is the unique identifier
                            after /sites/ in the URL.
                        </div>
                    </li>
                    <li>
                        Update the ID on Solar Insure Daybreak Platform and save the changes.
                    </li>
                </ol>
                <div className="font-semibold my-4 text-gray-800">Step Two: Verify that the Individual Site is Shared with Solar Insure</div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Log into the <a href="https://pwrfleet.generac.com/login" target="_blank" rel="noreferrer" className="text-primary-700 underline">PWRfleet portal.</a> portal.</li>
                    <li>Navigate to the &quot;All Sites&quot; list to</li>
                        find the related system. Click on the system to open its details page.
                    <li>Click on the “Access” tab in the left sidebar.</li>
                    <li>Select “Share Site”</li>
                    <li>Select Solar Insure and click “Share”.</li>
                    <li>
                        Return to the Daybreak platform. Click on the refresh monitoring button
                        to initiate a new attempt to pull monitoring data.
                    </li>
                </ol>
                <Button design="btn-secondary" className="block text-center mt-4 w-fit" target="_blank" href="/resources/Generac_Fleet_&_Site_Level_Integrations.pdf">
                    Click here for a Visual Guide
                </Button>
            </div>,
            maintainer: {
                enphase: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700 font-body">
                    <span className="font-semibold">For Enphase Systems</span>
                    <div className="my-4">Your company has chosen to share monitoring data for all registered warranties using the Maintainer method in Enlighten. For this method, you need to individually set up data access for every individual system that is registered on the Daybreak platform. An authorization error most likely indicates that you have not yet set up access permissions to share monitoring data with Solar Insure. This system needs to be accessible in Solar Insure&apos;s Enlighten Maintainer Account to show up in Daybreak. Follow these steps to resolve the issue:</div>
                    <span className="font-semibold">Step One: Add a Manufacturer System ID to the Warranty Details</span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify
                            the warranty registration details.
                        </li>
                        <li>Make sure that the Manufacturer System Identifier is populated and correct. This should be populated with the System ID for the related installation found in your <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer" className="text-primary-700 underline">Enphase Enlighten Portal.</a>
                            <div>
                                a. In Enlighten, Navigate to the &ldquo;Systems&ldquo; list to find the related system.
                            </div>
                            <div>b. Click on the system to open its details page.</div>
                            <div>c. Click on the gear icon to navigate to the Settings tab.</div>
                            <div>d. The system ID can be found in the Overview card.</div>
                        </li>
                        <li>Update the ID on Solar Insure Daybreak Platform and save the changes.</li>
                    </ol>
                    <div className="font-semibold my-4">Step Two: Verify Maintainer Access</div>
                    <div className="my-4">If the System is New and Has Not Yet Been Activated in the Enlighten Portal:</div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer" className="text-primary-700 underline">Enphase Enlighten Portal.</a></li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view
                            its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>
                            Review the Activation checklist. If Step 4 is still not complete,
                            add Solar Insure as an Authorized Subcontractor.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to
                            initiate a new attempt to pull monitoring data.
                        </li>
                    </ol>
                    <div className="my-4">If the System Has Already Been Activated:</div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer" className="text-primary-700 underline">Enphase Enlighten Portal.</a></li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view
                            its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>Scroll down to the System Access section.</li>
                        <li>
                            In the &apos;Companies with Access&apos; section, add the email
                            monitoring@solarinsure.com in the Email Address input field. Select
                            that email address as an authorized subcontractor and hit save.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to
                            initiate a new attempt to pull monitoring data.
                        </li>
                    </ol>
                </div>
            },
            default: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;.</span>
                    </li>
                    <li>
                        <span className="font-semibold">Click</span> on the <span className="font-semibold">&quot;Edit Warranty&quot;</span> option to access and modify the warranty registration details.
                    </li>
                    <li>
                        Carefully verify that the Manufacturer System Identifier entered is correct.
                        This is crucial as an incorrect ID can lead to the system not being recognized
                        in the manufacturer&apos;s database.
                        <div className="pl-4 -indent-2">
                            i. To find the system ID for Enphase systems, log into your Enlighten portal and Navigate
                            to the &quot;Systems&quot; list to find the related system. Click on the system to open its
                            details page. Click on the gear icon to navigate to the Settings tab. The system ID can be
                            found in listed in the Overview card.
                        </div>
                        <div className="pl-4 -indent-2">
                            ii. To find the site ID for SolarEdge systems, log into your SolarEdge portal and Navigate
                            to the &quot;Sites&quot; list to find the related site. Click on the system to open its
                            details page. The Site ID is listed under the homowner name on the Site details card on
                            the right.
                        </div>
                    </li>
                    <li>
                        To ensure accuracy, <span className="font-semibold">cross</span>-reference this ID with the site listed in the manufacturer&apos;s monitoring portal.
                    </li>
                    <li>
                        <span className="font-semibold">Check</span> that the registration details include a defined inverter manufacturer, and confirm that this value is correct.
                    </li>
                    <li>
                        <span className="font-semibold">Save</span> any changes you make to the warranty details.
                    </li>
                    <li>
                        Click on the <span className="font-semibold">Refresh Monitoring</span> button to initiate a new attempt to pull monitoring data.
                    </li>
                </ol>
            </div>
        }
    }
]

