import { useEffect, useRef } from 'react'

import { Button, IconClose, Tooltip } from '@/components'
import { Notification } from '@/models'
import { JobsBatchType } from '@/types'

type NotificationItemProps = {
    item: Notification
    onChange: () => void
}

const NotificationItem = ({ item, ...props }: NotificationItemProps) => {
    const initialItemRef = useRef<Notification>(item)
    const handleClose = async (item: Notification) => {
        await item.destroy()
        props.onChange()
    }

    useEffect(() => {
        const initialJobData = initialItemRef.current.data['jobs-batch'] as JobsBatchType
        const currentJobData = item.data['jobs-batch'] as JobsBatchType

        if (item.type.onComplete && initialJobData.percentage < 100 && currentJobData.percentage === 100) {
            item.type.onComplete(item.data)
        }
        initialItemRef.current = item
    }, [item.data['jobs-batch']])

    return <div className="flex justify-between items-start gap-3 p-2 text-xs leading-[18px] font-body font-normal">
        <div className={`border border-gray-300 rounded-lg p-2 stroke-gray-700 ${item.read_at ? 'stroke-gray-400' : 'stroke-gray-700 bg-gray-200'}`}>
            {typeof item.type.icon === 'function'
                ? item.type.icon(item.data)
                : item.type.icon}
        </div>

        <div className="grow">
            <div className="text-gray-900">
                {item.message}
            </div>
            {item.type.showTimestamp && item.created_at && <p className="text-gray-500">
                {new Date(item.created_at.toISOString()).format({
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZoneName: 'short',
                    hour12: false
                })}
            </p>}
        </div>

        <Tooltip content="Clear Notification">
            <Button design="btn-link" onClick={() => handleClose(item)}>
                <IconClose className="stroke-gray-500" size="xs"/>
            </Button>
        </Tooltip>
    </div>
}

export default NotificationItem
