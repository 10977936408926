import classnames from 'classnames'
import { HTMLAttributes, ReactNode } from 'react'

import {
    Tooltip,
    InputWarning,
    InputError,
    ErrorsType,
    WarningsType,
    FormDataValueType,
    FormDataType
} from '@/components'

export type FormControlSize = 'sm' | 'md' | 'lg'

export const SIZE_CLASS_MAP = {
    sm: 'form-control-sm',
    md: 'form-control-md',
    lg: 'form-control-lg'
}
export const BASE_CLASS = 'form-control'

type EventTarget = {
    name: string
    value: FormDataValueType | FormDataType | FormDataValueType[] | FormDataType[]
    checked?: boolean | 0 | 1
    type?: string
    readonly dataset?: DOMStringMap
}

export type FormChangeEvent = {
    currentTarget?: EventTarget
    target: EventTarget
}

export type FormChangeEventHandler = (e: FormChangeEvent) => void

export interface FormControlProps {
    id?: string,
    label?: ReactNode
    'data-test'?: string
    tooltip?: ReactNode
    preIcon?: ReactNode
    postIcon?: ReactNode
    hint?: ReactNode
    errors?: ErrorsType | boolean
    warnings?: WarningsType | boolean
    transparent?: boolean
    'input-size'?: FormControlSize
}

interface Props extends FormControlProps, HTMLAttributes<HTMLDivElement> {}

export const FormControl = ({
    id,
    className,
    label,
    'input-size': size = 'md',
    hint,
    tooltip,
    errors = [],
    warnings = [],
    preIcon,
    postIcon,
    children,
    transparent,
    'data-test': dataTest,
    ...props
}: Props) => {
    const classNames = classnames(
        BASE_CLASS,
        SIZE_CLASS_MAP[size],
        {
            transparent,
            'has-error': errors === true || (errors && errors.length),
            'has-warning': warnings === true || (warnings && warnings.length),
            'has-pre-icon': preIcon,
            'has-post-icon': postIcon
        },
        className
    )

    dataTest = dataTest || id

    return <div className={classNames} data-test={dataTest} {...props}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}

        <div className={`${BASE_CLASS}-container`}>

            {preIcon && <label
                className={`${BASE_CLASS}-icon ${BASE_CLASS}-pre-icon`}
                data-test={`${dataTest}-pre-icon`}
                htmlFor={id}
            >
                {preIcon}
            </label>}
            {tooltip ? <Tooltip content={tooltip}>{children}</Tooltip> : children}
            {postIcon && <label
                className={`${BASE_CLASS}-icon ${BASE_CLASS}-post-icon`}
                data-test={`${dataTest}-post-icon`}
                htmlFor={id}
            >
                {postIcon}
            </label>}
        </div>

        {hint && <div className="text-primary-700 text-sm mt-1.5" data-test={`${dataTest}-hint`}>{hint}</div>}
        {errors && errors !== true && <InputError data-test={`${dataTest}-error`} errors={errors}/>}
        {warnings && warnings !== true && <InputWarning data-test={`${dataTest}-warning`} errors={warnings}/>}
    </div>
}
