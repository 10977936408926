import { InverterManufacturer } from './InverterManufacturer'
import { Model } from './Model'
import { InverterManufacturerEnum } from '@/enums'
import type { AddressDataType, IdType, SystemDetailsDataType } from '@/types'

export default class SystemDetails extends Model {
    homeowner_name: string

    initial_energy_date: Date

    installation_date: Date

    installer: string

    inverter_count: number

    inverter_make: string

    inverter_manufacturer: InverterManufacturer

    inverter_model: string

    location: AddressDataType

    panel_make: string

    panel_count: number

    panel_model: string

    pto_date: Date

    system_id: IdType

    system_name: string

    system_size: number


    constructor(data: SystemDetailsDataType) {
        super(data)

        this.homeowner_name = data.homeowner_name
        this.initial_energy_date = Model.toDate(data.initial_energy_date) as Date
        this.installation_date = Model.toDate(data.installation_date) as Date
        this.installer = data.installer
        this.inverter_count = Model.toNumber(data.inverter_count) as number
        this.inverter_make = data.inverter_make
        this.inverter_manufacturer = InverterManufacturer.find(
            data.inverter_manufacturer?.toLowerCase() as InverterManufacturerEnum)
        this.inverter_model = data.inverter_model
        this.location = data.location
        this.panel_make = data.panel_make
        this.panel_count = Model.toNumber(data.panel_count) as number
        this.panel_model = data.panel_model
        this.pto_date = Model.toDate(data.pto_date) as Date
        this.system_id = data.system_id
        this.system_name = data.system_name
        this.system_size = Model.toNumber(data.system_size) as number
    }

    get address() {
        if (!this.location) return ''
        return `${this.location.street_address}, ${this.location.city}, ${this.location.state} ${this.location.zip}`.replace(/(^[,\s]+|[,\s]+$)/g, '')
    }

    get securedSystemId() {
        if (!this.system_id) return '-'

        const systemId = this.system_id as string

        if (this.inverter_manufacturer.isGenerac && systemId.length > 8) {
            return `${systemId.slice(0, 4)}...${systemId.slice(-4)}`
        }

        return this.system_id
    }
}
