import { IconBatteryCharging, IconSun } from '@/components'
import { WarrantyProductTypeDataType } from '@/types'

export enum WarrantyProductTypeEnum {
    POWER_PRODUCTION_ONLY = 'power_production_only',
    PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE = 'production_with_20_year_separate_storage',
    PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE = 'production_with_30_year_separate_storage'
}

export const WARRANTY_PRODUCT_TYPES: WarrantyProductTypeDataType[] = [
    {
        key: WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY,
        title: 'SI-30 Solar',
        secondaryTitle: '30-Year Solar System Coverage',
        subtitle: '30-Year Solar Warranty',
        color: 'warning',
        IconComponent: IconSun
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE,
        title: 'SI-30:20 Legacy',
        secondaryTitle: '20-Year Battery Coverage',
        subtitle: '30-Year Solar & 20-Year Battery Warranty',
        color: 'purple',
        IconComponent: IconBatteryCharging
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE,
        title: 'SI-30 Total',
        secondaryTitle: '30-Year Battery Coverage',
        subtitle: '30-Year Solar & Battery Warranty',
        color: 'success',
        IconComponent: IconBatteryCharging
    }
]
