import { ArticleCategory } from './ArticleCategory'
import { Model } from './Model'
import { api, Payload } from '@/services'
import { ArticleDataType, IdType } from '@/types'

export class Article extends Model {
    static endpoint = 'api/v1/support/articles'

    raw: ArticleDataType

    category_id: IdType

    title: string

    content: string

    already_voted: boolean

    category: ArticleCategory

    constructor(data: ArticleDataType) {
        super(data)
        this.raw = data
        this.category_id = data.category_id
        this.title = data.title
        this.content = data.content
        this.category = new ArticleCategory(data.category)
        this.already_voted = data.already_voted
    }

    feedback(payload: Payload) {
        return api.support.feedback({ article_id: this.id, ...payload })
    }

    static deleteNotification(id: IdType) {
        return api.support.deleteNotification(id)
    }
}
