import { IconAlertCircle, IconCheck, IconHelpCircle, IconPasscodeLock, IconSlashCircle01, IconX } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { CompanyMonitoringStatusDataType } from '@/types'

export enum CompanyMonitoringStatusEnum {
    VALID = 'valid',
    MISSING = 'missing',
    INVALID = 'invalid',
    NOT_REQUIRED = 'not_required',
    INCOMPLETE = 'incomplete',
    UNKNOWN = 'unknown'
}

export const COMPANY_MONITORING_STATUSES: CompanyMonitoringStatusDataType[] = [
    {
        index: 0,
        key: CompanyMonitoringStatusEnum.MISSING,
        title: 'Missing Required Credentials',
        icon: (size: IconSize = 'md') => <IconX size={size} className="stroke-error-600"/>,
        color: 'text-error-500',
        secondaryColor: 'bg-error-100'
    },
    {
        index: 1,
        key: CompanyMonitoringStatusEnum.INVALID,
        title: 'Invalid Required Credentials',
        icon: (size: IconSize = 'md') => <IconPasscodeLock size={size} className="stroke-warning-600"/>,
        color: 'text-orange-700',
        secondaryColor: 'bg-warning-100'
    },
    {
        index: 2,
        key: CompanyMonitoringStatusEnum.NOT_REQUIRED,
        title: 'Credentials Not Required',
        icon: (size: IconSize = 'md') => <IconSlashCircle01 size={size} className="stroke-gray-600"/>,
        color: 'text-gray-600',
        secondaryColor: 'bg-gray-200'
    },
    {
        index: 3,
        key: CompanyMonitoringStatusEnum.VALID,
        title: 'Credentials Submitted',
        icon: (size: IconSize = 'md') => <IconCheck size={size} className="stroke-success-600"/>,
        color: 'text-success-600',
        secondaryColor: 'bg-success-100'
    },
    {
        index: 4,
        key: CompanyMonitoringStatusEnum.INCOMPLETE,
        title: 'Certification Setup Incomplete',
        subtitle: 'Submit Required Credentials',
        icon: (size: IconSize = 'md') => <IconAlertCircle size={size} className="stroke-primary-700"/>,
        color: 'text-primary-800',
        secondaryColor: 'bg-primary-100'
    },
    {
        index: 5,
        key: CompanyMonitoringStatusEnum.UNKNOWN,
        title: 'Unknown',
        icon: (size: IconSize = 'md') => <IconHelpCircle size={size} className="stroke-gray-600"/>,
        color: 'text-gray-600',
        secondaryColor: 'bg-gray-100'
    }
]
