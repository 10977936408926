import { useEffect, useState } from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router-dom'

import {
    Button,
    Card,
    IconEdit05,
    IconLifeBuoy,
    IconPlus,
    Popup,
    ArticleCreate,
    ArticleEdit,
    VoteForm, PageHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAppDispatch, useAuth } from '@/hooks'
import { Article, AuthUser } from '@/models'
import { cookies } from '@/services'
import { login } from '@/store'

const SupportShow = () => {
    const { code, id = '' } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const auth = useAuth()
    const { data } = useLoaderData() as any
    const [articles, setArticles] = useState<Article[]>(data)
    const [showCreatePopup, setShowCreatePopup] = useState(false)
    const [showEditPopup, setShowEditPopup] = useState(false)

    const fetchArticles = async () => {
        const { data } = await Article.index({ category: code })
        setArticles(data)
    }

    useEffect(() => {
        if (!id && articles.length) navigate(`/support/${code}/${data[0].id}`)
    }, [])

    const checkNotifications = async () => {
        const notification = auth.user?.article_notifications
            ?.find(({ article_id: articleId }: any) => articleId === parseInt(id))
        if (notification) {
            await Article.deleteNotification(notification.id)
            AuthUser.currentUser().then(user => {
                dispatch(login({ user, apiToken: cookies.get('api_token') }))
            })
        }
    }

    useEffect(() => {
        checkNotifications()
    }, [id])

    const article = articles.find(item => item.id === parseInt(id))
    const category = articles.length ? articles[0].category : null

    const breadcrumb = [
        <IconLifeBuoy key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/support">Support</Link>,
        category?.name
    ]

    return <AuthLayout heading={<PageHeader title={category?.name} backUrl="/support"/>} breadcrumb={breadcrumb}>
        {auth.user?.isAdminOrStaff &&
            <div className="flex justify-end">
                <Button onClick={() => setShowCreatePopup(true)}>
                    <IconPlus className="mr-2 stroke-black"/> Add
                </Button>
            </div>}

        <Card className="mt-8">
            {category && <div className="grid col-span-1 lg:grid-cols-12 gap-16">
                <div className="col-span-1 lg:col-span-3">
                    <h4 className="text-sm text-primary-700 font-semibold mb-5">{category.name}</h4>
                    {articles.map(item =>
                        <Link
                            key={item.id}
                            to={`/support/${code}/${item.id}`}
                            className={`block mb-2 py-2.5 px-3.5 hover:bg-primary-50 font-light rounded-md transition-colors ${item.id === parseInt(id) ? 'bg-primary-50 text-primary-700' : 'text-gray-500'}`}
                        >
                            {item.title}
                        </Link>)}
                </div>
                {article && <div className="col-span-1 lg:col-span-9 flex flex-col">
                    <h2 className="text-xl font-semibold flex justify-between">
                        {article.title}
                        {auth.user?.isAdminOrStaff &&
                            <Button design="btn-secondary-gray" onClick={() => setShowEditPopup(true)}><IconEdit05/></Button>}
                    </h2>
                    <div className="mt-8.5 ql-editor" dangerouslySetInnerHTML={{ __html: article.content }}/>

                    {!article.already_voted && <VoteForm article={article}/>}
                </div>}
            </div>}
        </Card>

        {auth.user?.isAdminOrStaff && <Popup open={showCreatePopup} onClose={() => setShowCreatePopup(false)}>
            {category && <ArticleCreate
                onComplete={() => {
                    setShowCreatePopup(false)
                    fetchArticles()
                }}
                category={category}
            />}
        </Popup>}

        {auth.user?.isAdminOrStaff && <Popup open={showEditPopup} onClose={() => setShowEditPopup(false)}>
            {article && <ArticleEdit
                article={article}
                onComplete={() => {
                    setShowEditPopup(false)
                    fetchArticles()
                }}
            />}
        </Popup>}
    </AuthLayout>
}

export default SupportShow
