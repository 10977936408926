import { IconAlertTriangle, IconBatteryLow, IconMicroInverter, IconModem, IconSliders, IconWifiOff } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'
import { SystemStatusDataType } from '@/types'

export const SYSTEM_STATUSES_MONITORED_TIER_2: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_6,
        title: 'Medium Impact Alert - Level 6',
        refreshable: true,
        secondaryOrder: 6,
        icon: () => 6,
        description: 'Alert with medium severity score, suggesting a moderate risk that may escalate without action. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_5,
        title: 'Medium Impact Alert - Level 5',
        refreshable: true,
        secondaryOrder: 7,
        icon: () => 5,
        description: 'Noticeable alert requiring attention, with medium impact on system performance. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_4,
        title: 'Medium Impact Alert - Level 4',
        refreshable: true,
        secondaryOrder: 8,
        icon: () => 4,
        description: 'Alert indicating a moderate issue, needs monitoring to avoid further system impact. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.MICRO,
        title: 'Microinverters Not Reporting',
        refreshable: true,
        secondaryOrder: 1,
        icon: (size: IconSize) => <IconMicroInverter size={size}/>,
        description: 'One or more microinverters are not communicating, potentially due to range or interference issues. This can usually be resolved remotely.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer">https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span> to investigate site details.
                </li>
                <li>
                    <span className="font-semibold">Ensure</span> the Gateway is on a <span className="font-semibold">dedicated circuit</span> near the electrical panel without electrical interference.
                </li>
                <li>
                    <span className="font-semibold">Disconnect</span> devices on the same outlet such as <span className="font-semibold">power strips, UPS units, touch lamps,</span> and <span className="font-semibold">battery chargers</span>.
                </li>
                <li>
                    <span className="font-semibold">Relocate</span> heavy-duty appliances and workshop equipment to other circuits.
                </li>
                <li>
                    <span className="font-semibold">Remove</span> any older CFLs to reduce interference and check if reporting resumes.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENCHARGE_ISSUE,
        title: 'Battery Issue',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconBatteryLow size={size}/>,
        description: 'Detected problem with battery, needs checking to ensure proper storage performance.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">Events Tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Investigate</span> battery-related issues by <span className="font-semibold">clicking</span> on the <span className="font-semibold">event name.</span>
                </li>
                <li>
                    <span className="font-semibold">Follow</span> the recommended actions from Enphase for resolution.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM,
        title: 'System Issue',
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconAlertTriangle size={size}/>,
        description: 'General alert for an undefined system problem that requires further investigation.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span> and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Events tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Check</span> for any non-normal statuses on the <span className="font-semibold">Devices tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Take note</span> of <span className="font-semibold">system alerts</span> and follow up with recommended actions.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENPOWER_ISSUE,
        title: 'System Controller Issue',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconSliders size={size}/>,
        description: 'The system control unit is reporting a malfunction, needs attention to maintain system integrity.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Go</span> to the <span className="font-semibold">Events Tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Look into</span> issues related to system controllers by <span className="font-semibold">selecting</span> the <span className="font-semibold">event name</span>.
                </li>
                <li>
                    <span className="font-semibold">Apply</span> the recommended actions provided by Enphase.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.GATEWAY,
        title: 'Gateway Non-Critical Issue',
        refreshable: true,
        secondaryOrder: 5,
        icon: (size: IconSize) => <IconModem size={size}/>,
        description: 'Assigned to gateway issues detected within the last 7 days. Gateway issues typically become critical if they persist for more than a week, suggesting ongoing component problems or alterations in the connection setup that require manual resolution.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>

                <li>
                    <span className="font-semibold">Navigate</span> to the<span className="font-semibold"> Devices</span> or <span className="font-semibold">Communications tab</span> to identify Gateway warnings.
                </li>
                <li>
                    Inspect any <span className="font-semibold">alerts or notifications</span> related to the Gateway.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the Gateway&apos;s physical connections and power source for any disruptions.
                </li>
                <li>
                    <span className="font-semibold">If the issue persists, reset</span> the Gateway by turning it off and on again.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">Installation and Operation Manual</span> for troubleshooting non-critical issues.
                </li>
                <li>
                    For persistent problems, <span className="font-semibold">contact Enphase Customer Support</span> to resolve the issue without affecting your system’s operation.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM_DISCONNECTED,
        title: 'System Disconnected',
        refreshable: true,
        secondaryOrder: 9,
        icon: (size: IconSize) => <IconWifiOff size={size}/>,
        description: 'One or more systems in this site have temporarily lost internet connectivity.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection type: <span className="font-semibold">Wi-Fi, Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to your <span className="font-semibold">ISP, network password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech modem, <span className="font-semibold">unplug</span> the USB cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    }
]
